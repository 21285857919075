import { Grid, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import TaskIcon from '@mui/icons-material/Task'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import React, { useEffect, useState } from 'react'
import createApi from 'services/api'
import { formatDate } from 'services/helpers'
import history from 'services/history'
import { appDispatch, store } from 'services/store'
import { URL_LINK, URL_USER } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'

const api = createApi(store)

const useStyles = makeStyles(() => ({
  detailContainer: { paddingLeft: 80, paddingBottom: 30 }
}))

const GroupDatasetsList = ({ datasets }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.detailContainer}>
      <UtilsTable
        compact
        columns={[
          {
            title: 'Données incluses',
            field: 'title'
          }
        ]}
        onRowClick={(event, dataset) =>
          history.push(URL_LINK.DATA + dataset.id)
        }
        data={datasets}
      />
    </Grid>
  )
}

export const MyGroupsInner = () => {
  const [groupInfo, setGroupInfo] = useState()
  const [open, setOpen] = useState(false)
  const [selectedGroup, setSelectedGroup] = React.useState(null)

  const setPopupOpen = (group) => {
    setOpen(true)
    setSelectedGroup(group)
  }

  const handleClose = () => {
    setOpen(false)
    setSelectedGroup(null)
  }

  const getGroups = () => {
    return api
      .get(URL_USER.MY_GROUPS, {
        params: {
          includePublicUserGroup: false,
          includeRelatedDataSets: true,
          includeCanAcceptTermsOfUse: true
        }
      })
      .then((data) => {
        setGroupInfo(data.data)
      })
      .catch(() =>
        appDispatch(
          errorMessage("La liste des groupes n'a pas pu être chargée")
        )
      )
  }

  useEffect(() => {
    getGroups()
  }, [])

  const openTermsOfUse = async (group) => {
    try {
      const { data: fileRes } = await api.get(
        URL_USER.TERMS_OF_USE_DOCUMENT(group.activeTermsOfUseId),
        { responseType: 'blob' }
      )
      const file = new Blob([fileRes], { type: 'application/pdf' })
      // Build a URL from the file
      const fileURL = URL.createObjectURL(file)
      // Open the URL on new Window
      const pdfWindow = window.open()
      pdfWindow.location.href = fileURL
      // const filename = `${groupInfo.name}_termsofuse_v${versionNumber}.pdf`;
      // fileDownload(fileRes, filename);
      // appDispatch(successMessage("Le fichier a été téléchargé."));
    } catch (error) {
      // todo error handling when no file
      console.error(error)
      appDispatch(errorMessage("Le fichier n'a pu être téléchargé."))
    }
  }

  const acceptTermsOfUse = async (group) => {
    try {
      await api.post(URL_USER.ACCEPT_TERMS_OF_USE(group.activeTermsOfUseId))
      getGroups()
      appDispatch(successMessage('Les conditions ont été acceptées.'))
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("Les conditions n'ont pas pu être acceptées."))
    }
  }

  const deleteGroup = () => {
    api
      .post(
        URL_USER.SELF_REMOVE_GROUP,
        {},
        { params: { groupId: selectedGroup.id } }
      )
      .then(() => {
        handleClose()
        appDispatch(
          successMessage(`Vous avez été retiré du groupe ${selectedGroup.name}`)
        )
      })
      .then(() => {
        getGroups()
      })
      .catch(() =>
        appDispatch(
          errorMessage(
            `Vous n'avez pas pu être retiré du groupe ${selectedGroup.name}`
          )
        )
      )
  }

  if (!groupInfo) return <LoadingBar />

  return (
    <>
      <UtilsTable
        options={{
          paging: false,
          sorting: true,
          actionsColumnIndex: -1
        }}
        columns={[
          {
            title: 'Groupe',
            field: 'name'
          },
          {
            title: 'Description',
            field: 'description'
          },
          {
            title: "Date d'ajout",
            field: 'creationDate',
            defaultSort: 'desc',
            render: (group) =>
              group.creationDate ? formatDate(group.creationDate) : ''
          }
        ]}
        actions={[
          (group) => ({
            icon: () => <FileOpenIcon />,
            tooltip: "Lire les conditions d'utilisations de ce groupe",
            onClick: () => {
              openTermsOfUse(group)
            },
            hidden: group.activeTermsOfUseId == null,
            disabled: group.activeTermsOfUseId == null
          }),
          (group) => ({
            icon: () => <TaskIcon />,
            tooltip: "Accepter les conditions d'utilisations de ce groupe",
            onClick: () => {
              console.log(group)
              acceptTermsOfUse(group)
            },
            hidden: group.activeTermsOfUseId == null,
            disabled: group.activeTermsOfUseId == null || !group.canAcceptTermsOfUse
          }),
          (group) => ({
            icon: () => <ExitToAppIcon color="secondary" />,
            tooltip: 'Se retirer de ce groupe',
            onClick: () => setPopupOpen(group)
          })
        ]}
        detailPanel={({ rowData }) => {
          return <GroupDatasetsList datasets={rowData.dataSets} />
        }}
        data={groupInfo}
      />

      <DeleteUsergroupDialog
        open={open}
        handleClose={handleClose}
        deleteGroup={deleteGroup}
      />
    </>
  )
}

const DeleteUsergroupDialog = ({ open, handleClose, deleteGroup }) => {
  return (
    <Dialog
      open={open}
      className="dialog"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Se retirer du groupe</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Voulez-vous vraiment vous retirer de ce groupe? Vous devrez faire une
          nouvelle demande pour accéder aux données de ce groupe.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Annuler
        </Button>
        <Button onClick={deleteGroup} color="primary" variant="contained">
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const MyGroups = () => (
  <>
    <Title title="Mes groupes" />
    <div className="toggler-section -white">
      <div className="container -data">
        <MyGroupsInner />
      </div>
    </div>
  </>
)

export default MyGroups
