export const URL_BASE = {
  LOCAL: 'http://localhost:8081/api/v1/',
  DEV: 'https://devmobiclic.fabmobqc.com/api/v1/',
  PREPROD: 'https://devmobiclic.fabmobqc.com/api/v1/',
  PROD: 'https://devmobiclic.fabmobqc.com/api/v1/'
}

// TODO move to .env
const DB_ENV = process.env.REACT_APP_BACKEND
// const DB_ENV = "LOCAL";
console.log('DB_ENV', DB_ENV)
export const base = URL_BASE[DB_ENV]

export const basename = '/JalonPortal/services'

export const URL_USER = {
  LOGIN: 'auth/signin',
  // LOGOUT: "auth/signout",
  FORGOT_PASSWORD: 'public/users/resetPassword',
  UPDATE_PWD_RESET: 'public/users/updatePasswordAfterReset',
  SIGNUP_CREATE_BY_GUEST: 'public/signup',
  ACCOUNT_STATUS: 'public/users/userAccountStatus',
  PUBLIC_USERS: 'public/users',
  MY_GROUPS: 'public/users/loadMyGroups',
  SELF_REMOVE_GROUP: 'public/users/removeFromGroup',
  SELF_UPDATE_NAMES: 'public/users/updateNames',
  SELF_UPDATE_LANG: 'public/users/updateLang',
  SELF_UPDATE_PASSWORD: 'public/users/updatePassword',
  GET_PORTAL_COMPONENT: 'public/components/portal'
}

export const URL_MANAGER = {
  MANAGED_GROUPS: 'public/users/getDirectlyManagedGroups',
  GROUPS: 'public/usergroups/',
  GROUPS_ID: (id) => `public/usergroups/${id}`,
  SEARCH_BY_USER_NAME: 'public/users/searchByUserName',
  GROUPS_GET_USERS: 'public/usergroups/users',
  GROUPS_GET_MANAGERS: 'public/usergroups/managers',
  GROUPS_ADD_USERS: 'public/usergroups/addUsers',
  GROUPS_REMOVE_USERS: 'public/usergroups/removeUsers',
  GROUPS_ADD_MANAGERS: 'public/usergroups/addAccessManagers',
  GROUPS_REMOVE_MANAGERS: 'public/usergroups/removeAccessManagers',

  MANAGED_ANNOUNCEMENTS: '/public/announcements/managed/all',
  MANAGED_ANNOUNCEMENTS_ID: (id) => `/public/announcements/managed/byid/${id}`,
  ANNOUNCEMENTS_ADD: '/public/announcements',
  ANNOUNCEMENT_IMAGE: (id, n) => `/public/announcements/${id}/image/${n}`,
  ANNOUNCEMENTS_DELETE_ID: (id) => `/public/announcements/${id}`,

  SURVEYS: '/public/surveys/managed/all',
  SURVEYS_EXPORT: (id) => `/public/surveys/exportAnswers/${id}`,
  SURVEYS_ID: (id) => `/public/surveys/managed/byid/${id}`,
  SURVEYS_ADD: '/public/surveys',
  SURVEYS_DELETE_ID: (id) => `/public/surveys/${id}`,
  SURVEY_QUESTIONS: (surveyId) => `/public/surveys/${surveyId}/questions`,
  SURVEY_QUESTION_POSSIBLE_ANSWERS: (surveyId, questionId) =>
    `/public/surveys/${surveyId}/${questionId}/possibleanswers`,
  QUESTIONS_ADD: 'public/surveys/questions',
  QUESTIONS_DELETE_ID: (id) => `/public/surveys/questions/${id}`,

  MANAGED_PLATFORM_COMPONENTS: '/public/users/getManagedPlatformComponents'
}

export const URL_ADMIN = {
  USERS_BASE: 'admin/users',
  USERS: 'admin/users/all',
  USERS_ID: (id) => `${URL_ADMIN.USERS_BASE}/${id}`,
  USER_UPDATE_USERNAME: 'admin/users/updateUserName',
  USER_UPDATE_USERTYPE: 'admin/users/updateUserType',
  USER_UPDATE_NAMES: 'admin/users/updateNames',
  USER_UPDATE_LANG: 'admin/users/updateLang',
  USER_UPDATE_PURPOSE: 'admin/users/updatePurpose',
  USER_UPDATE_APIACCESS: 'admin/users/updateCanAccessAPI',
  USER_UPDATE_ACTIVESTATUS: 'admin/users/updateActiveStatus',
  USER_UPDATE_LOCKSTATUS: 'admin/users/updateLockStatus',
  USER_UPDATE_PASSWORD: 'admin/users/updatePasswordByAdmin',
  SIGNUP_CREATE_BY_ADMIN: 'admin/signup',
  GROUPS: 'admin/usergroups',
  GROUPS_ID: (id) => `${URL_ADMIN.GROUPS}/${id}`,
  GROUPS_ADD_DATASETS: 'admin/usergroups/addDataSets',
  GROUPS_REMOVE_DATASETS: 'admin/usergroups/removeDataSets',
  GROUPS_ADD_RESOURCES: 'admin/usergroups/addResources',
  GROUPS_REMOVE_RESOURCES: 'admin/usergroups/removeResources',
  GROUPS_ADD_MDTS: 'admin/usergroups/addMetadataTables',
  GROUPS_REMOVE_MDTS: 'admin/usergroups/removeMetadataTables',
  GROUPS_ADD_USERS: '/admin/usergroups/addUsers',
  GROUPS_REMOVE_USERS: '/admin/usergroups/removeUsers',
  GROUPS_ADD_MANAGERS: '/admin/usergroups/addAccessManagers',
  GROUPS_REMOVE_MANAGERS: '/admin/usergroups/removeAccessManagers',
  GROUPS_ADD_PLATFORMCOMPONENTS: '/admin/usergroups/addPlatformComponents',
  GROUPS_REMOVE_PLATFORMCOMPONENTS:
    '/admin/usergroups/removePlatformComponents',
  MANAGERS: '/admin/users/managers',
  PLATFORM_COMPONENTS_BASE: '/admin/components',
  PLATFORM_COMPONENTS_ID: (id) => `${URL_ADMIN.PLATFORM_COMPONENTS_BASE}/${id}`,
  PLATFORM_COMPONENTS: '/admin/components/all',
  PLATFORM_COMPONENTS_BY_USERGROUP: 'admin/components/userGroup',

  DATASET_UPDATE_PRIVATE_STATUS: 'admin/ckan/datasets/updatePrivateStatus',
  RESOURCE_UPDATE_PRIVATE_STATUS: 'admin/ckan/resources/updatePrivateStatus',
  MDTS: 'admin/metadata/tables',
  MDT_UPDATE_PRIVATE_STATUS: 'admin/metadata/tables/updatePrivateStatus',
  MDCS: 'admin/metadata/columns',
  MDC_NATURES: 'admin/metadata/columnNatures',
  MDC_TYPES: 'admin/metadata/columnTypes'
}

export const URL_EVENTS = {
  USERGROUP_USERS_PUBLIC: '/public/events/usergroup/users',
  USERGROUP_DATASETS_PUBLIC: '/public/events/usergroup/datasets',
  USERGROUP_RESOURCES_PUBLIC: '/public/events/usergroup/resources',
  USERGROUP_TABLES_PUBLIC: '/public/events/usergroup/tables',
  USERGROUP_MGRS_PUBLIC: '/public/events/usergroup/managers'
}

export const URL_LINK = {
  LOGIN: '/login/',
  SIGNUP: '/signup/',
  INDEX: '/index/',
  ABOUT: '/about/',
  HELP: '/help/',
  FORGOT_PASSWORD: '/forgotPassword/',
  RESET_PASSWORD: '/user/resetPassword/',
  SET_PASSWORD: '/user/resetPassword/',
  PROFILE: '/myprofile/',
  MY_GROUPS: '/mygroups/',

  USERS: '/admin/users/',
  USERS_ADD: '/admin/users/add/',
  USERS_UPDATE: '/admin/users/update/',
  USERGROUPS: '/admin/usergroups/',
  USERGROUPS_ADD: '/admin/usergroups/add/',
  USERGROUPS_UPDATE: '/admin/usergroups/update/',
  ANNOUNCEMENTS: '/admin/announcements/',
  ANNOUNCEMENTS_ADD: '/admin/announcements/add/',
  ANNOUNCEMENTS_UPDATE: '/admin/announcements/update/',
  SURVEYS: '/admin/surveys/',
  SURVEYS_ADD: '/admin/surveys/add/',
  SURVEYS_UPDATE: '/admin/surveys/update/',
  SURVEYS_QUESTION_ADD: (surveyId) => `/admin/surveys/${surveyId}/add/question`,
  SURVEYS_QUESTION_UPDATE: (surveyId, questionId) =>
    `/admin/surveys/${surveyId}/update/question/${questionId}`,

  PLATFORM_COMPONENTS: '/admin/platformcomponents',

  MANAGED_GROUPS: '/manage/usergroups/',
  MANAGE_GROUPS_UPDATE: '/manage/usergroups/update/',
  MANAGE_EVENTS_USERGROUPID: (usergroupId) => `/manage/events/${usergroupId}`,
  MANAGE_EXPORTS: '/manage/exports/',
  MANAGE_ANNOUNCEMENTS: '/manage/announcements/',
  MANAGE_ANNOUNCEMENTS_ADD: '/manage/announcements/add/',
  MANAGE_ANNOUNCEMENTS_UPDATE: '/manage/announcements/update/',
  MANAGE_SURVEYS: '/manage/surveys/',
  MANAGE_SURVEYS_ADD: '/manage/surveys/add/',
  MANAGE_SURVEYS_UPDATE: '/manage/surveys/update/',
  MANAGE_SURVEYS_QUESTION_ADD: (surveyId) =>
    `/manage/surveys/${surveyId}/add/question`,
  MANAGE_SURVEYS_QUESTION_UPDATE: (surveyId, questionId) =>
    `/manage/surveys/${surveyId}/update/question/${questionId}`
}
