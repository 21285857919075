import { Grid } from '@material-ui/core'
import bean from 'images/404bean.mp4'
import { Link } from 'react-router-dom'
import { URL_LINK } from 'services/urls'

const NotFound = ({ children }) => {
  return (
    <div>
      <div className="toggler-section -white">
        <div className="container -data">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item style={{ textAlign: 'center' }}>
              <video playsInline autoPlay muted loop width="560">
                <source src={bean} type="video/mp4" />
              </video>
              <h3>La page que vous recherchez n'a pas été trouvée</h3>
              <p>
                {children || (
                  <Link to={URL_LINK.INDEX}>Retour à l'accueil</Link>
                )}
              </p>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default NotFound
