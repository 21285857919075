import { createTheme } from '@material-ui/core/styles'
// Theme pour Material UI.
// Contient: Typography, Colors, Form styling

const theme = createTheme({
  typography: {
    fontFamily: ['Mark', 'Helvetica', 'sans-serif'].join(',')
  },
  spacing: 5,
  palette: {
    primary: {
      main: '#fe4600'
    },
    secondary: {
      main: '#757575'
    },
    neutral: {
      main: '#969696'
    },
    burgundy: {
      main: '#7e2340'
    }
  },
  shape: {
    borderRadius: 4
  },

  props: {
    MuiButton: {
      disableElevation: true
    }
  },

  overrides: {
    // DÉBUT OVERRIDES//////////////////////////////////////////////////////////////////////////////
    MuiButton: {
      root: {
        // Align buttons in the main menu like anchors
        '.menu-ctn &': {
          lineHeight: 'initial'
        },
        // Space between buttons
        '& ~ .MuiButton-root': {
          marginLeft: 8 // Same as DialogActions Buttons
        }
      }
    },

    MuiTableHead: {
      root: {
        backgroundColor: '#dedede'
      }
    },
    MuiAccordionSummary: {
      root: {
        '& p': {
          'font-weight': 'bold'
        },
        '&:hover p': {
          'text-decoration': 'underline'
        }
      }
    },
    MuiTableRow: {
      root: {
        fontSize: '1rem',
        fontWeight: '500',
        lineHeight: '0.75rem'
      },
      head: {
        fontSize: '1rem',
        textTransform: 'uppercase',
        fontWeight: '500',
        lineHeight: '1.5rem'
        // display: "flex",
      }
    },
    MuiCheckbox: {
      root: {
        color: '#757575',
        '&$checked': {
          color: '#757575'
        }
      },
      colorSecondary: {
        color: '#757575',
        '&$checked': {
          color: '#757575'
        }
      }
    },

    MuiCardActionArea: {
      root: {
        marginBottom: '0.8em'
      }
    },

    MuiDialog: {
      // Don't scroll body content if entire dialog scrolls in body
      // https://mui.com/components/dialogs/#scrolling-long-content
      scrollBody: {
        '& .MuiDialogContent-root': {
          overflowY: 'hidden'
        }
      }
    },

    MuiPaper: {
      root: {
        backgroundColor: '#ffffff'
      }
    },

    MuiCardContent: {
      root: {
        backgroundColor: '#fff'
      }
    },

    MuiTabs: {
      root: {
        backgroundColor: '#fff',
        color: 'black'
      },
      indicator: {
        backgroundColor: 'currentColor'
      }
    },

    MuiTab: {
      root: {
        lineHeight: 'normal'
      }
    },

    MuiGrid: {
      item: {}
    },

    MuiFormHelperText: {
      root: {
        // Use existing space / prevents shifting content below field
        marginTop: 0,
        height: 0
      },
      contained: {
        // Maximize use of space below field (prevents wrapping text in some instances)
        marginRight: 0
      }
    },

    MuiFormControlLabel: {
      root: {
        height: '56px',
        '& .MuiSvgIcon-root': {
          verticalAlign: 'top'
        }
      }
    },

    MuiTypography: {
      gutterBottom: {
        marginBottom: '0.5em'
      }
    },

    MuiToolbar: {
      root: {}
    },

    MuiChip: {
      root: {}
    },

    MuiFormLabel: {
      root: {}
    },

    MuiInputBase: {
      input: {}
    },

    MuiListItem: {
      root: {}
    },

    MuiListItemText: {
      root: {}
    },

    MuiListItemIcon: {
      root: {}
    },

    MuiBadge: {
      badge: {}
    },

    MuiOutlinedInput: {
      multiline: {}
    },
    MuiSelect: {
      select: {}
    }
  }, // FIN OVERRIDES ///////////////////////////////////////////////

  MuiOutlinedInput: {
    root: {}
  },

  MuiFormControlLabel: {
    root: {}
  },

  MuiFormControl: {
    root: {}
  }
})

export default theme
