import { MTableToolbar } from '@material-table/core'
import {
  FormControl,
  Grid, InputAdornment,
  InputLabel, MenuItem,
  Select
} from '@material-ui/core'

export const UsergroupUsersToolbar = ({
  userTypes,
  filterUsers,
  setFilterUsers,
  ...props
}) => {
  return (
    <Grid container item xs={12}>
      <FormControl variant="outlined" style={{ minWidth: 200 }}>
        <InputLabel disableAnimation={true} id="user-type-select">
          Filtre
        </InputLabel>
        <Select
          labelId="user-type-select"
          displayEmpty
          label="Filtre"
          variant="outlined"
          value={filterUsers}
          onChange={(event) => {
            const filterType = event.target.value
            setFilterUsers(filterType)
          }}
          startAdornment={
            filterUsers && (
              <InputAdornment position="start">
                {userTypes[filterUsers]?.icon}
              </InputAdornment>
            )
          }
        >
          {Object.entries(userTypes).map(([type, { name }]) => (
            <MenuItem value={type} key={type}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Search bar */}
      <div style={{ marginLeft: 'auto' }}>
        <MTableToolbar {...props} />
      </div>
    </Grid>
  )
}
