import { Breadcrumbs, Paper, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import ApplyButton from 'components/utils/ApplyButton'
import DeleteButton from 'components/utils/DeleteButton'
import FormSkeleton from 'components/utils/FormSkeleton'
import { Field } from 'formik'
import * as fmi from 'formik-material-ui'
import { Link } from 'react-router-dom'
import {
  EXCEPTIONS,
  PASSWORD_REGEX,
  USERTYPES
} from 'services/constants'
import { URL_LINK } from 'services/urls'
import * as yup from 'yup'

const UsernameSchema = yup.object({
  userName: yup
    .string()
    .max(128, 'Courriel doit contenir 128 charactères ou moins.')
    .matches(/^(?=\S+$).{0,128}$/, "Courriel ne peut contenir d'espace")
    .required('Requis')
})

const PasswordSchema = (admin) =>
  yup.object({
    admin: yup.boolean(),
    oldPassword: yup.string().when('admin', {
      is: false,
      then: yup.string().required('Ancien mot de passe requis')
    }),
    newPassword: yup
      .string()
      .required('Mot de passe requis')
      .min(6, 'Doit contenir entre 6 et 24 charactères')
      .max(24, 'Doit contenir entre 6 et 24 charactères')
      .matches(
        PASSWORD_REGEX,
        'Doit contenir au moins une majuscule, une minuscule, un chiffre et un charactère spécial parmi !@#$%^&*-'
      ),
    passwordConfirm: yup
      .string()
      .test(
        'passwords-match',
        'Les mots de passe doivent correspondre',
        function (value) {
          return this.parent.newPassword === value
        }
      )
  })

const NamesSchema = yup.object({
  firstName: yup
    .string()
    .max(128, 'Prénom doit contenir 128 charactères ou moins.')
    .required('Requis'),
  lastName: yup
    .string()
    .max(128, 'Nom de famille doit contenir 128 charactères ou moins.')
    .required('Requis')
})

const IsActiveSchema = yup.object({
  isActive: yup.boolean()
})

const IsLockedSchema = yup.object({
  isLocked: yup.boolean()
})

const UserTypeSchema = yup.object({
  userType: yup.string().oneOf(Object.values(USERTYPES))
})

export const UsernameForm = ({
  usernameState,
  saveUsername,
  resetLoading,
  alreadyInUseEmail,
  setAlreadyInUseEmail,
  usernameIsCurrentUser
}) => {
  return (
    <FormSkeleton
      initialState={usernameState}
      validationSchema={UsernameSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        return saveUsername(values).catch((error) => {
          if (
            error.response.data.exceptionType ===
            EXCEPTIONS.UserNameAlreadyExists
          ) {
            setAlreadyInUseEmail(alreadyInUseEmail.concat([values.userName]))
            setErrors({ userName: 'Courriel déjà utilisé' })
            setSubmitting(false)
            resetLoading()
          }
        })
      }}
      validate={(values) => {
        const errors = {}
        if (alreadyInUseEmail.includes(values.userName)) {
          errors.userName = 'Courriel déjà utilisé'
        }
        return errors
      }}
    >
      {({ isValid, dirty }) => (
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Field
              component={fmi.TextField}
              variant="outlined"
              fullWidth
              label="Courriel"
              name="userName"
              disabled={usernameIsCurrentUser}
            />
          </Grid>
          <Grid item className="centerCenter" xs={4}>
            <ApplyButton dirty={dirty} isValid={isValid} />
          </Grid>
        </Grid>
      )}
    </FormSkeleton>
  )
}

export const PasswordForm = ({ passwordState, savePassword, admin }) => {
  const colSpan = admin ? 4 : 3
  return (
    <FormSkeleton
      initialState={{ ...passwordState, admin }}
      validationSchema={PasswordSchema}
      onSubmit={(values, props) => {
        return savePassword(values).then(() => {
          props.resetForm()
        })
      }}
    >
      {({ isValid, dirty }) => (
        <>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h6">
                Changement de mot de passe
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {!admin && (
              <Grid item xs={colSpan}>
                <Field
                  component={fmi.TextField}
                  type="password"
                  variant="outlined"
                  fullWidth
                  label="Ancien mot de passe"
                  name="oldPassword"
                  inputProps={{
                    autoComplete: 'current-password'
                  }}
                />
              </Grid>
            )}
            <Grid item xs={colSpan}>
              <Field
                component={fmi.TextField}
                type="password"
                variant="outlined"
                fullWidth
                label="Nouveau mot de passe"
                name="newPassword"
                inputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={colSpan}>
              <Field
                component={fmi.TextField}
                type="password"
                variant="outlined"
                fullWidth
                label="Confirmer mot de passe"
                name="passwordConfirm"
                inputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item className="centerCenter" xs={colSpan}>
              <ApplyButton dirty={dirty} isValid={isValid} />
            </Grid>
          </Grid>
        </>
      )}
    </FormSkeleton>
  )
}

export const NamesForm = ({ namesState, saveNames }) => (
  <FormSkeleton
    initialState={namesState}
    validationSchema={NamesSchema}
    onSubmit={saveNames}
  >
    {({ isValid, dirty }) => (
      <Grid container>
        <Grid container item xs={8} spacing={8}>
          <Grid item xs={12}>
            <Field
              component={fmi.TextField}
              variant="outlined"
              fullWidth
              inputProps={{ style: { textTransform: 'capitalize' } }}
              label="Prénom"
              name="firstName"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              component={fmi.TextField}
              variant="outlined"
              fullWidth
              label="Nom"
              name="lastName"
              inputProps={{ style: { textTransform: 'capitalize' } }}
            />
          </Grid>
        </Grid>
        <Grid item className="centerCenter" xs={4}>
          <ApplyButton dirty={dirty} isValid={isValid} />
        </Grid>
      </Grid>
    )}
  </FormSkeleton>
)

export const UserTypeForm = ({ userTypeState, saveUserType }) => (
  <FormSkeleton
    initialState={userTypeState}
    validationSchema={UserTypeSchema}
    onSubmit={saveUserType}
  >
    {({ isValid, dirty }) => (
      <Grid container spacing={3}>
        <Grid container item xs={8}>
          <Field
            component={fmi.TextField}
            variant="outlined"
            fullWidth
            select
            label="Type d'utilisateur"
            id="usertype-select"
            name={'userType'}
          >
            {Object.values(USERTYPES).map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Field>
        </Grid>
        <Grid item className="centerCenter" xs={4}>
          <ApplyButton dirty={dirty} isValid={isValid} />
        </Grid>
      </Grid>
    )}
  </FormSkeleton>
)

export const IsActiveForm = ({ activeState, saveActive }) => (
  <FormSkeleton
    initialState={activeState}
    validationSchema={IsActiveSchema}
    onSubmit={saveActive}
  >
    {({ isValid, dirty }) => (
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Field
          name="isActive"
          type="checkbox"
          component={fmi.CheckboxWithLabel}
          Label={{ label: 'isActive' }}
        />
        <ApplyButton dirty={dirty} isValid={isValid} />
      </Grid>
    )}
  </FormSkeleton>
)

export const IsLockedForm = ({ lockedState, saveLocked }) => (
  <FormSkeleton
    initialState={lockedState}
    validationSchema={IsLockedSchema}
    onSubmit={saveLocked}
  >
    {({ isValid, dirty }) => (
      <Grid
        container
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
      >
        <Field
          name="isLocked"
          type="checkbox"
          component={fmi.CheckboxWithLabel}
          Label={{ label: 'isLocked' }}
        />
        <ApplyButton dirty={dirty} isValid={isValid} />
      </Grid>
    )}
  </FormSkeleton>
)

const UpdateUserForm = ({
  usernameState,
  saveUsername,
  namesState,
  saveNames,
  savePassword,
  activeState,
  saveActive,
  lockedState,
  saveLocked,
  userTypeState,
  saveUserType,
  setPopupOpen,
  admin, 
  resetLoading,
  alreadyInUseEmail,
  setAlreadyInUseEmail,
  usernameIsCurrentUser
}) => {
  return (
    <Grid container spacing={8}>
      {admin && (
        <Grid item>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={URL_LINK.USERS}>Utilisateurs</Link>
            <Typography>{usernameState.userName}</Typography>
          </Breadcrumbs>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={2}>
          {admin && (
            <Grid item xs={12}>
              <UsernameForm
                usernameIsCurrentUser={usernameIsCurrentUser}
                usernameState={usernameState}
                saveUsername={saveUsername}
                resetLoading={resetLoading}
                alreadyInUseEmail={alreadyInUseEmail}
                setAlreadyInUseEmail={setAlreadyInUseEmail}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <NamesForm namesState={namesState} saveNames={saveNames} />
          </Grid>
          {admin && ( 
            <>
              <Grid item xs={6}>
                <IsActiveForm
                  activeState={activeState}
                  saveActive={saveActive}
                />
              </Grid>
              <Grid item xs={6}>
                <IsLockedForm
                  lockedState={lockedState}
                  saveLocked={saveLocked}
                />
              </Grid>
              <Grid item xs={12}>
                <UserTypeForm
                  userTypeState={userTypeState}
                  saveUserType={saveUserType}
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <PasswordForm
              passwordState={{
                oldPassword: '',
                newPassword: '',
                passwordConfirm: ''
              }}
              savePassword={savePassword}
              admin={admin}
            />
          </Grid>
          <Grid item xs={12}>
            <Paper
              style={{
                padding: '2rem',
                display: 'flex',
                justifyContent: 'center'
              }}
              variant="outlined"
            >
              <DeleteButton
                onClick={(e) => {
                  setPopupOpen()
                }}
              >
                Supprimer {admin ? "l'utilisateur" : 'son compte'}
              </DeleteButton>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export const UpdateUserDialog = ({
  open,
  handleClose,
  deleteUser,
  admin = true
}) => (
  <Dialog
    open={open}
    className="dialog"
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {admin ? "Suppression de l'utilisateur" : 'Suppression de compte'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer{' '}
        {admin ? 'cet utilisateur?' : 'votre compte?'}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Annuler
      </Button>
      <Button onClick={deleteUser} color="primary" variant="contained">
        Confirmer
      </Button>
    </DialogActions>
  </Dialog>
)

export default UpdateUserForm
