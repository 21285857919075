import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
// import { createExpiredAction } from "services/actions";
import { Breadcrumbs, Typography } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import ApplyButton from 'components/utils/ApplyButton'
import Title from 'components/utils/Title/Title'
import { Field, Form, Formik } from 'formik'
import * as fmi from 'formik-material-ui'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import createApi from 'services/api'
import { EXCEPTIONS } from 'services/constants'
import { store } from 'services/store'
import { URL_ADMIN, URL_LINK } from 'services/urls'
import * as yup from 'yup'

const api = createApi(store)

const SignupSchema = yup.object().shape({
  name: yup.string().max(255, 'Nom trop long').required('Requis'),
  description: yup.string().max(255, 'Description trop longue')
})

const GroupCreatedDialog = ({ open, handleClose, createdId }) => (
  <Dialog
    open={open}
    className="dialog"
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Groupe créé avec succès</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Vous pouvez créer un nouveau groupe, modifier le groupe que vous venez
        de créer ou retourner à la liste des groupes.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary" autoFocus>
        Créer un groupe
      </Button>
      <Button onClick={handleClose} color="primary">
        <Link to={URL_LINK.USERGROUPS_UPDATE + createdId}>
          Modifier le groupe créé
        </Link>
      </Button>
      <Button onClick={handleClose} color="primary">
        <Link to={URL_LINK.USERGROUPS}>Voir la liste des groupes</Link>
      </Button>
    </DialogActions>
  </Dialog>
)

export const AddUsergroupForm = ({
  initialState,
  saveGroup,
  alreadyInUseName,
  setAlreadyInUseName
}) => (
  <Grid container justifyContent="center">
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={URL_LINK.USERGROUPS}>Groupes</Link>
          <Typography>Ajouter</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Formik
          initialValues={initialState}
          validationSchema={SignupSchema}
          onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
            saveGroup(values)
              .then(() => {
                resetForm()
              })
              .catch((error) => {
                if (
                  error.response.data.exceptionType ===
                  EXCEPTIONS.UserGroupAlreadyExists
                ) {
                  setAlreadyInUseName(alreadyInUseName.concat([values.name]))
                  setErrors({ name: 'Nom de groupe déjà utilisé' })
                  setSubmitting(false)
                }
              })
          }}
          validate={(values) => {
            const errors = {}
            if (alreadyInUseName.includes(values.name)) {
              errors.name = 'Nom de groupe déjà utilisé'
            }
            return errors
          }}
        >
          {({ values, errors, setFieldValue, touched, isValid, dirty }) => (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Paper variant="outlined" style={{ padding: '2rem' }}>
                    <Form autoComplete="off">
                      <Grid container spacing={8}>
                        <Grid item xs={12}>
                          <Field
                            component={fmi.TextField}
                            variant="outlined"
                            fullWidth
                            autoFocus
                            label="Nom de groupe"
                            name="name"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Field
                            component={fmi.TextField}
                            variant="outlined"
                            fullWidth
                            label="Description"
                            name="description"
                          />
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'center' }}>
                          <ApplyButton
                            dirty={dirty}
                            isValid={isValid}
                            endIcon={<GroupAddIcon />}
                          >
                            Ajouter le groupe
                          </ApplyButton>
                        </Grid>
                      </Grid>
                    </Form>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </Grid>
    </Grid>
  </Grid>
)

AddUsergroupForm.propTypes = {
  initialState: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
  }),
  saveGroup: PropTypes.func.isRequired
}

const AddUsergroup = () => {
  const [open, setOpen] = useState(false)
  const [createdId, setCreatedId] = useState('')
  const [alreadyInUseName, setAlreadyInUseName] = useState([])

  const saveUsergroup = (values) => {
    return api.post(URL_ADMIN.GROUPS, values).then((data) => {
      setCreatedId(data.data.id)
      setOpen(true)
    })
  }

  const handleClose = () => {
    setOpen(false)
  }

  // Valeur initiale groupe
  const initialState = {
    name: '',
    description: ''
  }

  return (
    <div>
      <Title title={'Ajouter un groupe'} />
      <div className="toggler-section -white">
        <div className="container -data">
          <AddUsergroupForm
            initialState={initialState}
            saveGroup={saveUsergroup}
            alreadyInUseName={alreadyInUseName}
            setAlreadyInUseName={setAlreadyInUseName}
          />
        </div>
      </div>
      <GroupCreatedDialog
        open={open}
        handleClose={handleClose}
        createdId={createdId}
      />
    </div>
  )
}
export default AddUsergroup
