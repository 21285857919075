import UpdateUserForm, {
  UpdateUserDialog
} from 'components/Shared/Users/UpdateUserForm'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import history from 'services/history'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { URL_ADMIN, URL_LINK } from 'services/urls'
import { successMessage } from 'store/message'

const api = createApi(store)

const UpdateUser = () => {
  // User id from URL
  const { id } = useParams()
  const [userInfo, setUserInfo] = useState()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [alreadyInUseEmail, setAlreadyInUseEmail] = React.useState([])

  const { userName: currentUserName } = useSelector((state) => state.user)

  const resetLoading = () => {
    setLoading(false)
  }

  const setPopupOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const deleteUser = () => {
    api
      .delete(URL_ADMIN.USERS_ID(id))
      .then(() => {
        setOpen(false)
        appDispatch(
          successMessage(`L'utilisateur ${userInfo.userName} a été supprimé`)
        )
        history.push(URL_LINK.USERS)
      })
      .catch((err) => err)
  }

  const saveUserUpdate = (url) => (values) => {
    return api
      .put(url, {
        id,
        ...values
      })
      .then((data) => {
        setUserInfo(data.data)
        setLoading(false)
        appDispatch(successMessage('Les changements ont été effectués'))
      })
      .catch((err) => err)
  }

  const savePassword = (values) => {
    const dto = {
      userId: id,
      newPassword: values.newPassword
    }
    return api
      .put(URL_ADMIN.USER_UPDATE_PASSWORD, dto)
      .then((data) => {
        setUserInfo(data.data)
        setLoading(false)
        appDispatch(successMessage('Le mot de passe a été changé'))
      })
      .catch((err) => err)
  }

  useEffect(() => {
    setLoading(true)
    api
      .get(URL_ADMIN.USERS_ID(id))
      .then((data) => {
        setUserInfo(data.data)
        setLoading(false)
      })
      .catch((err) => err)
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  return (
    <>
      <Title title={'Modifier un utilisateur'} />
      <div className="toggler-section -white">
        <div className="container -data">
          {!loading && userInfo 
            ? (
            <UpdateUserForm
              usernameState={{ userName: userInfo.userName }}
              saveUsername={saveUserUpdate(URL_ADMIN.USER_UPDATE_USERNAME)}
              activeState={{ isActive: userInfo.isActive }}
              saveActive={saveUserUpdate(URL_ADMIN.USER_UPDATE_ACTIVESTATUS)}
              lockedState={{ isLocked: userInfo.isLocked }}
              saveLocked={saveUserUpdate(URL_ADMIN.USER_UPDATE_LOCKSTATUS)}
              namesState={{
                firstName: userInfo.firstName,
                lastName: userInfo.lastName
              }}
              saveNames={saveUserUpdate(URL_ADMIN.USER_UPDATE_NAMES)}
              userTypeState={{ userType: userInfo.userType }}
              saveUserType={saveUserUpdate(URL_ADMIN.USER_UPDATE_USERTYPE)}
              savePassword={savePassword}
              setPopupOpen={setPopupOpen}
              admin={true}
              resetLoading={resetLoading}
              alreadyInUseEmail={alreadyInUseEmail}
              setAlreadyInUseEmail={setAlreadyInUseEmail}
              usernameIsCurrentUser={currentUserName === userInfo.userName}
            />
              )
            : (
            <LoadingBar />
              )}
        </div>
      </div>
      <UpdateUserDialog
        open={open}
        handleClose={handleClose}
        deleteUser={deleteUser}
      />
    </>
  )
}
export default UpdateUser
