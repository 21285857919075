import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert'
import { useSelector } from 'react-redux'
import { appDispatch } from 'services/store'
import { clearMessage } from 'store/message'

const Message = ({ type }) => {
  const { open, message } = useSelector((state) => state.message[type])

  function handleClose () {
    appDispatch(clearMessage())
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      aria-describedby="client-snackbar"
    >
      <Alert onClose={handleClose} variant="filled" severity={type}>
        {message}
      </Alert>
    </Snackbar>
  )
}

const SuccessMessage = () => <Message type="success" />
const ErrorMessage = () => <Message type="error" />
const WarningMessage = () => <Message type="warning" />
const InfoMessage = () => <Message type="info" />

export { SuccessMessage, ErrorMessage, WarningMessage, InfoMessage }
