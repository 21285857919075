import SaveIcon from '@material-ui/icons/Save'
import React from 'react'
import Button from '@material-ui/core/Button'

const ApplyButton = ({
  dirty = true,
  isValid,
  isSubmitting,
  children,
  ...props
}) => (
  <Button
    type="submit"
    color="primary"
    disabled={!dirty || !isValid || isSubmitting}
    variant="contained"
    endIcon={<SaveIcon />}
    {...props}
  >
    {children || 'Enregistrer'}
  </Button>
)
export default ApplyButton
