import { createSlice } from '@reduxjs/toolkit'

const defaultMessageState = {
  success: {
    open: false,
    message: ''
  },
  error: {
    open: false,
    message: ''
  },
  warning: {
    open: false,
    message: ''
  },
  info: {
    open: false,
    message: ''
  }
}

const messageSlice = createSlice({
  name: 'message',
  initialState: defaultMessageState,
  reducers: {
    successMessage: (state, { payload }) => ({
      ...state,
      success: {
        open: true,
        message: payload
      }
    }),
    errorMessage: (state, { payload }) => ({
      ...state,
      error: {
        open: true,
        message: payload
      }
    }),
    warningMessage: (state, { payload }) => ({
      ...state,
      warning: {
        open: true,
        message: payload
      }
    }),
    infoMessage: (state, { payload }) => ({
      ...state,
      info: {
        open: true,
        message: payload
      }
    }),
    clearMessage: (state) => {
      state.success.open = false
      state.error.open = false
      state.warning.open = false
      state.info.open = false
    }
  }
})

export const {
  successMessage,
  errorMessage,
  warningMessage,
  infoMessage,
  clearMessage
} = messageSlice.actions

export default messageSlice.reducer
