import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import logo from 'images/logo-jalon-alpha.svg'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { USERTYPES } from 'services/constants'
import { URL_LINK } from 'services/urls'

const PopupMenu = (props) => {
  const { title, links } = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown (event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open)
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <div>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'admin-menu-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        disableFocusRipple={true}
        disableTouchRipple={true}
      >
        {title}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="admin-menu-grow"
                  className="dropdown-header-menu"
                  onKeyDown={handleListKeyDown}
                >
                  {links.map((child, idx) =>
                      <Link
                        key={idx}
                        to={child.link}
                        onClick={child.onClick}
                        className={'dropdown-header-menu-link'}
                      >
                        <MenuItem onClick={handleClose}>{child.text}</MenuItem>
                      </Link>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

PopupMenu.propTypes = {
  links: PropTypes.array,
  title: PropTypes.string.isRequired
}

const Header = ({ loggedIn, userType, canManageOrgs, logout }) => {
  const adminLinks = [
    { text: 'Utilisateurs', link: URL_LINK.USERS },
    { text: 'Groupes', link: URL_LINK.USERGROUPS },
    {
      text: 'Annonces',
      link: URL_LINK.ANNOUNCEMENTS
    },
    {
      text: 'Sondages',
      link: URL_LINK.SURVEYS
    }
  ]

  const managerLinks = [
    { text: 'Groupes gérés', link: URL_LINK.MANAGED_GROUPS },
    {
      text: 'Annonces',
      link: URL_LINK.MANAGE_ANNOUNCEMENTS
    },
    {
      text: 'Sondages',
      link: URL_LINK.MANAGE_SURVEYS
    },
    {
      text: 'Export de données',
      link: URL_LINK.MANAGE_EXPORTS
    }
  ]

  return (
    <header className="site-header" id="main-header">
      <span id="js-bg-menu" className="bg" style={{ height: '0px' }}></span>
      <div className="header-ctn">
        <div className="logo-ctn">
          <Link to="/index">
            <img src={logo} alt={'Logo Jalon'} />
          </Link>
        </div>
        <div className="menu-ctn" id="site-navigation">
          <div className="main-menu-ctn">
            <ul className="menu-list">
              <li>
                <Link to={URL_LINK.HELP}>Aide</Link>
              </li>
              <li>
                <Link to={URL_LINK.ABOUT}>À propos</Link>
              </li>
              {userType === USERTYPES.ADMIN && (
                <li>
                  <PopupMenu title={'Admin'} links={adminLinks} />
                </li>
              )}
              {userType === USERTYPES.MANAGER && (
                <li>
                  <PopupMenu title={'Gestion'} links={managerLinks} />
                </li>
              )}
            </ul>
          </div>
          <div className="secondary-menu-ctn">
            <ul className="menu-list">
              <li>
                <a href="http://www.jalonmtl.org">
                  <span className="icon icon-export"></span>
                  Site principal
                </a>
              </li>
              {!loggedIn
                ? (
                <>
                  <li>
                    <Link to={URL_LINK.LOGIN}>Se connecter</Link>
                  </li>
                  <li>
                    <Button
                      component={Link}
                      to={URL_LINK.SIGNUP}
                      variant="outlined"
                      style={{ textTransform: 'none' }}
                    >
                      S'inscrire
                    </Button>
                  </li>
                </>
                  )
                : (
                <li>
                  <PopupMenu
                    title={'Mon profil'}
                    links={[
                      { text: 'Mon profil', link: URL_LINK.PROFILE },
                      { text: 'Mes groupes', link: URL_LINK.MY_GROUPS },
                      {
                        text: 'Se déconnecter',
                        link: URL_LINK.INDEX,
                        onClick: logout
                      }
                    ]}
                  />
                </li>
                  )}
            </ul>
          </div>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  loggedIn: false
}

Header.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  userType: PropTypes.string,
  canManageOrgs: PropTypes.bool,
  logout: PropTypes.func.isRequired
}

export default Header
