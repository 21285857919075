import React from 'react'
import PropTypes from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'

const useStyle = makeStyles((theme) => ({
  // Hide element without using display: none so material-table
  // can calculate column widths on other mounted tabs.
  hidden: {
    height: 0,
    overflow: 'hidden',
    visibility: 'hidden'
  },
  visible: {
    marginTop: theme.spacing(2)
  }
}))

const TabPanel = (props) => {
  const { children, value, index, keepMounted, ...other } = props
  const isActive = value === index
  const classes = useStyle()

  return (
    <div
      role="tabpanel"
      className={!isActive ? classes.hidden : classes.visible}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {(isActive || keepMounted) && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

export default TabPanel
