import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DeleteForever } from '@material-ui/icons'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import { useEffect, useState } from 'react'
import createApi from 'services/api'
import { sortByProp } from 'services/helpers'
import history from 'services/history'
import { appDispatch, store } from 'services/store'
import { URL_ADMIN, URL_LINK } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'

const api = createApi(store)

const DeleteUserDialog = ({ open, setOpen, deleteUser }) => (
  <Dialog
    open={open}
    className="dialog"
    onClose={() => {
      setOpen(false)
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      Suppression de l'utilisateur
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer cet utilisateur?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          setOpen(false)
        }}
        color="primary"
      >
        Annuler
      </Button>
      <Button onClick={deleteUser} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
)

const UsersTable = ({ users, setPopupOpen }) => (
  <UtilsTable
    title="Liste des utilisateurs"
    options={{
      search: true,
      paging: false,
      searchAutoFocus: true,
      actionsColumnIndex: -1
    }}
    columns={[
      {
        title: 'Courriel',
        field: 'userName',
        align: 'left'
      },
      {
        title: 'Prénom',
        field: 'firstName',
        align: 'left',
        defaultSort: 'asc',
        // Case insensitive sorting
        customSort: sortByProp('firstName'),
        cellStyle: {
          textTransform: 'capitalize'
        }
      },
      {
        title: 'Nom',
        field: 'lastName',
        align: 'left',
        defaultSort: 'asc',
        // Case insensitive sorting
        customSort: sortByProp('lastName'),
        cellStyle: {
          textTransform: 'capitalize'
        }
      }
    ]}
    actions={[
      (user) => ({
        icon: () => <DeleteForever color="secondary" />,
        tooltip: 'Supprimer cet utilisateur',
        onClick: () => setPopupOpen(user)
      })
    ]}
    onRowClick={(event, user) => history.push(URL_LINK.USERS_UPDATE + user.id)}
    data={users}
  />
)

export const UsersInner = () => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState()

  const setPopupOpen = (user) => {
    setOpen(true)
    setSelectedUser(user)
  }

  const getUsers = () => {
    setLoading(true)
    api
      .get(URL_ADMIN.USERS)
      .then((data) => {
        setUsers(data.data)
      })
      .then(() => {
        setLoading(false)
      })
      .catch(() =>
        appDispatch(
          errorMessage('La liste des utilisateurs n\'a pas pu être chargée')
        )
      )
  }

  const deleteUser = () => {
    api
      .delete(URL_ADMIN.USERS_ID(selectedUser.id))
      .then(() => {
        setOpen(false)
        appDispatch(
          successMessage(
            `L'utilisateur ${selectedUser.userName} a été supprimé`
          )
        )
        setSelectedUser(null)
        getUsers()
      })
      .catch(() =>
        appDispatch(errorMessage('L\'utilisateur n\'a pas pu être supprimé'))
      )
  }

  useEffect(getUsers, [])

  if (loading) return <LoadingBar />

  return (
    <>
      <UsersTable users={users} setPopupOpen={setPopupOpen} />
      <DeleteUserDialog open={open} setOpen={setOpen} deleteUser={deleteUser} />
    </>
  )
}

const Users = () => (
  <>
    <Title title="Utilisateurs" />
    <div className="toggler-section -white">
      <div className="container -data">
        <UsersInner />
      </div>
    </div>
  </>
)

export default Users
