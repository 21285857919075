import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@material-ui/core'
import { AddComment, DeleteForever, SaveAlt } from '@material-ui/icons'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import createApi from 'services/api'
import { USERTYPES } from 'services/constants'
import { displayFixedDate, downloadExportedDataCSV } from 'services/helpers'
import history from 'services/history'
import { appDispatch, store } from 'services/store'
import { URL_LINK, URL_MANAGER } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'
import { fetchPlatformComponents } from 'store/platformComponents'

const api = createApi(store)

const DeleteSurveyDialog = ({
  surveyDialogOpen,
  setSurveyDialogOpen,
  deleteSurvey
}) => (
  <Dialog
    open={surveyDialogOpen}
    className="dialog"
    onClose={() => setSurveyDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de sondage</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer ce sondage?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setSurveyDialogOpen(false)} color="primary">
        Annuler
      </Button>
      <Button onClick={deleteSurvey} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
)

const SurveyListTable = ({
  surveys,
  setPopupOpen,
  platformComponents,
  exportSurvey
}) => {
  const userType = useSelector((state) => state.user.userType)
  const isAdmin = userType === USERTYPES.ADMIN

  return (
    <Grid item xs={12} style={{ marginBottom: '2rem' }}>
      <UtilsTable
        options={{
          pageSize: 10,
          search: true,
          searchAutoFocus: true,
          actionsColumnIndex: -1
        }}
        columns={[
          {
            title: 'Titre FR',
            field: 'titleContent.contentFR'
          },
          {
            title: 'Title EN',
            field: 'titleContent.contentEN'
          },
          {
            title: 'Description FR',
            field: 'descriptionContent.contentFR'
          },
          {
            title: 'Description EN',
            field: 'descriptionContent.contentEN'
          },
          {
            title: 'Date de publication',
            field: 'publishedDate',
            render: ({ publishedDate }) => (
              <span title={publishedDate}>
                {displayFixedDate(publishedDate)}
              </span>
            )
          },
          {
            title: 'Plateformes',
            render: ({ componentIds }) => {
              const componentCodes = componentIds.map(
                (componentId) =>
                  platformComponents.find(
                    (platformComponent) => platformComponent.id === componentId
                  ).code
              )

              return componentCodes.join(', ')
            }
          }
        ]}
        actions={[
          {
            isFreeAction: true,
            tooltip: 'Ajouter un sondage',
            icon: () => <AddComment />,
            onClick: () => {
              const addUrl = isAdmin
                ? URL_LINK.SURVEYS_ADD
                : URL_LINK.MANAGE_SURVEYS_ADD
              history.push(addUrl)
            }
          },
          (survey) => ({
            icon: () => <SaveAlt color="secondary" />,
            tooltip: 'Exporter ce sondage',
            onClick: () => exportSurvey(survey.id)
          }),
          (survey) => ({
            icon: () => <DeleteForever color="secondary" />,
            tooltip: 'Supprimer ce sondage',
            onClick: () => setPopupOpen(survey)
          })
        ]}
        onRowClick={(event, survey) => {
          const updateUrl = isAdmin
            ? URL_LINK.SURVEYS_UPDATE
            : URL_LINK.MANAGE_SURVEYS_UPDATE
          history.push(updateUrl + survey.id)
        }}
        data={surveys}
      />
    </Grid>
  )
}

const SurveyListInner = () => {
  const platformComponents = useSelector(
    (state) => state.platformComponents.platformComponentsList
  )
  const [surveys, setSurveys] = useState(null)
  const [selectedSurvey, setSelectedSurvey] = useState(false)
  const [surveyDialogOpen, setSurveyDialogOpen] = useState(false)

  const setPopupOpen = (survey) => {
    setSelectedSurvey(survey)
    setSurveyDialogOpen(true)
  }

  const getSurveys = async () => {
    try {
      const { data: surveysRes } = await api.get(URL_MANAGER.SURVEYS)
      setSurveys(surveysRes)
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("Les sondages n'ont pas pu être chargés"))
    }
  }

  const deleteSurvey = async () => {
    try {
      await api.delete(URL_MANAGER.SURVEYS_DELETE_ID(selectedSurvey.id))
      await getSurveys()
      setSurveyDialogOpen(false)
      appDispatch(successMessage('Le sondage a été supprimé'))
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("Le sondage n'a pas pu être supprimé"))
    }
  }

  const downloadSurveyExport = (surveyId) => {
    try {
      downloadExportedDataCSV(api, URL_MANAGER.SURVEYS_EXPORT(surveyId))
    } catch {
      appDispatch(errorMessage("Le fichier n'a pas pu être téléchargé"))
    }
  }

  useEffect(() => {
    appDispatch(fetchPlatformComponents(api))
    getSurveys()
  }, [])

  if (!surveys || !platformComponents.length) {
    return <LoadingBar />
  }

  return (
    <>
      <SurveyListTable
        surveys={surveys}
        platformComponents={platformComponents}
        setPopupOpen={setPopupOpen}
        exportSurvey={downloadSurveyExport}
      />
      <DeleteSurveyDialog
        surveyDialogOpen={surveyDialogOpen}
        setSurveyDialogOpen={setSurveyDialogOpen}
        deleteSurvey={deleteSurvey}
      />
    </>
  )
}

const SurveyList = () => (
  <>
    <Title title="Sondages" />
    <div className="toggler-section -white">
      <div className="container -data">
        <SurveyListInner />
      </div>
    </div>
  </>
)

export default SurveyList
