import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { Form, Formik } from 'formik'
import React from 'react'

const FormSkeleton = ({
  initialState,
  validationSchema,
  onSubmit,
  children,
  enableReinitalize = true,
  validate = undefined
}) => (
  <Formik
    initialValues={initialState}
    validationSchema={validationSchema}
    enableReinitialize={enableReinitalize}
    onSubmit={onSubmit}
    validate={validate}
  >
    {({ values, errors, setFieldValue, touched, isValid, dirty, ...props }) => (
      <Paper
        style={{
          padding: '2rem',
          height: '100%',
          display: 'flex',
          alignItems: 'center'
        }}
        variant="outlined"
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Form autoComplete="off">
              {children({
                values,
                errors,
                setFieldValue,
                touched,
                isValid,
                dirty,
                ...props
              })}
            </Form>
          </Grid>
        </Grid>
      </Paper>
    )}
  </Formik>
)

export default FormSkeleton
