import {
  Grid,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  FormControl,
  DialogActions,
  Button
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import * as yup from 'yup'
import * as fmi from 'formik-material-ui'
import { URL_MANAGER } from 'services/urls'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import ApplyButton from 'components/utils/ApplyButton'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PersonIcon from '@material-ui/icons/Person'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { USERTYPES } from 'services/constants'
import { errorMessage, successMessage } from 'store/message'
import { UsergroupUsersToolbar } from 'components/Shared/Usergroups/UsergroupUsersToolbar'
import { Field, Form, Formik } from 'formik'
import { matchingSets, toggleItemsInSet } from 'services/helpers'
import { useSelector } from 'react-redux'

const api = createApi(store)

const userTypes = {
  members: { name: 'Membres', icon: <PersonIcon /> },
  managers: { name: 'Gestionnaires', icon: <SupervisorAccountIcon /> }
}

const addMemberSchema = yup.object().shape({
  // String of comma separated emails
  // https://github.com/jquense/yup/issues/564#issuecomment-580407848
  usernames: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value
      }
      return originalValue ? originalValue.split(/[\s,]+/) : []
    })
    .of(yup.string().email(({ value }) => `Le courriel ${value} est invalide`))
})

const AddMemberForm = ({ isValid, dirty, isSubmitting }) => (
  <DialogContent>
    <Form autoComplete="off">
      <DialogContentText id="alert-dialog-description">
        Saisir le courriel ou une liste de courriels séparés par une virgule.
      </DialogContentText>
      <Grid container spacing={8} style={{ marginBottom: 0 }}>
        <Grid item xs={12}>
          <Field
            component={fmi.TextField}
            variant="outlined"
            fullWidth
            autoFocus
            label="Courriels"
            name="usernames"
          />
        </Grid>
      </Grid>
      <Grid container spacing={8} style={{ marginBottom: 0 }}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <ApplyButton
            dirty={dirty}
            isValid={isValid}
            isSubmitting={isSubmitting}
            endIcon={<PersonAddIcon />}
          >
            Ajouter
          </ApplyButton>
        </Grid>
      </Grid>
    </Form>
  </DialogContent>
)

const AddMemberDialog = ({
  addMemberDialogOpen,
  setAddMemberDialogOpen,
  addMembers
}) => (
  <Dialog
    open={addMemberDialogOpen}
    className="dialog"
    onClose={() => setAddMemberDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Ajouter membres</DialogTitle>
    <Formik
      component={AddMemberForm}
      initialValues={{ usernames: '' }}
      validationSchema={addMemberSchema}
      onSubmit={addMembers}
    />
  </Dialog>
)

const RemoveMemberDialog = ({
  removeMemberDialogOpen,
  setRemoveMemberDialogOpen,
  removeMember
}) => (
  <Dialog
    open={removeMemberDialogOpen}
    className="dialog"
    onClose={() => setRemoveMemberDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Retirer membre</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Retirer ce membre du groupe?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setRemoveMemberDialogOpen(false)} color="primary">
        Annuler
      </Button>
      <Button onClick={removeMember} color="primary" variant="contained">
        Confirmer
      </Button>
    </DialogActions>
  </Dialog>
)

const UsergroupMembersTable = ({
  selfUser,
  setAddMemberDialogOpen,
  setRemoveMemberDialogOpen,
  setSelectedMember,
  removeMember,
  addManager,
  removeManager,
  members,
  usergroupManagers,
  filterUsers,
  setFilterUsers,
  eligibleManagers
}) => (
  <Grid item xs={12} style={{ marginBottom: '2rem' }}>
    <UtilsTable
      components={{
        Toolbar: (props) => (
          <UsergroupUsersToolbar
            userTypes={userTypes}
            filterUsers={filterUsers}
            setFilterUsers={setFilterUsers}
            {...props}
          />
        )
      }}
      options={{
        pageSize: 10,
        sorting: true,
        thirdSortClick: false,
        showTextRowsSelected: false,
        actionsColumnIndex: -1
      }}
      actions={[
        {
          isFreeAction: true,
          tooltip: 'Ajouter des membres',
          icon: () => <PersonAddIcon />,
          onClick: async () => {
            setAddMemberDialogOpen(true)
          }
        },
        (user) => ({
          hidden: user.userName === selfUser.userName,
          tooltip: 'Retirer membre',
          icon: () => <RemoveCircleOutlineIcon color="secondary" />,
          // TODO mui 5 icon: () => <PersonRemoveIcon color="secondary" />,
          onClick: async (event, user) => {
            setSelectedMember(user)
            setRemoveMemberDialogOpen(true)
          }
        })
      ]}
      columns={[
        // Gestionnaires
        {
          cellStyle: {
            padding: '0 16px'
          },
          headerStyle: {
            padding: '0 16px'
          },
          width: 130,
          sorting: false,
          render: (rowData) => {
            const user = members.find(({ id }) => id === rowData.id)

            return (
              <Checkbox
                color="primary"
                checked={usergroupManagers.has(user)}
                disabled={
                  !eligibleManagers.has(user) ||
                  user.userName === selfUser.userName
                }
                style={{ padding: 0 }}
                onClick={(event) => {
                  const { checked } = event.target

                  if (checked) {
                    addManager(user)
                  } else {
                    removeManager(user)
                  }
                }}
              />
            )
          },
          title: 'Gestionnaire'
        },
        {
          title: 'Courriel',
          field: 'userName',
          align: 'left',
          width: '30%'
        },
        {
          title: 'Prénom',
          field: 'firstName',
          align: 'left',
          defaultSort: 'asc',
          cellStyle: {
            textTransform: 'capitalize'
          }
        },
        {
          title: 'Nom',
          field: 'lastName',
          align: 'left',
          cellStyle: {
            textTransform: 'capitalize'
          }
        }
      ]}
      data={members.filter((member) =>
        filterUsers === 'managers' ? usergroupManagers.has(member) : true
      )}
    />
  </Grid>
)

const UsergroupMembers = ({ id }) => {
  const selfUser = useSelector((state) => state.user)
  const [addMemberDialogOpen, setAddMemberDialogOpen] = useState(false)
  const [removeMemberDialogOpen, setRemoveMemberDialogOpen] = useState(false)
  const [selectedMember, setSelectedMember] = useState(null)

  const [members, setMembers] = useState([])
  const [initUsergroupManagers, setInitUsergroupManagers] = useState(new Set())
  const [usergroupManagers, setUsergroupManagers] = useState(new Set())
  const [eligibleManagers, setEligibleManagers] = useState(new Set())

  const [filterUsers, setFilterUsers] = useState(Object.keys(userTypes)[0])

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [dirty, setDirty] = useState(false)

  const getMembers = async () => {
    return await Promise.all([
      api.get(URL_MANAGER.GROUPS_GET_USERS, {
        params: { userGroupId: id }
      }),
      api.get(URL_MANAGER.GROUPS_GET_MANAGERS, {
        params: { userGroupId: id }
      })
    ])
      .then(
        ([{ data: usergroupMembersRes }, { data: usergroupManagersRes }]) => {
          const usergroupManagersSet = new Set(
            usergroupMembersRes.filter(
              (user) =>
                usergroupManagersRes.map(({ id }) => id).includes(user.id)
              // || user.userType === USERTYPES.ADMIN
            )
          )

          const eligibleManagersSet = new Set(
            usergroupMembersRes.filter(
              (user) => user.userType === USERTYPES.MANAGER && user.isActive
            )
          )

          setInitUsergroupManagers(usergroupManagersSet)
          setUsergroupManagers(usergroupManagersSet)
          setEligibleManagers(eligibleManagersSet)
          setMembers(usergroupMembersRes)
        }
      )
      .catch((err) => err)
  }

  const addMembers = async (values) => {
    const usernames = values.usernames
      .split(',')
      .map((username) => username.trim())
      .filter((username) => username.length)

    try {
      const usersReq = usernames.map((userName) =>
        api.get(URL_MANAGER.SEARCH_BY_USER_NAME, {
          params: { userName }
        })
      )
      const usersRes = await Promise.all(usersReq)
      const userIds = usersRes
        .map((userRes) => userRes.data.id)
        .filter((username) => username?.length)

      // No valid usernames, display error
      if (userIds.length === 0) {
        throw new Error()
      }

      await api.post(
        URL_MANAGER.GROUPS_ADD_USERS,
        { ids: userIds },
        {
          params: { groupId: id }
        }
      )

      const message =
        userIds.length === 1
          ? "L'utilisateur a été ajouté au groupe"
          : 'Les utilisateurs ont été ajoutés au groupe'

      await getMembers()
      appDispatch(successMessage(message))
      setAddMemberDialogOpen(false)
    } catch {
      const message =
        usernames.length === 1
          ? "L'utilisateur n'a pas pu être ajouté ou n'existe pas"
          : "Les utilisateurs n'ont pas pu être ajoutés ou n'existent pas"

      appDispatch(errorMessage(message))
    }
  }

  const removeMember = async () => {
    try {
      await api.post(
        URL_MANAGER.GROUPS_REMOVE_USERS,
        { ids: [selectedMember.id] },
        {
          params: { groupId: id }
        }
      )

      await getMembers()

      setRemoveMemberDialogOpen(false)
      appDispatch(
        successMessage(
          `L'utilisateur ${selectedMember.userName} a été retiré du groupe`
        )
      )
    } catch {
      appDispatch(
        errorMessage(
          `L'utilisateur ${selectedMember.userName} n'a pas pu être retiré du groupe`
        )
      )
    }
  }

  const addManager = async (user) => {
    toggleItemsInSet(setUsergroupManagers)(user, true)
  }

  const removeManager = async (user) => {
    toggleItemsInSet(setUsergroupManagers)(user, false)
  }

  const saveManagers = async () => {
    const addManagers = [...usergroupManagers].filter(
      (usergroupManager) => !initUsergroupManagers.has(usergroupManager)
    )
    const removeManagers = [...initUsergroupManagers].filter(
      (initUsergroupManager) => !usergroupManagers.has(initUsergroupManager)
    )

    setIsSubmitting(true)
    try {
      // Add newly checked managers
      await api.post(
        URL_MANAGER.GROUPS_ADD_MANAGERS,
        { ids: addManagers.map((manager) => manager.id) },
        {
          params: { groupId: id }
        }
      )

      // Remove newly unchecked managers
      await api.post(
        URL_MANAGER.GROUPS_REMOVE_MANAGERS,
        { ids: removeManagers.map((manager) => manager.id) },
        {
          params: { groupId: id }
        }
      )

      setInitUsergroupManagers(new Set([...usergroupManagers]))
      appDispatch(successMessage('Les changements ont été effectués'))
    } catch {
      appDispatch(errorMessage("Les changements n'ont pas pu être effectués"))
    } finally {
      setIsSubmitting(false)
    }
  }

  // Compare the initial state to the current state
  // to determine if changes have been made since last save
  useEffect(() => {
    const changed = !matchingSets(initUsergroupManagers, usergroupManagers)

    setDirty(changed)
  }, [initUsergroupManagers, usergroupManagers])

  useEffect(() => {
    getMembers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !members.length
    ? (
    <LoadingBar />
      )
    : (
    <>
      <UsergroupMembersTable
        selfUser={selfUser}
        setAddMemberDialogOpen={setAddMemberDialogOpen}
        setRemoveMemberDialogOpen={setRemoveMemberDialogOpen}
        setSelectedMember={setSelectedMember}
        removeMember={removeMember}
        addManager={addManager}
        removeManager={removeManager}
        members={members}
        usergroupManagers={usergroupManagers}
        filterUsers={filterUsers}
        setFilterUsers={setFilterUsers}
        eligibleManagers={eligibleManagers}
      />
      <Grid item className="centerCenter" xs={12}>
        <FormControl>
          <ApplyButton
            dirty={dirty}
            isValid={true}
            isSubmitting={isSubmitting}
            onClick={saveManagers}
          ></ApplyButton>
        </FormControl>
      </Grid>
      <AddMemberDialog
        addMemberDialogOpen={addMemberDialogOpen}
        setAddMemberDialogOpen={setAddMemberDialogOpen}
        addMembers={addMembers}
      />
      <RemoveMemberDialog
        removeMemberDialogOpen={removeMemberDialogOpen}
        setRemoveMemberDialogOpen={setRemoveMemberDialogOpen}
        removeMember={removeMember}
      />
    </>
      )
}

export default UsergroupMembers
