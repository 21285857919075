import React from 'react'

import logoRenv from 'images/logo-jalon-alpha-renv.svg'
import logoMtl from 'images/logo-montreal.svg'
import logoQc from 'images/logo-quebec.svg'
import ecoInnoQc from 'images/economie-innovation-quebec.png'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  subscribeButton: {
    padding: '14px 40px',
    color: 'white',
    border: '2px solid white',
    borderRadius: '100px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'white',
      color: theme.palette.burgundy.main
    }
  }
}))

function Footer () {
  const classes = useStyles()

  return (
    <footer className="site-footer">
      <div className="container">
        <div className="info-ctn">
          <div className="logo">
            <img src={logoRenv} alt={'Jalon Mtl'} />
          </div>
          <div className="news-ctn">
            <p className="news-text">
              Inscrivez-vous à l’infolettre Jalon pour ne rien manquer
            </p>
            <Button
              size="large"
              variant="outlined"
              className={classes.subscribeButton}
              href="https://jalonmtl.org/infolettre/"
            >
              Abonnement
            </Button>
          </div>
        </div>
        <div className="sub-footer">
          <ul className="sub-menu">
            <li>
              <span>Avec la collaboration de</span>
              <a
                href="http://ville.montreal.qc.ca/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoMtl} alt="Montréal" />
              </a>
              <a
                href="https://www.ville.quebec.qc.ca/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={logoQc} alt="Québec" />
              </a>
              <a
                href="https://www.economie.gouv.qc.ca/accueil/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={ecoInnoQc} alt="Économie Innovation QC" />
              </a>
            </li>
            <li>
              <a href="https://jalonmtl.org/politique-de-confidentialite/">
                Politique de confidentialité
              </a>
            </li>
            <li>
              <a href="https://jalonmtl.org/carrieres/">Carrières</a>
            </li>
          </ul>
          <ul className="social">
            <li>
              <a
                className="social-link"
                href="https://www.linkedin.com/company/jalon/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="icon icon-linkedin"></span>
              </a>
            </li>
            <li>
              <a
                className="social-link"
                href="https://www.facebook.com/Jalonmtl/"
                target="_blank"
                rel="noreferrer"
              >
                <span className="icon icon-facebook"></span>
              </a>
            </li>
            <li>
              <a className="social-link" href="https://twitter.com/jalonmtl">
                <span className="icon icon-twitter"></span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
