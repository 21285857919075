import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Title from 'components/utils/Title/Title'
import UsergroupMembers from './UsergroupMembers'
import { Breadcrumbs, Grid, Typography } from '@material-ui/core'
import { URL_MANAGER, URL_LINK } from 'services/urls'
import createApi from 'services/api'
import { store } from 'services/store'

const api = createApi(store)

const UpdateUsergroup = () => {
  // User id from URL
  const { id } = useParams()
  const [name, setName] = useState('')

  useEffect(() => {
    api.get(URL_MANAGER.GROUPS_ID(id)).then(({ data }) => {
      setName(data.name)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <Title title="Modifier un groupe" />
      <div className="toggler-section -white">
        <div className="container -data">
          <Grid container spacing={8}>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={URL_LINK.MANAGED_GROUPS}>Groupes gérés</Link>
                <Typography>{name}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <UsergroupMembers id={id} />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default UpdateUsergroup
