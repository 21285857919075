import React from 'react'
import Title from 'components/utils/Title/Title'
import image from 'images/mob_image_3.png'

function Index () {
  return (
    <>
      <Title title={'Portail en données mobilité de Jalon'} />
      <div className="toggler-section -white">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="text-ctn">
                <p>
                  Bienvenue sur la Plateforme numérique de données en mobilité
                  de Jalon.
                </p>

                <p>
                  Réalisé en collaboration avec le Ministère de l'Économie et de
                  l'Innovation, et encouragé par Montréal en commun, Jalon rend
                  disponible sa plateforme de données en mobilité pour ceux et
                  celles qui sont intéressés à mieux comprendre la mobilité. Cet
                  outil vise à mettre en commun, à partager et à valoriser de
                  façon innovante des données liées à la mobilité recueillies
                  par différents acteurs à travers le Québec et le Canada.
                </p>
                {/*
                  <p>
                    Cette version vise à présenter les grandes fonctions de la
                    plateforme et à recueillir les commentaires des différents
                    acteurs de l'écosystème de la mobilité. L'objectif est de
                    permettre à tous de participer à l'élaboration fonctionnelle
                    de la plateforme afin d'en assurer la pertinence.
                  </p> */}

                <p>
                  À travers ces différents échanges, Jalon s'intéresse tout
                  particulièrement à répertorier les données qui représentent un
                  des nombreux aspects de la mobilité des citoyens et des
                  marchandises. Ajoutons également que Jalon protège la vie
                  privée des citoyens en anonymisant, lorsque nécessaire, les
                  données partagées.
                </p>

                <p>
                  Ainsi Jalon est fière d’offrir cette version bêta de la
                  plateforme. La plateforme offre aujourd’hui un ensemble de jeu
                  de données ainsi que des outils pour explorer ces données.
                </p>
                <p>
                  Jalon continue avec ferveur à faire évoluer cette plateforme
                  tant dans les outils que l’inventaire des données mais aussi
                  l’identification des besoins en données des organisations de
                  l'écosystème.
                </p>

                <p>
                  L’inventaire des ensembles de données publiques est en accès
                  libre. N’hésitez pas à parcourir notre catalogue.
                </p>

                <p>
                  De plus, en vous créant un compte, il vous sera alors possible
                  d’accéder aux données de chacun des ensembles de données
                  publiques. Votre compte vous donnera aussi la possibilité
                  d'accéder aux données via notre API si vous en faites la
                  demande.
                </p>

                <p>
                  Pour toutes questions n’hésitez pas à communiquer avec{' '}
                  <a href="mailto:plateforme@jalonmtl.services">
                    l'équipe de Jalon
                  </a>
                  .
                </p>
              </div>
            </div>
            <div className="col-md-5 push-md-1">
              <div className="image-ctn">
                <img src={image} alt={'Enfant sur un vélo dans la neige'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Index
