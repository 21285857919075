import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { Field, Form, Formik } from 'formik'
import * as fmi from 'formik-material-ui'
import React from 'react'
import history from 'services/history'
import * as yup from 'yup'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { URL_ADMIN } from 'services/urls'
import ApplyButton from 'components/utils/ApplyButton'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import { EXCEPTIONS } from 'services/constants'
import { successMessage } from 'store/message'
import DeleteButton from 'components/utils/DeleteButton'

const api = createApi(store)

// Validation schema
const GroupSchema = yup.object().shape({
  // id: yup.number().required("Requis"),
  name: yup.string().max(255, 'Nom trop long').required('Requis'),
  description: yup.string().max(255, 'Description trop longue')
})

export const UsergroupDetailsForm = ({
  initialState,
  saveGroup,
  setPopupOpen,
  alreadyInUseName,
  setAlreadyInUseName
}) => (
  <Grid item xs={12}>
    <Formik
      initialValues={initialState}
      validationSchema={GroupSchema}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        saveGroup(values).catch((error) => {
          if (
            error.response.data.exceptionType ===
            EXCEPTIONS.UserGroupAlreadyExists
          ) {
            setAlreadyInUseName(alreadyInUseName.concat([values.name]))
            setErrors({ name: 'Nom de groupe déjà utilisé' })
            setSubmitting(false)
          }
        })
      }}
      validate={(values) => {
        const errors = {}
        if (alreadyInUseName.includes(values.name)) {
          errors.name = 'Nom de groupe déjà utilisé'
        }
        return errors
      }}
    >
      {({ values, errors, setFieldValue, touched, isValid, dirty }) => (
        <Form autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                autoFocus
                label="Nom de groupe"
                name="name"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                label="Description"
                name="description"
              />
            </Grid>

            <Grid item className="centerCenter" xs={6}>
              <DeleteButton
                onClick={() => {
                  setPopupOpen()
                }}
              >
                Supprimer le groupe
              </DeleteButton>
            </Grid>
            <Grid item className="centerCenter" xs={6}>
              <ApplyButton dirty={dirty} isValid={isValid} />
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  </Grid>
)

const DeleteUsergroupDialog = ({ open, setOpen, deleteGroup }) => (
  <Dialog
    open={open}
    className="dialog"
    onClose={() => {
      setOpen(false)
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de groupe</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer ce groupe?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          setOpen(false)
        }}
        color="primary"
      >
        Annuler
      </Button>
      <Button onClick={deleteGroup} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
)

const UsergroupDetails = ({ id, setUsergroupName }) => {
  const [groupInfo, setGroupInfo] = React.useState()
  const [open, setOpen] = React.useState(false)
  const [alreadyInUseName, setAlreadyInUseName] = React.useState([])

  const setPopupOpen = () => {
    setOpen(true)
  }

  const deleteGroup = () => {
    api
      .delete(URL_ADMIN.GROUPS_ID(id))
      .then(() => {
        setOpen(false)
        history.push(URL_ADMIN.GROUPS)
      })
      .catch((err) => err)
  }

  React.useEffect(() => {
    api
      .get(URL_ADMIN.GROUPS_ID(id))
      .then((data) => {
        setGroupInfo(data.data)
      })
      .catch((err) => err)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const saveGroup = (values) => {
    return api.put(URL_ADMIN.GROUPS, { id, ...values }).then((data) => {
      setGroupInfo(data.data)
      setUsergroupName(data.data.name)
      appDispatch(successMessage('Les changements ont été effectués'))
    })
  }

  return (
    <>
      {groupInfo
        ? (
        <Grid container spacing={3}>
          <UsergroupDetailsForm
            initialState={{
              name: groupInfo.name,
              description: groupInfo.description || ''
            }}
            saveGroup={saveGroup}
            setPopupOpen={setPopupOpen}
            alreadyInUseName={alreadyInUseName}
            setAlreadyInUseName={setAlreadyInUseName}
          />
        </Grid>
          )
        : (
        <LoadingBar />
          )}
      <DeleteUsergroupDialog
        open={open}
        setOpen={setOpen}
        deleteGroup={deleteGroup}
      />
    </>
  )
}
export default UsergroupDetails
