import {
  Box,
  Breadcrumbs,
  Grid,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices'
import InfoIcon from '@material-ui/icons/Info'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import TabPanel from 'components/utils/TabPanel'
import Title from 'components/utils/Title/Title'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import createApi from 'services/api'
import { store } from 'services/store'
import { URL_ADMIN, URL_LINK } from 'services/urls'
import UsergroupDetails from './UsergroupDetails'
import UsergroupPlatform from './UsergroupPlatform'
import UsergroupUsers from './UsergroupUsers'

const api = createApi(store)

export const UpdateUsergroupInner = ({ id = null }) => {
  const [name, setName] = useState('')
  const [tab, setTab] = useState(0)

  useEffect(() => {
    api.get(URL_ADMIN.GROUPS_ID(id)).then(({ data }) => {
      setName(data.name)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid container spacing={8}>
      <Grid item>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={URL_LINK.USERGROUPS}>Groupes</Link>
          <Typography>{name}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper variant="outlined">
          <Box p={3}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Tabs
                value={tab}
                onChange={(event, value) => setTab(value)}
                aria-label="Groupe sections"
              >
                <Tab label="Détails" icon={<InfoIcon />} id="simple-tab-0" />
                <Tab
                  label="Droits d'accès"
                  icon={<SupervisorAccountIcon />}
                  id="simple-tab-1"
                />
                <Tab
                  label="Plateforme"
                  icon={<ImportantDevicesIcon />}
                  id="simple-tab-2"
                />
              </Tabs>
            </div>
            <TabPanel keepMounted value={tab} index={0}>
              <UsergroupDetails id={id} setUsergroupName={setName} />
            </TabPanel>
            <TabPanel keepMounted value={tab} index={1}>
              <UsergroupUsers id={id} />
            </TabPanel>
            <TabPanel keepMounted value={tab} index={2}>
              <UsergroupPlatform id={id} />
            </TabPanel>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  )
}

const UpdateUsergroup = () => {
  const { id } = useParams()

  return (
    <div>
      <Title title="Modifier un groupe" />
      <div className="toggler-section -white">
        <div className="container -data">
          <UpdateUsergroupInner id={id} />
        </div>
      </div>
    </div>
  )
}

export default UpdateUsergroup
