import { Grid } from '@material-ui/core'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import { useEffect, useState } from 'react'
import createApi from 'services/api'
import { appDispatch, store } from 'services/store'
import { URL_ADMIN, URL_MANAGER } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'
import { clearPlatformComponents } from 'store/platformComponents'

const api = createApi(store)

const ComponentListTable = ({
  components,
  onRowAdd,
  onRowUpdate,
  onRowDelete
}) => {
  return (
    <Grid item xs={12} style={{ marginBottom: '2rem' }}>
      <UtilsTable
        options={{
          pageSize: 10,
          search: true,
          sorting: true,
          searchAutoFocus: true,
          actionsColumnIndex: -1
        }}
        columns={[
          {
            title: 'Code',
            field: 'code',
            defaultSort: 'asc'
          },
          {
            title: 'Is Challenge?',
            field: 'isChallenge',
            type: 'boolean'
          }
        ]}
        editable={{
          onRowAdd,
          onRowUpdate,
          onRowDelete
        }}
        data={components}
      />
    </Grid>
  )
}

const ComponentListInner = () => {
  const [components, setComponents] = useState(null)

  const getComponents = async () => {
    try {
      const { data: componentsRes } = await api.get(
        URL_MANAGER.MANAGED_PLATFORM_COMPONENTS
      )
      setComponents(componentsRes)
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("Les plateformes n'ont pas pu être chargées"))
    }
  }

  const deleteComponent = async ({ id }) => {
    try {
      await api.delete(URL_ADMIN.PLATFORM_COMPONENTS_BASE + '/' + id)
      appDispatch(successMessage('La plateforme a été supprimée'))
      appDispatch(clearPlatformComponents())

      return getComponents()
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("La plateforme n'a pas pu être supprimée"))
    }
  }

  const addComponent = async ({ code, isChallenge }) => {
    try {
      const componentBody = {
        code,
        isChallenge
      }

      await api.post(
        URL_ADMIN.PLATFORM_COMPONENTS_BASE,
        componentBody
      )

      appDispatch(successMessage('Données créées avec succès'))
      appDispatch(clearPlatformComponents())

      return getComponents()
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("Les données n'ont pas pu être créées"))
    }
  }

  const updateComponent = async ({ id, code, isChallenge }) => {
    try {
      const componentBody = {
        id,
        code,
        isChallenge
      }

      await api.put(
        URL_ADMIN.PLATFORM_COMPONENTS_BASE,
        componentBody
      )

      appDispatch(successMessage('Les changements ont été effectués'))
      appDispatch(clearPlatformComponents())

      return getComponents()
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("Les changements n'ont pas pu être effectués"))
    }
  }

  useEffect(() => {
    getComponents()
  }, [])

  if (!components) {
    return <LoadingBar />
  }

  return (
    <>
      <ComponentListTable
        components={components}
        onRowAdd={addComponent}
        onRowUpdate={updateComponent}
        onRowDelete={deleteComponent}
      />
    </>
  )
}

const ComponentList = () => (
  <>
    <Title title="Plateformes" />
    <div className="toggler-section -white">
      <div className="container -data">
        <ComponentListInner />
      </div>
    </div>
  </>
)

export default ComponentList
