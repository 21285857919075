import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DeleteForever } from '@material-ui/icons'
import GroupAddIcon from '@material-ui/icons/GroupAdd'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import history from 'services/history'
import React, { useEffect, useState } from 'react'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { URL_ADMIN, URL_LINK } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'

const api = createApi(store)

const DeleteUsergroupDialog = ({ open, setOpen, deleteGroup }) => (
  <Dialog
    open={open}
    className="dialog"
    onClose={() => {
      setOpen(false)
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de groupe</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer ce groupe?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={() => {
          setOpen(false)
        }}
        color="primary"
      >
        Annuler
      </Button>
      <Button onClick={deleteGroup} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
)

const UserGroupsTable = ({ groups, setPopupOpen }) => (
  <UtilsTable
    title="Liste des groupes"
    options={{
      search: true,
      paging: false,
      searchAutoFocus: true,
      actionsColumnIndex: -1
    }}
    columns={[
      {
        title: 'Nom du groupe',
        field: 'name',
        align: 'left',
        defaultSort: 'asc',
        width: '30%'
      },
      { title: 'Description', field: 'description', align: 'left' }
    ]}
    actions={[
      {
        isFreeAction: true,
        tooltip: 'Ajouter un groupe',
        icon: () => <GroupAddIcon />,
        onClick: () => {
          history.push(URL_LINK.USERGROUPS_ADD)
        }
      },
      (usergroup) => ({
        icon: () => <DeleteForever color="secondary" />,
        tooltip: 'Supprimer ce groupe',
        onClick: () => setPopupOpen(usergroup)
      })
    ]}
    onRowClick={(event, usergroup) =>
      history.push(URL_LINK.USERGROUPS_UPDATE + usergroup.id)
    }
    data={groups}
  />
)

export const UsergroupsInner = () => {
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedUsergroup, setSelectedUsergroup] = useState()

  const setPopupOpen = (usergroup) => {
    setOpen(true)
    setSelectedUsergroup(usergroup)
  }

  const getGroups = () => {
    setLoading(true)
    api
      .get(URL_ADMIN.GROUPS)
      .then((data) => {
        setGroups(data.data)
      })
      .then(() => {
        setLoading(false)
      })
      .catch(() =>
        appDispatch(
          errorMessage("La liste des groupes d'accès n'a pas pu être chargée")
        )
      )
  }

  const deleteGroup = () => {
    api
      .delete(URL_ADMIN.GROUPS_ID(selectedUsergroup.id))
      .then(() => {
        setOpen(false)
        appDispatch(
          successMessage(`Le groupe ${selectedUsergroup.name} a été supprimé`)
        )
        setSelectedUsergroup(null)
        getGroups()
      })
      .catch(() =>
        appDispatch(errorMessage('Le groupe n\'a pas pu être supprimé'))
      )
  }

  useEffect(getGroups, [])

  if (loading) return <LoadingBar />

  return (
    <>
      <UserGroupsTable groups={groups} setPopupOpen={setPopupOpen} />
      <DeleteUsergroupDialog
        open={open}
        setOpen={setOpen}
        deleteGroup={deleteGroup}
      />
    </>
  )
}

const Usergroups = () => (
  <div>
    <Title title="Groupes d'accès" />
    <div className="toggler-section -white">
      <div className="container -data">
        <UsergroupsInner />
      </div>
    </div>
  </div>
)

export default Usergroups
