import {
  combineReducers,
  configureStore,
  getDefaultMiddleware
} from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import sessionStorage from 'redux-persist/lib/storage/session'

import message from 'store/message'
import user from 'store/user'
import platformComponents from 'store/platformComponents'

const persistConfig = {
  key: 'root',
  version: 2,
  whitelist: ['user'],
  storage
}

const platformComponentsPersistConfig = {
  key: 'platformComponentsStorage',
  storage: sessionStorage
}

const reducer = combineReducers({
  message,
  user,
  platformComponents: persistReducer(
    platformComponentsPersistConfig,
    platformComponents
  )
})

const store = configureStore({
  reducer: persistReducer(persistConfig, reducer),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
})

const { dispatch } = store
const persistor = persistStore(store)

export { dispatch as appDispatch }
export { store, reducer, persistor }
