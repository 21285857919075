import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import createApi from 'services/api'
import {
  eventsDataFetch,
  formatDate,
  getEventTableOptions
} from 'services/helpers'
import { appDispatch, store } from 'services/store'
import { errorMessage } from 'store/message'

const api = createApi(store)

const tableOptions = (activeEventType) =>
  getEventTableOptions(api, appDispatch, errorMessage, activeEventType)

const tableOptionsWithUserGroupId = (activeEventType, userGroupId) =>
  getEventTableOptions(
    api,
    appDispatch,
    errorMessage,
    activeEventType,
    { userGroupId }
  )

const UsergroupMembershipChangeEventsTable = ({
  activeEventType,
  tableRef,
  userGroupId
}) => {
  let params = {}
  if (userGroupId != null) {
    params = { userGroupId }
  }
  return (
    <UtilsTable
      ref={tableRef}
      compact
      options={
        userGroupId == null
          ? tableOptions(activeEventType)
          : tableOptionsWithUserGroupId(activeEventType, userGroupId)
      }
      data={eventsDataFetch(
        activeEventType,
        api,
        appDispatch,
        errorMessage,
        params
      )}
      columns={[
        {
          title: 'eventDate',
          render: ({ eventDate }) => (
            <span title={eventDate}>
              {formatDate(eventDate, {
                dateStyle: 'short',
                timeStyle: 'short'
              })}
            </span>
          ),
          cellStyle: { whiteSpace: 'pre' }
        },
        { field: 'currentUserName', title: 'currentUserName' },
        { field: 'groupName', title: 'groupName' },
        { field: 'action', title: 'action' },
        { field: 'recordType', title: 'recordType' },
        { field: 'recordName', title: 'recordName' }
      ]}
    />
  )
}

const UserUpdateEventsTable = ({ activeEventType, tableRef }) => {
  return (
    <UtilsTable
      ref={tableRef}
      compact
      options={tableOptions(activeEventType)}
      data={eventsDataFetch(activeEventType, api, appDispatch, errorMessage)}
      columns={[
        {
          title: 'eventDate',
          render: ({ eventDate }) => (
            <span title={eventDate}>
              {formatDate(eventDate, {
                dateStyle: 'short',
                timeStyle: 'short'
              })}
            </span>
          ),
          cellStyle: { whiteSpace: 'pre' }
        },
        { field: 'currentUserName', title: 'currentUserName' },
        { field: 'userName', title: 'userName' },
        { field: 'fieldName', title: 'fieldName' },
        { field: 'oldFieldValue', title: 'oldFieldValue' },
        { field: 'newFieldValue', title: 'newFieldValue' }
      ]}
    />
  )
}

const UserMonitoredActionEventsTable = ({ activeEventType, tableRef }) => {
  return (
    <UtilsTable
      ref={tableRef}
      compact
      options={tableOptions(activeEventType)}
      data={eventsDataFetch(activeEventType, api, appDispatch, errorMessage)}
      columns={[
        {
          title: 'eventDate',
          render: ({ eventDate }) => (
            <span title={eventDate}>
              {formatDate(eventDate, {
                dateStyle: 'short',
                timeStyle: 'short'
              })}
            </span>
          ),
          cellStyle: { whiteSpace: 'pre' }
        },
        { field: 'isSuccessful', title: 'isSuccessful' },
        { field: 'currentUserName', title: 'currentUserName' },
        { field: 'ipAddress', title: 'ipAddress' },
        { field: 'endpointAccessed', title: 'endpointAccessed' },
        { field: 'endpointController', title: 'endpointController' },
        { field: 'queryString', title: 'queryString' },
        { field: 'jsonRequestBody', title: 'jsonRequestBody' },
        { field: 'httpMethod', title: 'httpMethod' },
        { field: 'endpointProtocol', title: 'endpointProtocol' },
        { field: 'endpointServerName', title: 'endpointServerName' }
      ]}
    />
  )
}

const LoginEventsTable = ({ activeEventType, tableRef }) => {
  return (
    <UtilsTable
      ref={tableRef}
      compact
      options={tableOptions(activeEventType)}
      data={eventsDataFetch(activeEventType, api, appDispatch, errorMessage)}
      columns={[
        {
          title: 'eventDate',
          render: ({ eventDate }) => (
            <span title={eventDate}>
              {formatDate(eventDate, {
                dateStyle: 'short',
                timeStyle: 'short'
              })}
            </span>
          ),
          cellStyle: { whiteSpace: 'pre' }
        },
        { field: 'currentUserName', title: 'currentUserName' },
        { field: 'ipAddress', title: 'ipAddress' },
        { field: 'isSuccessful', title: 'isSuccessful' }
      ]}
    />
  )
}

const UserGroupCreationEventsTable = ({ activeEventType, tableRef }) => {
  return (
    <UtilsTable
      ref={tableRef}
      compact
      options={tableOptions(activeEventType)}
      data={eventsDataFetch(activeEventType, api, appDispatch, errorMessage)}
      columns={[
        { field: 'name', title: 'name' },
        {
          title: 'creationDate',
          render: ({ auditCreationDate }) => (
            <span title={auditCreationDate}>
              {formatDate(auditCreationDate, {
                dateStyle: 'short',
                timeStyle: 'short'
              })}
            </span>
          ),
          cellStyle: { whiteSpace: 'pre' }
        },
        { field: 'isSoftDeleted', title: 'isSoftDeleted' }
      ]}
    />
  )
}

const UserCreationEventsTable = ({ activeEventType, tableRef }) => {
  return (
    <UtilsTable
      ref={tableRef}
      compact
      options={tableOptions(activeEventType)}
      data={eventsDataFetch(activeEventType, api, appDispatch, errorMessage)}
      columns={[
        { field: 'userName', title: 'email' },
        { field: 'firstName', title: 'firstName' },
        { field: 'lastName', title: 'lastName' },
        { field: 'userType', title: 'userType' },
        { field: 'lang', title: 'lang' },
        {
          title: 'creationDate',
          render: ({ auditCreationDate }) => (
            <span title={auditCreationDate}>
              {formatDate(auditCreationDate, {
                dateStyle: 'short',
                timeStyle: 'short'
              })}
            </span>
          ),
          cellStyle: { whiteSpace: 'pre' }
        },
        { field: 'isSoftDeleted', title: 'isSoftDeleted' }
      ]}
    />
  )
}

export {
  LoginEventsTable,
  UserUpdateEventsTable,
  UserCreationEventsTable,
  UserGroupCreationEventsTable,
  UserMonitoredActionEventsTable,
  UsergroupMembershipChangeEventsTable
}
