import axios from 'axios'
import { expire } from 'store/user'
import { clearPlatformComponents } from 'store/platformComponents'
import { base, URL_ADMIN, URL_USER } from './urls'
import { EXCEPTIONS } from './constants'

const urls_endpoints_filtered_logs = [
  URL_ADMIN.USER_UPDATE_PASSWORD,
  URL_USER.LOGIN,
  URL_USER.SELF_UPDATE_PASSWORD,
  URL_USER.UPDATE_PWD_RESET
]

const createApi = (reduxStore) => {
  const axiosInstance = axios.create({
    baseURL: base,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  })

  function errorHandling (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response.data)
      console.log(error.response.status)
      console.log(error.response.headers)
      if (
        error.response.status === 401 &&
        error.response.data &&
        error.response.data.error === EXCEPTIONS.TokenExpired
      ) {
        // TODO: Open login dialog
        reduxStore.dispatch(expire())
        reduxStore.dispatch(clearPlatformComponents())
      }
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message)
    }
    if (
      !error.config &&
      urls_endpoints_filtered_logs.indexOf(error.config.url) !== -1
    ) { console.log(error.config) }

    return Promise.reject(error)
  }

  axiosInstance.interceptors.request.use((config) => {
    // https://stackoverflow.com/a/43052288/552963
    const { loggedIn, token } = reduxStore.getState().user

    if (loggedIn) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  })

  axiosInstance.interceptors.response.use(
    (response) => response,
    errorHandling
  )

  return axiosInstance
}

export default createApi
