import Button from '@material-ui/core/Button'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import { makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    color: red[600],
    borderColor: red[600],
    '&:hover': {
      backgroundColor: red[600],
      color: theme.palette.getContrastText(red[600])
    },
    '& ~ .MuiButton-root': {
      marginLeft: 32
    }
  }
}))

const DeleteButton = (props) => {
  const classes = useStyles()

  return (
    <Button
      variant="outlined"
      className={classes.deleteButton}
      endIcon={<DeleteForeverIcon />}
      {...props}
    />
  )
}

export default DeleteButton
