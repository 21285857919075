export const USERTYPES = {
  REGULAR: 'regular_user',
  MANAGER: 'access_manager',
  ADMIN: 'jalon_admin'
}

export const LANGS = {
  EN: 'English',
  FR: 'Français'
}

export const LOCALES = {
  EN: 'en-CA',
  FR: 'fr-CA'
}

export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*-])(?=\S+$).{6,24}$/

export const REQUEST_STATUS = {
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

export const EXCEPTIONS = {
  // response.data.exceptionType
  TokenExpired: 'token_expired',
  EmailAlreadyExists: 'EmailAlreadyExistsException',
  UserNameAlreadyExists: 'UserNameAlreadyExistsException',
  UserGroupAlreadyExists: 'RecordAlreadyExistsException',
  RecordAlreadyExistsException: 'RecordAlreadyExistsException',
  // response.data.error
  ChallengeInUseErrorMessage:
    'Cannot delete this challenge because it has related users or trips'
}

export const RESOURCE_TYPES = ['FILE', 'API']
export const TABLE_TYPES = ['TABLE', 'JOIN']
export const RESOURCE_LEVELS = [1, 2]

export const SURVEY_ANSWER_TYPES = [
  'free_text',
  'multi_choice',
  'single_choice'
]

export const DATA_API_CURL = (base, resourceID) => (
  <>
    <span>curl --location </span>
    <span>--request POST '{base}public/data' </span>
    <span>
      --header 'Authorization: Bearer {'<'}JWT_TOKEN{'>'}'
    </span>
    <span>--header 'Content-Type: application/json' </span>
    <span>--data-raw '&#123; "table": "{resourceID}" &#125;'</span>
  </>
)

export const PAGES_LIST = {
  public: ['Connexion', "S'inscrire", 'Accueil', 'Mot de passe oublié', 'Réinitialiser son mot de passe', 'A propos', 'Aide', 'Données'],
  user: ['Mon profil', 'Mes groupes'],
  manager: ['Groupes gérés', 'Événements', 'Groupes gérés avec des données exportables', 'Annonces', 'Sondages']
}
