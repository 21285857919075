import frLocale from "date-fns/locale/fr";
import enLocale from "date-fns/locale/en-US";

const i18n = {
  en: {
    dateFormat: {
      locale: enLocale,
      ampm: true,
      long: "MMMM d, yyyy",
      dateTime: "MMMM d, yyyy hh:mm",
    },
    answerTypes: {
      free_text: "Text",
      multi_choice: "Multiple choice",
      single_choice: "Single choice",
    },
  },
  fr: {
    dateFormat: {
      locale: frLocale,
      ampm: false,
      long: "d MMMM yyyy",
      dateTime: "d MMMM yyyy à HH:mm",
    },
    answerTypes: {
      free_text: "Texte",
      multi_choice: "Choix multiple",
      single_choice: "Choix unique",
    },
    materialTable: {
      body: {
        emptyDataSourceMessage: "Aucune donnée à afficher",
        addTooltip: "Ajouter",
        deleteTooltip: "Supprimer",
        editTooltip: "Modifier",
        filterRow: {
          filterTooltip: "Filtrer",
        },
        editRow: {
          deleteText: "Supprimer cette ligne?",
          cancelTooltip: "Annuler",
          saveTooltip: "Confirmer",
        },
      },
      grouping: {
        placeholder: "Tirer l'entête ...",
        groupedBy: "Grouper par:",
      },
      actions: "",
      header: {
        actions: "",
      },
      pagination: {
        labelDisplayedRows: "{from}-{to} de {count}",
        labelRowsSelect: "lignes",
        labelRowsPerPage: "lignes par page:",
        firstAriaLabel: "Première page",
        firstTooltip: "Première page",
        previousAriaLabel: "Page précédente",
        previousTooltip: "Page précédente",
        nextAriaLabel: "Page suivante",
        nextTooltip: "Page suivante",
        lastAriaLabel: "Dernière page",
        lastTooltip: "Dernière page",
      },
      toolbar: {
        addRemoveColumns: "Ajouter ou supprimer des colonnes",
        nRowsSelected: "{0} ligne(s) sélectionée(s)",
        showColumnsTitle: "Voir les colonnes",
        showColumnsAriaLabel: "Voir les colonnes",
        exportTitle: "Exporter",
        exportAriaLabel: "Exporter",
        exportCSVName: "Exporter en CSV",
        exportPDFName: "Exporter en PDF",
        searchTooltip: "Recherche",
        searchPlaceholder: "Recherche",
        searchAriaLabel: "Recherche",
        clearSearchAriaLabel: "Supprimer recherche",
      },
    },
  },
};

export default i18n;
