import { Breadcrumbs, Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ApplyButton from 'components/utils/ApplyButton'
import { Field, Form, Formik } from 'formik'
import * as fmi from 'formik-material-ui'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { EXCEPTIONS } from 'services/constants'
import 'services/helpers'
import { URL_LINK } from 'services/urls'
import * as yup from 'yup'

// Validation schema
const SignupSchemaGuest = yup.object({
  email: yup
    .string()
    .email('Courriel invalide')
    .max(128, 'Courriel trop long')
    .required('Requis'),
  firstName: yup.string().max(128, 'Trop long').required('Requis'),
  lastName: yup.string().max(128, 'Trop long').required('Requis'),
})

const SignupSchemaAdmin = yup.object({
  email: yup
    .string()
    .email('Courriel invalide')
    .max(128, 'Courriel trop long')
    .required('Requis'),
  firstName: yup.string().max(128, 'Trop long').required('Requis'),
  lastName: yup.string().max(128, 'Trop long').required('Requis'),
})

const AddUserForm = ({
  initialUserState,
  saveUser,
  submitLabel,
  isAdmin
}) => {
  const [alreadyInUseEmail, setAlreadyInUseEmail] = useState([])
  return (
    <>
    <Formik
      initialValues={initialUserState}
      validationSchema={isAdmin ? SignupSchemaAdmin : SignupSchemaGuest}
      onSubmit={async (values, { resetForm, setErrors }) => {
        try {
          await saveUser(values)
          resetForm()
        } catch (error) {
          if (
            error.response.data.exceptionType === EXCEPTIONS.EmailAlreadyExists
          ) {
            setAlreadyInUseEmail(alreadyInUseEmail.concat([values.email]))
            setErrors({ email: 'Courriel déjà utilisé' })
          }
        }
      }}
      validate={(values) => {
        const errors = {}
        if (alreadyInUseEmail.includes(values.email)) {
          errors.email = 'Courriel déjà utilisé'
        }
        return errors
      }}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Grid container justifyContent="center">
          <Grid container spacing={8}>
            <Grid item>
              <Breadcrumbs aria-label="breadcrumb">
                <Link to={URL_LINK.USERS}>Utilisateurs</Link>
                <Typography>Ajouter</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Paper style={{ padding: '2rem' }} variant="outlined">
                <Form autoComplete="off">
                  <Grid container spacing={8}>
                    <Grid item xs={12}>
                      <Field
                        component={fmi.TextField}
                        variant="outlined"
                        fullWidth
                        autoFocus
                        label="Courriel"
                        name="email"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={fmi.TextField}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          style: { textTransform: 'capitalize' }
                        }}
                        label="Prénom"
                        name="firstName"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        component={fmi.TextField}
                        variant="outlined"
                        fullWidth
                        inputProps={{
                          style: { textTransform: 'capitalize' }
                        }}
                        label="Nom"
                        name="lastName"
                      />
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={12} className="centerCenter">
                      <ApplyButton
                        dirty={dirty}
                        isValid={isValid}
                        isSubmitting={isSubmitting}
                        endIcon={<PersonAddIcon />}
                      >
                        {submitLabel}
                      </ApplyButton>
                    </Grid>
                  </Grid>
                </Form>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Formik>
    </>
  )
}

export default AddUserForm
