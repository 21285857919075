import {
  Breadcrumbs,
  Button, Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid, Paper, TextField, Typography
} from "@material-ui/core";
import {
  AddComment, DeleteForever, Save
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import NotFound from "components/Shared/NotFound/NotFound";
import ApplyButton from "components/utils/ApplyButton";
import DeleteButton from "components/utils/DeleteButton";
import LoadingBar from "components/utils/LoadingBar/LoadingBar";
import Title from "components/utils/Title/Title";
import UtilsTable from "components/utils/UtilsTable/UtilsTable";
import i18n from "data/i18n";
import { Field, Form, Formik } from "formik";
import * as fmi from "formik-material-ui";
import { DateTimePicker } from "formik-material-ui-pickers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import createApi from "services/api";
import { USERTYPES } from "services/constants";
import {
  countDuplicatesByProperty, formatFixedDate, LocalizedUtils
} from "services/helpers";
import history from "services/history";
import { appDispatch, store } from "services/store";
import { URL_LINK, URL_MANAGER } from "services/urls";
import { errorMessage, successMessage } from "store/message";
import {
  fetchPlatformComponents
} from "store/platformComponents";
import * as yup from "yup";

const api = createApi(store);

const surveyInitialValues = {
  // id,
  publishedDate: new Date(),
  titleContent: {
    contentFR: "",
    contentEN: "",
  },
  descriptionContent: {
    contentFR: "",
    contentEN: "",
  },
  componentIds: [],
  closed: false,
};

const UpdateSurveySchema = yup.object({
  publishedDate: yup.date().nullable(),
  titleContent: yup.object().shape({
    contentFR: yup.string().required("Requis").max(255, "Titre trop long"),
    contentEN: yup.string().required("Requis").max(255, "Titre trop long"),
  }),
  descriptionContent: yup.object().shape({
    contentFR: yup.string().nullable().max(255, "Description trop longue"),
    contentEN: yup.string().nullable().max(255, "Description trop longue"),
  }),
  closed: yup.boolean(),
  componentIds: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string().required(),
        code: yup.string().required(),
      })
    )
    .required()
    .min(1, "Doit être associé à au moins une plateforme"),
});

const DeleteSurveyDialog = ({
  surveyDialogOpen,
  setSurveyDialogOpen,
  deleteSurvey,
}) => (
  <Dialog
    open={surveyDialogOpen}
    className="dialog"
    onClose={() => setSurveyDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de sondage</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer ce sondage?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setSurveyDialogOpen(false)} color="primary">
        Annuler
      </Button>
      <Button onClick={deleteSurvey} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
);

const DeleteQuestionDialog = ({
  questionDialogOpen,
  setQuestionDialogOpen,
  deleteQuestion,
}) => (
  <Dialog
    open={questionDialogOpen}
    className="dialog"
    onClose={() => setQuestionDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de question</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer cette question?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setQuestionDialogOpen(false)} color="primary">
        Annuler
      </Button>
      <Button onClick={deleteQuestion} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
);

const QuestionList = ({
  questions,
  surveyId,
  setQuestionDialogOpen,
  seqNumberCounts,
}) => {
  const userType = useSelector((state) => state.user.userType);
  const isAdmin = userType === USERTYPES.ADMIN;

  return (
    <Grid item xs={12} style={{ marginBottom: "2rem" }}>
      <UtilsTable
        options={{
          pageSize: 10,
          search: true,
          searchAutoFocus: true,
          actionsColumnIndex: -1,
        }}
        columns={[
          {
            field: "questionSeqNumber",
            defaultSort: "asc",
            width: "1%",
            align: "center",
            render: (question) => (
              <span
                style={
                  seqNumberCounts[question.questionSeqNumber] > 1
                    ? { color: "red" }
                    : {}
                }
              >
                {question.questionSeqNumber}
              </span>
            ),
          },
          {
            title: "Question FR",
            field: "content.contentFR",
          },
          {
            title: "Question EN",
            field: "content.contentEN",
          },
          {
            title: "Type de réponse",
            field: "typeOfAnswers",
          },
        ]}
        actions={[
          {
            isFreeAction: true,
            tooltip: "Ajouter une question",
            icon: () => <AddComment />,
            onClick: () => {
              const addUrl = isAdmin
                ? URL_LINK.SURVEYS_QUESTION_ADD(surveyId)
                : URL_LINK.MANAGE_SURVEYS_QUESTION_ADD(surveyId);
              history.push(addUrl);
            },
          },
          (question) => ({
            icon: () => <DeleteForever color="secondary" />,
            tooltip: "Supprimer cette question",
            onClick: () => setQuestionDialogOpen(question),
          }),
        ]}
        onRowClick={(event, question) => {
          const updateUrl = isAdmin
            ? URL_LINK.SURVEYS_QUESTION_UPDATE(surveyId, question.id)
            : URL_LINK.MANAGE_SURVEYS_QUESTION_UPDATE(surveyId, question.id);
          history.push(updateUrl);
        }}
        data={questions}
      />
    </Grid>
  );
};

const UpdateSurveyForm = ({
  values,
  errors,
  dirty,
  touched,
  isSubmitting,
  isValid,
  setFieldValue,
  handleBlur,
  id,
  setSurveyDialogOpen,
  setQuestionDialogOpen,
  // setDeleteQuestion,
  platformComponents,
}) => {
  // const classes = useStyles();
  const { lang } = useSelector((state) => state.user);

  return (
    <Form autoComplete="off" style={{ width: "100%" }}>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Typography variant="h6">Détails</Typography>
        </Grid>
        <Grid item xs={12}>
          <Field
            component={fmi.TextField}
            variant="outlined"
            fullWidth
            autoFocus
            label="Titre FR"
            name="titleContent.contentFR"
            value={values.titleContent.contentFR ?? ""}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            component={fmi.TextField}
            variant="outlined"
            fullWidth
            label="Titre EN"
            name="titleContent.contentEN"
            value={values.titleContent.contentEN ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={fmi.TextField}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            label="Description FR"
            name="descriptionContent.contentFR"
            value={values.descriptionContent.contentFR ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={fmi.TextField}
            variant="outlined"
            fullWidth
            multiline
            minRows={4}
            label="Description EN"
            name="descriptionContent.contentEN"
            value={values.descriptionContent.contentEN ?? ""}
          />
        </Grid>
        <Grid item xs={6}>
          <MuiPickersUtilsProvider
            utils={LocalizedUtils}
            locale={i18n[lang.toLowerCase()].dateFormat.locale}
          >
            <Field
              name="publishedDate"
              component={DateTimePicker}
              inputVariant="outlined"
              label="Date publiée"
              fullWidth
              format={i18n.fr.dateFormat.dateTime}
              ampm={i18n.fr.dateFormat.ampm}
              cancelLabel="Annuler"
              okLabel="Confirmer"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={6}>
          <Field
            name="closed"
            type="checkbox"
            component={fmi.CheckboxWithLabel}
            Label={{
              label: "Fermé",
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            multiple
            component={Autocomplete}
            options={platformComponents}
            onChange={(_, value) => setFieldValue("componentIds", value)}
            onBlur={handleBlur}
            getOptionLabel={(componentIds) => componentIds.code}
            getOptionSelected={(option, value) => option.id === value.id}
            name="componentIds"
            value={values.componentIds}
            disabled={platformComponents.length === 1}
            renderInput={(params) => (
              <TextField
                {...params}
                name="componentIds"
                error={touched.componentIds && !!errors.componentIds}
                helperText={touched.componentIds && errors.componentIds}
                label="Plateformes"
                variant="outlined"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} className="centerCenter">
          {id && (
            <DeleteButton onClick={() => setSurveyDialogOpen(true)}>
              Supprimer le sondage
            </DeleteButton>
          )}
          <ApplyButton
            isValid={isValid}
            dirty={dirty}
            isSubmitting={isSubmitting}
            endIcon={id ? <Save /> : <AddComment />}
          >
            {!id && "Ajouter le sondage"}
          </ApplyButton>
        </Grid>
      </Grid>
    </Form>
  );
};

export const UpdateSurveyInner = ({ id = null }) => {
  const [survey, setSurvey] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [surveyDialogOpen, setSurveyDialogOpen] = useState(false);
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false);
  const [selectedQuestionToDelete, setSelectedQuestionToDelete] =
    useState(null);
  const [seqNumberCounts, setSeqNumberCounts] = useState({});

  const setQuestionDialogOpenHelper = (question) => {
    setQuestionDialogOpen(true);
    setSelectedQuestionToDelete(question);
  };

  const userType = useSelector((state) => state.user.userType);
  const isAdmin = userType === USERTYPES.ADMIN;
  const platformComponents = useSelector(
    (state) => state.platformComponents.platformComponentsList
  );

  const surveysUrl = isAdmin ? URL_LINK.SURVEYS : URL_LINK.MANAGE_SURVEYS;

  const getQuestions = async () => {
    try {
      const { data: questionsRes } = id
        ? await api.get(URL_MANAGER.SURVEY_QUESTIONS(id))
        : { data: surveyInitialValues.questions };

      const questionSeqNumberCounts = countDuplicatesByProperty(
        questionsRes,
        "questionSeqNumber"
      );

      setQuestions(questionsRes);
      setSeqNumberCounts(questionSeqNumberCounts);
    } catch (error) {
      console.error(error);
      appDispatch(errorMessage("Le sondage n'a pas pu être chargé"));
    }
  };

  const getSurvey = async () => {
    try {
      const { data: surveyRes } = id
        ? await api.get(URL_MANAGER.SURVEYS_ID(id))
        : { data: surveyInitialValues };

      const componentIds = surveyRes.componentIds.length
        ? // Updating existing announcement
          surveyRes.componentIds.map((componentId) =>
            platformComponents.find(
              (platformComponent) => platformComponent.id === componentId
            )
          )
        : platformComponents.length === 1
        ? // Add only option if user has only one option
          platformComponents
        : // Add new announcement, multiple choice
          [];

      if (id) getQuestions();

      setSurvey({ ...surveyRes, componentIds });
    } catch (error) {
      console.error(error);
      appDispatch(errorMessage("Le sondage n'a pas pu être chargé"));
    } finally {
      setLoading(false);
    }
  };

  const saveSurvey = async (values) => {
    try {
      const method = id ? "put" : "post";

      const surveyBody = {
        id,
        publishedDate: formatFixedDate(values.publishedDate),
        closed: values.closed,
        titleContent: {
          ...values.titleContent,
        },
        descriptionContent: {
          ...values.descriptionContent,
        },
        componentIds: values.componentIds.map(
          (componentIds) => componentIds.id
        ),
      };

      await api[method](
        URL_MANAGER.SURVEYS_ADD,
        surveyBody
      );

      appDispatch(
        successMessage(
          id
            ? "Les changements ont été effectués"
            : "Données créées avec succès"
        )
      );

      // Redirect to survey details
      history.push(surveysUrl);
    } catch (error) {
      console.error(error);
      appDispatch(
        errorMessage(
          id
            ? "Les changements n'ont pas pu être effectués"
            : "Les données n'ont pas pu être créées"
        )
      );
    }
  };

  const deleteSurvey = async () => {
    try {
      await api.delete(URL_MANAGER.SURVEYS_DELETE_ID(id));
      setSurveyDialogOpen(false);
      appDispatch(successMessage("Le sondage a été supprimé"));
      // Redirect to survey details
      history.push(surveysUrl);
    } catch (error) {
      console.error(error);
      appDispatch(errorMessage("Le sondage n'a pas pu être supprimé"));
    }
  };

  const deleteQuestion = async () => {
    try {
      setLoading(true);
      await api.delete(
        URL_MANAGER.QUESTIONS_DELETE_ID(selectedQuestionToDelete.id)
      );
      setQuestionDialogOpen(false);
      setSelectedQuestionToDelete(null);
      getQuestions();
      setLoading(false);
      appDispatch(successMessage("La question a été supprimée"));
    } catch (error) {
      console.error(error);
      appDispatch(errorMessage("La question n'a pas pu être supprimée"));
    }
  };

  useEffect(() => {
    appDispatch(fetchPlatformComponents(api));
    getSurvey();
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  if (!loading && !survey) {
    return <NotFound />;
  }

  if (!survey) {
    return <LoadingBar />;
  }

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={surveysUrl}>Sondages</Link>
            <Typography>
              {id ? survey.titleContent.contentFR : "Ajouter"}
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Paper style={{ padding: "2rem" }} variant="outlined">
            <Formik
              children={(formikProps) => (
                <UpdateSurveyForm
                  id={id}
                  setSurveyDialogOpen={setSurveyDialogOpen}
                  setQuestionDialogOpen={setQuestionDialogOpenHelper}
                  platformComponents={platformComponents}
                  {...formikProps}
                />
              )}
              validationSchema={UpdateSurveySchema}
              initialValues={survey}
              onSubmit={saveSurvey}
            />
            {id && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">Questions</Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: 0 }}>
                  <QuestionList
                    questions={questions}
                    seqNumberCounts={seqNumberCounts}
                    surveyId={id}
                    setQuestionDialogOpen={setQuestionDialogOpenHelper}
                  />
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <DeleteSurveyDialog
        surveyDialogOpen={surveyDialogOpen}
        setSurveyDialogOpen={setSurveyDialogOpen}
        deleteSurvey={deleteSurvey}
      />
      <DeleteQuestionDialog
        questionDialogOpen={questionDialogOpen}
        setQuestionDialogOpen={setQuestionDialogOpen}
        deleteQuestion={deleteQuestion}
      />
    </>
  );
};

const UpdateSurvey = () => {
  const { id } = useParams();

  return (
    <>
      <Title title={id ? "Modifier un sondage" : "Ajouter un sondage"} />
      <div className="toggler-section -white">
        <div className="container -data">
          <UpdateSurveyInner id={id} />
        </div>
      </div>
    </>
  );
};

export default UpdateSurvey;
