import { Chip, makeStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { AddComment, DeleteForever, Photo } from '@material-ui/icons'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import createApi from 'services/api'
import { USERTYPES } from 'services/constants'
import { displayFixedDate } from 'services/helpers'
import history from 'services/history'
import { appDispatch, store } from 'services/store'
import { URL_LINK, URL_MANAGER } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'
import { fetchPlatformComponents } from 'store/platformComponents'
import { AnnouncementsToolbar } from './AnnouncementsToolbar'

const api = createApi(store)

const useStyles = makeStyles((theme) => ({
  chip: {
    cursor: 'pointer',
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  }
}))

const DeleteAnnouncementDialog = ({ open, setOpen, deleteAnnouncement }) => (
  <Dialog
    open={open}
    className="dialog"
    onClose={() => setOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de l'annonce</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer cette annonce?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Annuler
      </Button>
      <Button onClick={deleteAnnouncement} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
)

const AnnouncementsTable = ({
  announcements,
  platformComponents,
  setPopupOpen
}) => {
  const [filterPlatforms, setFilterPlatforms] = useState('')
  const userType = useSelector((state) => state.user.userType)
  const isAdmin = userType === USERTYPES.ADMIN
  const classes = useStyles()

  return (
    <UtilsTable
      options={{
        pageSize: 10,
        search: true,
        searchAutoFocus: true,
        actionsColumnIndex: -1
      }}
      components={{
        Toolbar: (props) => (
          <AnnouncementsToolbar
            platformComponents={platformComponents}
            filterPlatforms={filterPlatforms}
            setFilterPlatforms={setFilterPlatforms}
            {...props}
          />
        )
      }}
      columns={[
        {
          title: 'Titre',
          field: 'titleContent.contentFR'
        },
        {
          title: 'Plateformes',
          render: ({ componentIds }) => {
            const componentCodes = componentIds.map(
              (componentId) =>
                platformComponents.find(
                  (platformComponent) => platformComponent.id === componentId
                ).code
            )

            return componentCodes.map((code) => (
              <Chip label={code} key={code} className={classes.chip} />
            ))
          }
        },
        {
          title: 'Images',
          sorting: false,
          render: ({ nbImages }) =>
            nbImages
              ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Photo color="secondary" style={{ display: 'flex' }} />
                <span>&nbsp;{nbImages}</span>
              </div>
                )
              : (
                  ''
                ),
          width: 0,
          cellStyle: { paddingTop: 0, paddingBottom: 0 }
        },
        {
          title: 'Date',
          defaultSort: 'desc',
          field: 'publishedDate',
          render: ({ publishedDate }) => displayFixedDate(publishedDate)
        }
      ]}
      actions={[
        {
          isFreeAction: true,
          tooltip: 'Ajouter une annonce',
          icon: () => <AddComment />,
          onClick: () => {
            const addUrl = isAdmin
              ? URL_LINK.ANNOUNCEMENTS_ADD
              : URL_LINK.MANAGE_ANNOUNCEMENTS_ADD
            history.push(addUrl)
          }
        },
        (announcement) => ({
          icon: () => <DeleteForever color="secondary" />,
          tooltip: 'Supprimer cette annonce',
          onClick: () => setPopupOpen(announcement)
        })
      ]}
      onRowClick={(event, { id }) => {
        const updateUrl = isAdmin
          ? URL_LINK.ANNOUNCEMENTS_UPDATE
          : URL_LINK.MANAGE_ANNOUNCEMENTS_UPDATE
        history.push(updateUrl + id)
      }}
      data={announcements.filter(({ componentIds }) => {
        if (!filterPlatforms) return true

        return componentIds.some(
          (componentId) => componentId === filterPlatforms
        )
      })}
    />
  )
}

export const AnnouncementsInner = () => {
  const [announcements, setAnnouncements] = useState(null)
  const [open, setOpen] = useState(false)
  const [selectedAnnouncement, setSelectedAnnouncement] = useState()
  const platformComponents = useSelector(
    (state) => state.platformComponents.platformComponentsList
  )

  const setPopupOpen = (announcement) => {
    setSelectedAnnouncement(announcement)
    setOpen(true)
  }

  const getAnnouncements = async () => {
    try {
      const { data: announcementsRes } = await api.get(
        URL_MANAGER.MANAGED_ANNOUNCEMENTS
      )

      setAnnouncements(announcementsRes)
    } catch (error) {
      console.error(error)
      appDispatch(
        errorMessage('La liste des annonces n\'a pas pu être chargée')
      )
    }
  }

  const deleteAnnouncement = async () => {
    try {
      await api.delete(
        URL_MANAGER.ANNOUNCEMENTS_DELETE_ID(selectedAnnouncement.id)
      )

      setOpen(false)
      appDispatch(successMessage("L'annonce a été supprimée"))
      setSelectedAnnouncement(null)
      getAnnouncements()
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage('L\'annonce n\'a pas pu être supprimée'))
    }
  }

  useEffect(() => {
    getAnnouncements()
    appDispatch(fetchPlatformComponents(api))
  }, [])

  if (!announcements || !platformComponents.length) return <LoadingBar />

  return (
    <>
      <AnnouncementsTable
        announcements={announcements}
        platformComponents={platformComponents}
        setPopupOpen={setPopupOpen}
      />
      <DeleteAnnouncementDialog
        open={open}
        setOpen={setOpen}
        deleteAnnouncement={deleteAnnouncement}
      />
    </>
  )
}

const Announcements = () => (
  <>
    <Title title="Annonces" />
    <div className="toggler-section -white">
      <div className="container -data">
        <AnnouncementsInner />
      </div>
    </div>
  </>
)

export default Announcements
