import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import ApplyButton from 'components/utils/ApplyButton'
import Title from 'components/utils/Title/Title'
import { Field, Form, Formik } from 'formik'
import * as fmi from 'formik-material-ui'
import { useSelector } from 'react-redux'
import createApi from 'services/api'
import { PASSWORD_REGEX } from 'services/constants'
import history from 'services/history'
import { appDispatch, store } from 'services/store'
import { URL_LINK, URL_USER } from 'services/urls'
import { successMessage } from 'store/message'
import { unlock } from 'store/user'
import * as yup from 'yup'

const api = createApi(store)

const ResetPasswordSchema = yup.object({
  token: yup
    .string()
    .required('Code de vérification requis')
    .matches(/\d{6}/, 'Doit contenir 6 chiffres'),
  password: yup
    .string()
    .required('Mot de passe requis')
    .min(6, 'Doit contenir entre 6 et 24 charactères')
    .max(24, 'Doit contenir entre 6 et 24 charactères')
    .matches(
      PASSWORD_REGEX,
      'Doit contenir au moins une majuscule, une minuscule, un chiffre et un charactère spécial parmi !@#$%^&*-'
    ),
  passwordConfirm: yup
    .string()
    .test(
      'passwords-match',
      'Les mots de passe doivent correspondre',
      function (value) {
        return this.parent.password === value
      }
    )
})

const ResetPasswordForm = ({ dirty, isValid, isSubmitting, handleChange }) => (
  <Grid container justifyContent="center">
    <Grid item xs={5}>
      <Paper style={{ padding: '2rem' }} variant="outlined">
        <Form autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              Veuillez entrer votre code de vérification et un nouveau mot de
              passe.
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                type="text"
                variant="outlined"
                fullWidth
                label="Code de vérification"
                name="token"
                inputProps={{
                  maxLength: 6,
                  autoComplete: 'one-time-code'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                type="password"
                variant="outlined"
                fullWidth
                label="Nouveau mot de passe"
                name="password"
                inputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                type="password"
                variant="outlined"
                fullWidth
                label="Confirmer mot de passe"
                name="passwordConfirm"
                inputProps={{
                  autoComplete: 'new-password'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <ApplyButton
                isValid={isValid}
                isSubmitting={isSubmitting}
                endIcon={null}
              >
                Appliquer
              </ApplyButton>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Grid>
  </Grid>
)

const ResetPasswordInner = () => {
  // const { token } = useParams();

  // From setUserName in Signup / ForgotPassword
  const userName = useSelector((state) => state.user.userName)

  const handleSubmit = async (values, { setFieldError }) => {
    try {
      await api.put(URL_USER.UPDATE_PWD_RESET, {
        userName,
        token: values.token,
        newPassword: values.password
      })
      appDispatch(unlock())
      appDispatch(successMessage('Vous pouvez maintenant vous connecter'))
      history.push(URL_LINK.LOGIN)
    } catch (error) {
      setFieldError(
        'passwordConfirm',
        "Votre mot de passe n'a pas pu être réinitialisé."
      )
    }
  }

  return (
    <Formik
      component={ResetPasswordForm}
      validationSchema={ResetPasswordSchema}
      // Must validateOnMount or Appliquer is clickable initially
      // https://github.com/formium/formik/issues/1950
      validateOnMount
      initialValues={{
        token: '',
        password: '',
        passwordConfirm: ''
      }}
      onSubmit={handleSubmit}
    />
  )
}

const ResetPassword = () => {
  const title = 'Réinitialiser son mot de passe'

  return (
    <>
      <Title title={title} />
      <div className="toggler-section -white">
        <div className="container -data">
          <ResetPasswordInner />
        </div>
      </div>
    </>
  )
}

export { ResetPasswordForm, ResetPasswordSchema }
export default ResetPassword
