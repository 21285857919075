import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import Title from 'components/utils/Title/Title'
import { createRef, useState } from 'react'
import { useParams } from 'react-router'
import { URL_EVENTS } from 'services/urls'
import {
  UsergroupMembershipChangeEventsTable
} from './EventsTables'

const mgrEventTypes = [
  {
    name: 'Usergroup User Membership Change',
    url: URL_EVENTS.USERGROUP_USERS_PUBLIC,
    render: (activeEventType, tableRef, userGroupId) => (
      <UsergroupMembershipChangeEventsTable
        activeEventType={activeEventType}
        tableRef={tableRef}
        userGroupId={userGroupId}
      />
    )
  },
  {
    name: 'Usergroup Access Manager Change',
    url: URL_EVENTS.USERGROUP_MGRS_PUBLIC,
    render: (activeEventType, tableRef, userGroupId) => (
      <UsergroupMembershipChangeEventsTable
        activeEventType={activeEventType}
        tableRef={tableRef}
        userGroupId={userGroupId}
      />
    )
  },
  {
    name: 'Usergroup Dataset Membership Change',
    url: URL_EVENTS.USERGROUP_DATASETS_PUBLIC,
    render: (activeEventType, tableRef, userGroupId) => (
      <UsergroupMembershipChangeEventsTable
        activeEventType={activeEventType}
        tableRef={tableRef}
        userGroupId={userGroupId}
      />
    )
  },
  {
    name: 'Usergroup Resource Membership Change',
    url: URL_EVENTS.USERGROUP_RESOURCES_PUBLIC,
    render: (activeEventType, tableRef, userGroupId) => (
      <UsergroupMembershipChangeEventsTable
        activeEventType={activeEventType}
        tableRef={tableRef}
        userGroupId={userGroupId}
      />
    )
  },
  {
    name: 'Usergroup Table Membership Change',
    url: URL_EVENTS.USERGROUP_TABLES_PUBLIC,
    render: (activeEventType, tableRef, userGroupId) => (
      <UsergroupMembershipChangeEventsTable
        activeEventType={activeEventType}
        tableRef={tableRef}
        userGroupId={userGroupId}
      />
    )
  }
]

const EventSelect = ({
  eventTypes,
  activeEventType,
  setActiveEventType,
  tableRef
}) => {
  return (
    <Grid item xs={12}>
      <FormControl variant="outlined" style={{ minWidth: 200 }}>
        <InputLabel disableAnimation={true} id="event-type-select">
          Type d'événement
        </InputLabel>
        <Select
          labelId="event-type-select"
          displayEmpty
          label="Type d'événement"
          variant="outlined"
          value={activeEventType}
          onChange={(event) => {
            setActiveEventType(event.target.value)
            tableRef.current?.onQueryChange({
              page: 0
            })
          }}
        >
          {eventTypes.map((eventType, idx) => (
            <MenuItem value={eventType} key={eventType.name}>
              {eventType.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

const EventsInner = ({ userGroupId }) => {
  const tableRef = createRef()
  const eventTypes = mgrEventTypes
  const [activeEventType, setActiveEventType] = useState(eventTypes[0])

  return (
    <>
      <EventSelect
        eventTypes={eventTypes}
        activeEventType={activeEventType}
        setActiveEventType={setActiveEventType}
        tableRef={tableRef}
      />
      {activeEventType.render(activeEventType, tableRef, userGroupId) }
    </>
  )
}

const Events = () => {
  const { usergroupId } = useParams()

  return (
    <>
      <Title title="Événements" />
      <div className="toggler-section -white">
        <div className="container -data">
          <EventsInner userGroupId={usergroupId} />
        </div>
      </div>
    </>
  )
}

export default Events
