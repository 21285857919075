import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import history from 'services/history'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { URL_LINK, URL_MANAGER } from 'services/urls'
import { errorMessage } from 'store/message'
import ListAltIcon from '@mui/icons-material/ListAlt'

const api = createApi(store)

const UserGroupsTable = ({ groups }) => (
  <UtilsTable
    title="Liste des groupes gérés"
    options={{
      search: true,
      paging: false,
      toolbarButtonAlignment: 'left',
      searchAutoFocus: true,
      actionsColumnIndex: -1
    }}
    columns={[
      {
        title: 'Nom du groupe',
        field: 'name',
        align: 'left',
        defaultSort: 'asc',
        width: '30%'
      },
      { title: 'Description', field: 'description', align: 'left' }
    ]}
    onRowClick={(event, usergroup) => {
      history.push(URL_LINK.MANAGE_GROUPS_UPDATE + usergroup.id)
    }}
    actions={[(group) => ({
      icon: () => <ListAltIcon color="primary" />,
      tooltip: "Rapports d'audit",
      onClick: () => { history.push(URL_LINK.MANAGE_EVENTS_USERGROUPID(group.id)) }
    })]}
    data={groups}
  />
)

export const UsergroupsInner = ({ groups, loading }) => {
  return <>{loading ? <LoadingBar /> : <UserGroupsTable groups={groups} />}</>
}

UsergroupsInner.defaultProps = {
  groups: [],
  loading: false
}

UsergroupsInner.propTypes = {
  groups: PropTypes.array.isRequired
}

const ManagedUsergroups = () => {
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)

  const getGroups = () => {
    setLoading(true)
    api
      .get(URL_MANAGER.MANAGED_GROUPS)
      .then((data) => {
        setGroups(data.data)
      })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        appDispatch(
          errorMessage("La liste des groupes n'a pas pu être chargée")
        )
      })
  }

  useEffect(getGroups, [])

  return (
    <div>
      <Title title={'Groupes gérés'} />
      <div className="toggler-section -white">
        <div className="container -data">
          <UsergroupsInner groups={groups} loading={loading} />
        </div>
      </div>
    </div>
  )
}
export default ManagedUsergroups
