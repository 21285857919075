import { SaveAlt } from '@material-ui/icons'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import createApi from 'services/api'
import { downloadExportedDataCSV } from 'services/helpers'
import { appDispatch, store } from 'services/store'
import { URL_MANAGER } from 'services/urls'
import { errorMessage } from 'store/message'
const api = createApi(store)

const UserGroupsTable = ({ groups }) => (
  <UtilsTable
    title="Liste des groupes gérés pour lesquels il y a des données exportables"
    options={{
      search: true,
      paging: false,
      toolbarButtonAlignment: 'left',
      searchAutoFocus: true,
      actionsColumnIndex: -1
    }}
    columns={[
      {
        title: 'Nom du groupe',
        field: 'name',
        align: 'left',
        defaultSort: 'asc',
        width: '30%'
      },
      { title: 'Description', field: 'description', align: 'left' }
    ]}
    actions={[
      {
        icon: () => <SaveAlt color="primary" />,
        tooltip: 'Export de données',
        onClick: () => {
          downloadData()
        }
      }
    ]}
    data={groups}
  />
)

// Temporary hack for mobiclic
// We'll implement a permanent solution later
const downloadData = () => {
  console.log('calling export data')

  try {
    downloadExportedDataCSV(api, 'public/mobiclic/exportData')
  } catch {
    appDispatch(errorMessage("Le fichier n'a pas pu être téléchargé"))
  }
}

export const UsergroupsInner = ({ groups, loading }) => {
  return <>{loading ? <LoadingBar /> : <UserGroupsTable groups={groups} />}</>
}

UsergroupsInner.defaultProps = {
  groups: [],
  loading: false
}

UsergroupsInner.propTypes = {
  groups: PropTypes.array.isRequired
}

const ManagedUsergroups = () => {
  const [groups, setGroups] = useState([])
  const [loading, setLoading] = useState(false)

  const getGroups = () => {
    setLoading(true)
    api
      .get(URL_MANAGER.MANAGED_GROUPS)
      .then((data) => {
        setGroups(data.data)
      })
      .then(() => {
        setLoading(false)
      })
      .catch(() => {
        appDispatch(
          errorMessage("La liste des groupes n'a pas pu être chargée")
        )
      })
  }

  useEffect(getGroups, [])

  return (
    <div>
      <Title title={'Groupes gérés avec des données exportables'} />
      <div className="toggler-section -white">
        <div className="container -data">
          <UsergroupsInner
            groups={groups.filter((g) =>
              g.name.toLowerCase().includes('mobiclic')
            )}
            loading={loading}
          />
        </div>
      </div>
    </div>
  )
}
export default ManagedUsergroups
