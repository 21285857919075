import { ThemeProvider } from "@material-ui/core";
import ManagedUsergroups from "components/Manager/ManagedUsergroups/ManagedUsergroups";
import MyGroups from "components/Portal/MyGroups/MyGroups";
import UpdateProfile from "components/Portal/UpdateProfile/UpdateProfile";
import Announcements from "components/Shared/Announcements/Announcements";
import UpdateAnnouncement from "components/Shared/Announcements/UpdateAnnouncement";
import Events from "components/Shared/Events/Events";
import NotFound from "components/Shared/NotFound/NotFound";
import SurveyList from "components/Shared/Surveys/SurveyList";
import UpdateQuestion from "components/Shared/Surveys/UpdateQuestion";
import UpdateSurvey from "components/Shared/Surveys/UpdateSurvey";
import {
  ErrorMessage,
  InfoMessage,
  SuccessMessage,
  WarningMessage,
} from "components/utils/Message";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { clearPlatformComponents } from "store/platformComponents";
import { logout } from "store/user";
import AddUsergroup from "./components/Admin/Usergroups/AddUsergroup";
import UpdateUsergroup from "./components/Admin/Usergroups/UpdateUsergroup";
import Usergroups from "./components/Admin/Usergroups/Usergroups";
import UpdateUser from "./components/Admin/Users/UpdateUser";
import Users from "./components/Admin/Users/Users";
import ManagerUpdateUsergroup from "./components/Manager/Usergroups/UpdateUsergroup";
import About from "./components/Portal/About/About";
import Footer from "./components/Portal/Footer/Footer";
import ForgotPassword from "./components/Portal/ForgotPassword/ForgotPassword";
import Header from "./components/Portal/Header/Header";
import Help from "./components/Portal/Help/Help";
import Index from "./components/Portal/Index/Index";
import Login from "./components/Portal/Login/Login";
import ResetPassword from "./components/Portal/ResetPassword/ResetPassword";
import Signup from "./components/Portal/Signup/Signup";
import "./css/datasets.css";
import "./css/main.css";
import { USERTYPES } from "./services/constants";
import { URL_LINK } from "./services/urls";
import theme from "./theme";

import ComponentList from "components/Admin/PlatformComponents/PlatformComponentsList";
import DataExports from "components/Manager/DataExports/DataExports";
import createApi from "services/api";
import { auditPortalEvents } from "services/helpers";
import { store } from "services/store";

const api = createApi(store);

// https://reactrouter.com/web/guides/scroll-restoration
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const PublicRoute = ({
  component: Component,
  disableScrollToTop = false,
  ...rest
}) => {
  if (!disableScrollToTop) {
    ScrollToTop();
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

const PrivateRoute = ({
  component: Component,
  disableScrollToTop = false,
  ...rest
}) => {
  const isLoggedIn = useSelector((state) => state.user.loggedIn);

  if (!disableScrollToTop) {
    ScrollToTop();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? <Component {...props} /> : <Redirect to={URL_LINK.LOGIN} />
      }
    />
  );
};

const ExclusivelyPublicRoute = ({
  component: Component,
  disableScrollToTop = false,
  ...rest
}) => {
  const isLoggedOut = !useSelector((state) => state.user.loggedIn);

  if (!disableScrollToTop) {
    ScrollToTop();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedOut ? (
          <Component {...props} />
        ) : (
          <Redirect to={URL_LINK.INDEX} />
        )
      }
    />
  );
};

const HelperRoute = ({
  component: Component,
  disableScrollToTop = false,
  userType,
  ...rest
}) => {
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const isUserType = useSelector(
    (state) => isLoggedIn && state.user.userType === userType
  );

  if (!disableScrollToTop) {
    ScrollToTop();
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isUserType ? <Component {...props} /> : <Redirect to={URL_LINK.LOGIN} />
      }
    />
  );
};

const AdminRoute = (props) => (
  <HelperRoute userType={USERTYPES.ADMIN} {...props} />
);

const ManagerRoute = (props) => (
  <HelperRoute userType={USERTYPES.MANAGER} {...props} />
);

function App() {
  const dispatch = useDispatch();

  const userType = useSelector((state) => state.user.userType);
  const loggedIn = useSelector((state) => state.user.loggedIn);
  const canManageOrgs = useSelector((state) => state.user.canManageOrgs);
  const location = useLocation();

  const trackedLocationsExact = [URL_LINK.ABOUT, URL_LINK.HELP, URL_LINK.DATA];
  const trackedLocationsPrefix = [URL_LINK.DATA, URL_LINK.DATA_RES];

  const auditLocationChanges = async (location) => {
    if (
      trackedLocationsExact.includes(location) ||
      trackedLocationsPrefix.find((prefix) => location.startsWith(prefix)) !==
        undefined
    ) {
      try {
        await auditPortalEvents(api, {});
      } catch (error) {}
    }
  };

  useEffect(() => {
    auditLocationChanges(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <Header
          loggedIn={loggedIn}
          userType={userType}
          canManageOrgs={canManageOrgs}
          logout={() => {
            dispatch(logout());
            dispatch(clearPlatformComponents());
          }}
        />
        <div className="site-content">
          <div className="page-home page-push">
            <Switch>
              {/* Public routes */}
              <ExclusivelyPublicRoute
                exact
                path={URL_LINK.LOGIN}
                component={Login}
              />
              <ExclusivelyPublicRoute
                exact
                path={URL_LINK.SIGNUP}
                component={Signup}
              />
              <PublicRoute
                exact
                path={["/", URL_LINK.INDEX]}
                component={Index}
              />
              <ExclusivelyPublicRoute
                exact
                path={URL_LINK.FORGOT_PASSWORD}
                component={ForgotPassword}
              />
              <ExclusivelyPublicRoute
                exact
                path={URL_LINK.RESET_PASSWORD + ":token?"}
                component={ResetPassword}
              />
              <PublicRoute path={URL_LINK.ABOUT} component={About} />
              <PublicRoute path={URL_LINK.HELP} component={Help} />
              {/* Private routes */}
              <PrivateRoute
                path={URL_LINK.PROFILE}
                exact
                component={UpdateProfile}
              />
              <PrivateRoute
                path={URL_LINK.MY_GROUPS}
                exact
                component={MyGroups}
              />

              {/* Access Manager routes */}
              <ManagerRoute
                path={URL_LINK.MANAGED_GROUPS}
                exact
                component={ManagedUsergroups}
              />

              <ManagerRoute
                path={URL_LINK.MANAGE_GROUPS_UPDATE + ":id"}
                exact
                component={ManagerUpdateUsergroup}
              />

              <ManagerRoute
                path={URL_LINK.MANAGE_EVENTS_USERGROUPID(":usergroupId")}
                exact
                component={Events}
              />

              <ManagerRoute
                path={URL_LINK.MANAGE_EXPORTS}
                exact
                component={DataExports}
              />

              <ManagerRoute
                path={URL_LINK.MANAGE_ANNOUNCEMENTS}
                exact
                component={Announcements}
              />

              <ManagerRoute
                path={URL_LINK.MANAGE_ANNOUNCEMENTS_ADD}
                exact
                component={UpdateAnnouncement}
              />

              <ManagerRoute
                path={URL_LINK.MANAGE_ANNOUNCEMENTS_UPDATE + ":id"}
                exact
                component={UpdateAnnouncement}
              />

              <ManagerRoute
                path={URL_LINK.MANAGE_SURVEYS}
                exact
                component={SurveyList}
              />
              <ManagerRoute
                path={URL_LINK.MANAGE_SURVEYS_ADD}
                exact
                component={UpdateSurvey}
              />
              <ManagerRoute
                path={URL_LINK.MANAGE_SURVEYS_UPDATE + ":id"}
                exact
                component={UpdateSurvey}
              />
              <ManagerRoute
                path={URL_LINK.MANAGE_SURVEYS_QUESTION_ADD(":surveyId")}
                exact
                component={UpdateQuestion}
              />
              <ManagerRoute
                path={URL_LINK.MANAGE_SURVEYS_QUESTION_UPDATE(
                  ":surveyId",
                  ":questionId"
                )}
                exact
                component={UpdateQuestion}
              />

              {/* Admin routes */}
              <AdminRoute path={URL_LINK.USERS} exact component={Users} />
              <AdminRoute
                path={URL_LINK.USERS_UPDATE + ":id"}
                exact
                component={UpdateUser}
              />
              <AdminRoute
                path={URL_LINK.USERGROUPS}
                exact
                component={Usergroups}
              />
              <AdminRoute
                path={URL_LINK.USERGROUPS_ADD}
                exact
                component={AddUsergroup}
              />
              <AdminRoute
                path={URL_LINK.USERGROUPS_UPDATE + ":id"}
                exact
                component={UpdateUsergroup}
              />
              <AdminRoute
                path={URL_LINK.ANNOUNCEMENTS}
                exact
                component={Announcements}
              />
              <AdminRoute
                path={URL_LINK.ANNOUNCEMENTS_ADD}
                exact
                component={UpdateAnnouncement}
              />
              <AdminRoute
                path={URL_LINK.ANNOUNCEMENTS_UPDATE + ":id"}
                exact
                component={UpdateAnnouncement}
              />
              <AdminRoute
                path={URL_LINK.SURVEYS}
                exact
                component={SurveyList}
              />
              <AdminRoute
                path={URL_LINK.SURVEYS_ADD}
                exact
                component={UpdateSurvey}
              />
              <AdminRoute
                path={URL_LINK.SURVEYS_UPDATE + ":id"}
                exact
                component={UpdateSurvey}
              />
              <AdminRoute
                path={URL_LINK.SURVEYS_QUESTION_ADD(":surveyId")}
                exact
                component={UpdateQuestion}
              />
              <AdminRoute
                path={URL_LINK.SURVEYS_QUESTION_UPDATE(
                  ":surveyId",
                  ":questionId"
                )}
                exact
                component={UpdateQuestion}
              />

              <AdminRoute
                path={URL_LINK.PLATFORM_COMPONENTS}
                exact
                component={ComponentList}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
        <Footer />
        <SuccessMessage />
        <ErrorMessage />
        <WarningMessage />
        <InfoMessage />
      </>
    </ThemeProvider>
  );
}

export default App;
