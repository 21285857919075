import React, { useState } from 'react'
import ApplyButton from 'components/utils/ApplyButton'
import Title from 'components/utils/Title/Title'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { Formik, Form, Field } from 'formik'
import * as yup from 'yup'
import * as fmi from 'formik-material-ui'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { URL_LINK, URL_USER } from 'services/urls'
import history from 'services/history'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { setUserName } from 'store/user'

const api = createApi(store)

const ForgotPasswordSchema = yup.object({
  username: yup.string().email('Courriel invalide').required('Courriel requis')
})

export const ForgotPasswordDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      className="dialog"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Mot de passe oublié</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Un courriel de confirmation vous a été envoyé avec un code de
          vérification. Veuillez continuer pour réinitialiser votre mot de passe
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Continuer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ForgotPasswordForm = ({
  status = {},
  isValid,
  dirty,
  handleChange,
  isSubmitting
}) => (
  <Grid container justifyContent="center">
    <Grid item xs={5}>
      <Paper style={{ padding: '2rem' }} variant="outlined">
        <Form autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              Entrez votre courriel pour réinitialiser votre mot de passe.
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                label="Courriel"
                name="username"
                inputProps={{
                  autoComplete: 'username'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              {status.success || (
                <ApplyButton
                  dirty={dirty}
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  endIcon={null}
                >
                  Envoyer
                </ApplyButton>
              )}
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Grid>
  </Grid>
)

const ForgotPasswordInner = () => {
  // const dispatch = useDispatch();
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
    history.push(URL_LINK.RESET_PASSWORD)
  }

  const handleSubmit = async (values, { setStatus, setFieldError }) => {
    try {
      const { username } = values
      await api.post(URL_USER.FORGOT_PASSWORD, null, {
        params: {
          userName: username
        }
      })

      appDispatch(setUserName(username))
      setOpen(true)
    } catch (error) {
      setFieldError(
        'username',
        "Le courriel saisi est erroné ou n'existe pas."
      )
    }
  }

  return (
    <>
      <Formik
        component={ForgotPasswordForm}
        validationSchema={ForgotPasswordSchema}
        initialValues={{
          username: ''
        }}
        onSubmit={handleSubmit}
      />
      <ForgotPasswordDialog open={open} handleClose={handleClose} />
    </>
  )
}

const ForgotPassword = () => (
  <>
    <Title title="Mot de passe oublié" />
    <div className="toggler-section -white">
      <div className="container -data">
        <ForgotPasswordInner />
      </div>
    </div>
  </>
)

export { ForgotPasswordForm, ForgotPasswordSchema }
export default ForgotPassword
