import DateFnsUtils from '@date-io/date-fns'
import i18n from 'data/i18n'
import { utcToZonedTime } from 'date-fns-tz'
import fileDownload from 'js-file-download'
import { LOCALES } from 'services/constants'
import { URL_USER } from './urls'

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const fromUtc = (date) => {
  return utcToZonedTime(date, timeZone, { timeZone: 'UTC' })
}

export const fromLocalTime = (date) => {
  if (date) return utcToZonedTime(date, 'UTC', { timeZone })
  else return null
}

const getDatePart = (parts, type) => {
  const p = parts.filter((p) => p.type === type)
  return p && p.length > 0 ? p[0].value : null
}

export const displayFixedDate = (dateString) => {
  return dateString.replace('T', ' ')
}

export function formatDateToParts (date, options) {
  return new Intl.DateTimeFormat(LOCALES.FR, options).formatToParts(
    fromUtc(date)
  )
}

export const formatFixedDate = (date) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  }
  const dateParts = formatDateToParts(date, options)

  const year = getDatePart(dateParts, 'year')
  const month = getDatePart(dateParts, 'month')
  const day = getDatePart(dateParts, 'day')
  const hour = getDatePart(dateParts, 'hour')
  const minute = getDatePart(dateParts, 'minute')

  // const second = getDatePart(dateParts, "second");
  const second = '00'

  const fixedDate =
    year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second

  return fixedDate
}

export const formatISODate = (date) => {
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
  const dateParts = formatDateToParts(date, options)

  const year = getDatePart(dateParts, 'year')
  const month = getDatePart(dateParts, 'month')
  const day = getDatePart(dateParts, 'day')

  const fixedDate = year + '-' + month + '-' + day

  return fixedDate
}

export function formatAction (action) {
  if (action === 'ADD') {
    return 'Ajout'
  } else if (action === 'REMOVE') {
    return 'Retrait'
  }
}

export function formatDate (
  date = new Date(),
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }
) {
  return new Intl.DateTimeFormat(LOCALES.FR, options).format(fromUtc(date))
}

export function getCountryName (code) {
  const intlRegion = new Intl.DisplayNames([LOCALES.FR], { type: 'region' })

  return intlRegion.of(code)
}

// For use with MuiPickersUtilsProvider and DatePicker
export class LocalizedUtils extends DateFnsUtils {
  getDatePickerHeaderText (date) {
    return formatDate(date, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  }

  getDateTimePickerHeaderText (date) {
    return formatDate(date, {
      month: 'short',
      day: 'numeric'
    })
  }
}

export const sortByProp = (prop, { reverse = false } = {}) => (a, b) => {
  if (reverse) [a, b] = [b, a]
  return a[prop].localeCompare(b[prop])
}

export const downloadExportedDataCSV = async (api, url) => {
  return api.post(url, {}, { responseType: 'blob' }).then((response) => {
    const headerLine = response.headers['content-disposition']
    const startFileNameIndex = headerLine.indexOf('filename=') + 9
    const filename = headerLine.substring(startFileNameIndex)
    fileDownload(response.data, filename)
  })
}

export const downloadCSV = async (api, url, body, errorHandling = null) => {
  return api
    .post(
      url,
      {
        page: 1,
        recordsPerPage: 1000000,
        partialDownloadPossible: true,
        ...body
      },
      { responseType: 'blob' }
    )
    .then((response) => {
      const headerLine = response.headers['content-disposition']
      const startFileNameIndex = headerLine.indexOf('filename=') + 9
      const filename = headerLine.substring(startFileNameIndex)
      fileDownload(response.data, filename)
    }).catch((error) => {
      if (errorHandling != null) {
        errorHandling(error)
      }
      console.log(error)
    })
}

export const downloadCSVGet = async (api, url, body) => {
  return api
    .get(
      url,
      {
        page: 1,
        recordsPerPage: 1000000,
        ...body
      },
      { responseType: 'blob' }
    )
    .then((response) => {
      const headerLine = response.headers['content-disposition']
      const startFileNameIndex = headerLine.indexOf('filename=') + 9
      const filename = headerLine.substring(startFileNameIndex)
      fileDownload(response.data, filename)
    })
}

export const flattenObject = (obj) => {
  const flattened = {}

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      const flattenedRec = flattenObject(obj[key])
      Object.keys(flattenedRec).forEach((innerKey) => {
        flattened[`${key}_${innerKey}`] = flattenedRec[innerKey]
      })
      // Object.assign(flattened, flattenObject(obj[key]))
    } else {
      flattened[key] = obj[key]
    }
  })

  return flattened
}

export const matchingSets = (a, b) =>
  a.size === b.size && [...a].every((value) => b.has(value))

// Helper function to add or remove items from a Set via a react hook
export const toggleItemsInSet = (reactStateSetter) => (
  items,
  toggle = true
) => {
  if (items === undefined) return
  const itemsArray = Array.isArray(items) ? items : [items]

  reactStateSetter(
    (prev = []) =>
      new Set(
        toggle
          ? [...prev, ...itemsArray]
          : [...prev].filter((d) => !itemsArray.includes(d))
      )
  )
}

export const countDuplicatesByProperty = (array, property) => {
  const counts = {}
  array.forEach((x) => {
    counts[x[property]] = (counts[x[property]] || 0) + 1
  })
  return counts
}

// ex:
// const myArray = [{ id: 123, name: "abc" }]
// arrayToMaterialTableLookup(myArray, "id", "name")
// returns { 123: "abc" }
export const arrayToMaterialTableLookup = (array, getKey, getValue) => {
  return array.reduce((obj, item) => {
    return Object.assign(obj, {
      [getKey(item)]: getValue(item)
    })
  }, {})
}

export const getEventTableOptions = (api, appDispatch, errorMessage, activeEventType, params = {}, props = {}) => ({
  toolbar: true,
  paging: true,
  pageSize: 10,
  pageSizeOptions: [10, 25, 50],
  exportMenu: [
    {
      label: i18n.fr.materialTable.toolbar.exportCSVName,
      exportFunc: () => {
        try {
          downloadCSVGet(api, activeEventType.url, { params: { ...params, download: true } })
        } catch {
          appDispatch(
            errorMessage("La liste des événements n'a pas pu être téléchargée")
          )
        }
      }
    }
  ],
  ...props
})

export const eventsDataFetch = (activeEventType, api, appDispatch, errorMessage, params = {}) => async (query) => {
  try {
    const { data } = await api.get(activeEventType.url, {
      params: {
        ...params,
        page: query.page + 1,
        recordsPerPage: query.pageSize
      }
    })
    return {
      data: data.content,
      page: data.pageable.pageNumber,
      totalCount: data.totalElements
    }
  } catch {
    appDispatch(
      errorMessage("La liste des événements n'a pas pu être chargée")
    )
  }
}

export const auditPortalEvents = (api, params) => {
  return api.post(URL_USER.AUDIT_PORTAL_EVENTS, {}, { params: { ...params, url: window.location.href } })
}
