import {
  Breadcrumbs,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  TextField,
  Typography
} from '@material-ui/core'
import NotFound from 'components/Shared/NotFound/NotFound'
import Title from 'components/utils/Title/Title'
import { Field, FieldArray, Form, Formik } from 'formik'
import * as fmi from 'formik-material-ui'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { AddComment, AddPhotoAlternate, Clear, Save } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ApplyButton from 'components/utils/ApplyButton'
import DeleteButton from 'components/utils/DeleteButton'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Compressor from 'compressorjs'
import i18n from 'data/i18n'
import { DateTimePicker } from 'formik-material-ui-pickers'
import { useSelector } from 'react-redux'
import createApi from 'services/api'
import { USERTYPES } from 'services/constants'
import { formatFixedDate, LocalizedUtils } from 'services/helpers'
import history from 'services/history'
import { appDispatch, store } from 'services/store'
import { URL_LINK, URL_MANAGER } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'
import { fetchPlatformComponents } from 'store/platformComponents'

const api = createApi(store)

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
    maxHeight: 300,
    '&:hover $imageDelete': {
      display: 'inline-flex'
    }
  },
  imageDelete: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.primary.main
  },
  imageInfo: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    color: 'white',
    background: '#0000007f',
    margin: theme.spacing(3)
  },
  image: {
    maxHeight: '100%',
    maxWidth: '100%'
  }
}))

const announcementInitialValues = {
  componentIds: [],
  descriptionContent: {
    contentFR: '',
    contentEN: ''
  },
  nbImages: 0,
  publishedDate: Date.now(),
  titleContent: {
    contentFR: '',
    contentEN: ''
  }
}

const UpdateAnnouncementSchema = yup.object({
  componentIds: yup.array().of(yup.object()).min(1, 'Requis'),
  descriptionContent: yup.object({
    contentFR: yup
      .string()
      .required('Requis')
      .max(512, 'Description trop longue'),
    contentEN: yup
      .string()
      .required('Requis')
      .max(512, 'Description trop longue')
  }),
  // nbImages: 0,
  publishedDate: yup.string(),
  titleContent: yup.object({
    contentFR: yup.string().required('Requis'),
    contentEN: yup.string().required('Requis')
  })
})

const DeleteAnnouncementDialog = ({ open, setOpen, deleteAnnouncement }) => (
  <Dialog
    open={open}
    className="dialog"
    onClose={() => setOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de l'annonce</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer cette annonce?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpen(false)} color="primary">
        Annuler
      </Button>
      <Button onClick={deleteAnnouncement} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
)

const UpdateAnnouncementForm = ({
  values,
  errors,
  dirty,
  touched,
  isSubmitting,
  isValid,
  setFieldValue,
  handleBlur,
  setTouched,
  id,
  platformComponents,
  setOpen
}) => {
  const classes = useStyles()
  const { lang } = useSelector((state) => state.user)

  return (
    <Grid item xs={12}>
      <Paper style={{ padding: '2rem' }} variant="outlined">
        <Form autoComplete="off">
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography variant="h6">Détails</Typography>
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                label="Titre FR"
                name="titleContent.contentFR"
                value={values.titleContent.contentFR ?? ''}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                label="Titre EN"
                name="titleContent.contentEN"
                value={values.titleContent.contentEN ?? ''}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                multiline
                minRows={4}
                label="Description FR"
                name="descriptionContent.contentFR"
                value={values.descriptionContent.contentFR ?? ''}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                multiline
                minRows={4}
                label="Description EN"
                name="descriptionContent.contentEN"
                value={values.descriptionContent.contentEN ?? ''}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                multiple
                component={Autocomplete}
                options={platformComponents}
                onChange={(_, value) => setFieldValue('componentIds', value)}
                onBlur={handleBlur}
                getOptionLabel={(componentIds) => componentIds.code}
                getOptionSelected={(option, value) => option.id === value.id}
                name="componentIds"
                value={values.componentIds}
                disabled={platformComponents.length === 1}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="componentIds"
                    error={touched.componentIds && !!errors.componentIds}
                    helperText={
                      touched.componentIds && errors.componentIds
                    }
                    label="Plateformes"
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider
                utils={LocalizedUtils}
                locale={i18n[lang.toLowerCase()].dateFormat.locale}
              >
                <Field
                  name="publishedDate"
                  component={DateTimePicker}
                  inputVariant="outlined"
                  label="Date publiée"
                  fullWidth
                  format={i18n.fr.dateFormat.dateTime}
                  ampm={i18n.fr.dateFormat.ampm}
                  cancelLabel="Annuler"
                  okLabel="Confirmer"
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Images</Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: 0 }}>
              <Grid container spacing={4}>
                <FieldArray
                  name="images"
                  render={(arrayHelpers) => (
                    <>
                      {values.images.map(
                        (image, index) =>
                          !image.removed && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={4}
                              key={index}
                              className={classes.imageContainer}
                            >
                              <img
                                alt=""
                                src={image.preview}
                                className={classes.image}
                              />
                              <IconButton
                                className={classes.imageDelete}
                                onClick={() => {
                                  setTouched({ images: true })
                                  if (image.existing) {
                                    // If the image exists on the server, mark it for removal
                                    arrayHelpers.replace(index, {
                                      ...image,
                                      removed: true
                                    })
                                  } else {
                                    // If it was added then removed, just remove it
                                    arrayHelpers.remove(index)
                                  }
                                }}
                              >
                                <Clear />
                              </IconButton>
                              <Chip
                                size="small"
                                className={classes.imageInfo}
                                label={`${image?.type?.replace(
                                  'image/',
                                  ''
                                )} - ${Math.round(image.size / 1000)} ko`}
                              ></Chip>
                            </Grid>
                          )
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        className={classes.imageContainer}
                      >
                        <Button
                          variant="contained"
                          component="label"
                          endIcon={<AddPhotoAlternate />}
                        >
                          Ajouter une image
                          <input
                            name="image"
                            accept="image/*"
                            type="file"
                            hidden
                            onChange={(e) => {
                              const file = e.target.files[0]

                              new Compressor(file, {
                                quality: 0.8,
                                maxWidth: 1920,
                                strict: false, // Don't upscale images smaller than maxWidth
                                success (result) {
                                  const fileReader = new FileReader()

                                  fileReader.onload = () => {
                                    if (fileReader.readyState === 2) {
                                      setTouched({ images: true })
                                      arrayHelpers.push({
                                        file: result,
                                        preview: fileReader.result,
                                        type: result.type,
                                        size: result.size
                                      })
                                    }
                                  }
                                  fileReader.readAsDataURL(result)
                                },
                                error (err) {
                                  appDispatch(
                                    errorMessage(
                                      "Le format d'image n'est pas accepté"
                                    )
                                  )
                                  console.log(err.message)
                                }
                              })
                            }}
                          />
                        </Button>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} className="centerCenter">
              {id && (
                <DeleteButton onClick={() => setOpen(true)}>
                  Supprimer l'annonce
                </DeleteButton>
              )}
              <ApplyButton
                isValid={isValid}
                dirty={dirty}
                isSubmitting={isSubmitting}
                endIcon={id ? <Save /> : <AddComment />}
              >
                {!id && 'Enregistrer'}
              </ApplyButton>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Grid>
  )
}

export const UpdateAnnouncementInner = ({ id = null }) => {
  const [announcement, setAnnouncement] = useState(null)
  const platformComponents = useSelector(
    (state) => state.platformComponents.platformComponentsList
  )

  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)

  const userType = useSelector((state) => state.user.userType)
  const isAdmin = userType === USERTYPES.ADMIN

  const announcementsUrl = isAdmin
    ? URL_LINK.ANNOUNCEMENTS
    : URL_LINK.MANAGE_ANNOUNCEMENTS

  const getAnnouncement = async () => {
    try {
      const { data: announcementRes } = id
        ? await api.get(URL_MANAGER.MANAGED_ANNOUNCEMENTS_ID(id))
        : { data: announcementInitialValues }

      const imagesRes = id
        ? await Promise.all(
          [...Array(announcementRes.nbImages).keys()].map((n) =>
            api.get(URL_MANAGER.ANNOUNCEMENT_IMAGE(id, n + 1), {
              responseType: 'arraybuffer'
            })
          )
        )
        : []

      const images = imagesRes.map((imageRes) => {
        const type = imageRes.headers['content-type']
        // Convert image to base64 for previewing
        const image = Buffer.from(imageRes.data).toString('base64')
        // Convert image to file for reuploading
        const file = new Blob([imageRes.data], { type })

        return {
          file,
          preview: `data:${type.toLowerCase()};base64,${image}`,
          type: type.toLowerCase(),
          size: file.size,
          existing: true
        }
      })

      const componentIds = announcementRes.componentIds.length
        ? // Updating existing announcement
        announcementRes.componentIds.map((componentId) =>
          platformComponents.find(
            (platformComponent) => platformComponent.id === componentId
          )
        )
        : platformComponents.length === 1
          ? // Add only option if user has only one option
          platformComponents
          : // Add new announcement, multiple choice
            []

      const publishedDate = announcementRes.publishedDate

      const body = {
        ...announcementRes,
        componentIds,
        publishedDate,
        images
      }

      setAnnouncement(body)
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage("L'annonce n'a pas pu être chargée"))
    } finally {
      setLoading(false)
    }
  }

  const saveAnnouncement = async (values) => {
    try {
      const method = id ? 'put' : 'post'

      const body = {
        id,
        titleContent: {
          ...values.titleContent
        },
        descriptionContent: {
          ...values.descriptionContent
        },
        componentIds: values.componentIds.map((componentId) => componentId.id),
        publishedDate: formatFixedDate(values.publishedDate),
        nbImages: values.images.length
      }

      // Save announcement details
      const { data } = await api[method](URL_MANAGER.ANNOUNCEMENTS_ADD, body)

      const removedImages = values.images.filter((image) => image.removed)
      const addedImages = values.images.filter((image) => !image.existing)
      // All non-removed images, including existing ones
      const allImages = values.images.filter((image) => !image.removed)

      const removeImage = async (image, removedImagesIndex) => {
        // Removing image by index:
        // Get its index in values.images and subtract from it the number of images
        // already removed sequentially (removedImagesIndex)
        const imageIndex = values.images.indexOf(image)
        const imageRemovalIndex = imageIndex - removedImagesIndex

        return await api.delete(
          URL_MANAGER.ANNOUNCEMENT_IMAGE(data.id, imageRemovalIndex + 1)
        )
      }

      const addImage = async (image) => {
        // Adding image by index:
        // Get its index in all non-removed images after removing deleted images
        const imageAddIndex = allImages.indexOf(image)
        const { file } = image
        const formData = new FormData()
        formData.append('multipartImage', file)

        return await api.post(
          URL_MANAGER.ANNOUNCEMENT_IMAGE(data.id, imageAddIndex + 1),
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
      }

      await removedImages.reduce(
        (promise, image, i) =>
          promise.then(async () => await removeImage(image, i)),
        Promise.resolve()
      )

      await addedImages.reduce(
        (promise, image) => promise.then(async () => await addImage(image)),
        Promise.resolve()
      )

      appDispatch(
        successMessage(
          id ? 'Les changements ont été effectués' : 'Annonce créée avec succès'
        )
      )

      // Redirect to announcements
      history.push(announcementsUrl)
    } catch (error) {
      console.error(error)
      appDispatch(
        errorMessage(
          id
            ? "Les changements n'ont pas pu être effectués"
            : "L'annonce n'a pas pu être créée"
        )
      )
    }
  }

  const deleteAnnouncement = async () => {
    try {
      await api.delete(URL_MANAGER.ANNOUNCEMENTS_DELETE_ID(id))
      setOpen(false)

      appDispatch(successMessage("L'annonce a été supprimée"))
      history.push(announcementsUrl)
    } catch (error) {
      console.error(error)
      appDispatch(errorMessage('L\'annonce n\'a pas pu être supprimée'))
    }
  }

  useEffect(() => {
    if (platformComponents.length) {
      getAnnouncement()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [platformComponents])

  useEffect(() => {
    appDispatch(fetchPlatformComponents(api))
  }, [])

  if (!loading && !announcement) {
    return <NotFound />
  }

  if (!announcement || !platformComponents) {
    return <LoadingBar />
  }

  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link to={announcementsUrl}>Annonces</Link>
          <Typography>
            {id ? announcement.titleContent.contentFR : 'Ajouter'}
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Formik
        children={(formikProps) => (
          <UpdateAnnouncementForm
            id={id}
            platformComponents={platformComponents}
            setOpen={setOpen}
            {...formikProps}
          />
        )}
        validationSchema={UpdateAnnouncementSchema}
        initialValues={announcement}
        onSubmit={saveAnnouncement}
      />
      <DeleteAnnouncementDialog
        open={open}
        setOpen={setOpen}
        deleteAnnouncement={deleteAnnouncement}
      />
    </Grid>
  )
}

const UpdateAnnouncement = () => {
  const { id } = useParams()

  return (
    <>
      <Title title={id ? 'Modifier une annonce' : 'Ajouter une annonce'} />
      <div className="toggler-section -white">
        <div className="container -data">
          <UpdateAnnouncementInner id={id} />
        </div>
      </div>
    </>
  )
}

export default UpdateAnnouncement
