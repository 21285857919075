import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { USERTYPES } from 'services/constants'
import { URL_MANAGER } from 'services/urls'

// https://redux-toolkit.js.org/usage/usage-guide#async-requests-with-createasyncthunk

export const fetchPlatformComponents = createAsyncThunk(
  'platformComponents/fetchPlatformComponents',
  async (api) => {
    const { data } = await api.get(URL_MANAGER.MANAGED_PLATFORM_COMPONENTS)
    return data
  },
  // Only fetch if not cached
  {
    condition: (_, { getState }) => {
      const {
        platformComponents,
        user: { userType }
      } = getState()

      const isAdmin = userType === USERTYPES.ADMIN
      const isManager = userType === USERTYPES.MANAGER

      return (
        (isAdmin || isManager) &&
        platformComponents.platformComponentsList.length === 0
      )
    }
  }
)

const initialState = {
  platformComponentsList: []
}

const platformComponentsSlice = createSlice({
  name: 'platformComponents',
  initialState,
  reducers: {
    clearPlatformComponents: () => {
      return initialState
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPlatformComponents.fulfilled, (state, { payload }) => {
      state.platformComponentsList = payload
    })
  }
})

export const { clearPlatformComponents } = platformComponentsSlice.actions
export default platformComponentsSlice.reducer
