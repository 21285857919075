import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import { MTableToolbar } from '@material-table/core'
import { ImportantDevices } from '@material-ui/icons'

export const AnnouncementsToolbar = ({
  platformComponents,
  filterPlatforms,
  setFilterPlatforms,
  ...props
}) => {
  return (
    <Grid container item xs={12}>
      <FormControl variant="outlined" style={{ minWidth: 200 }}>
        <InputLabel disableAnimation={true} id="platform-select">
          Plateforme
        </InputLabel>
        <Select
          labelId="platform-select"
          displayEmpty
          label="Plateforme"
          variant="outlined"
          value={filterPlatforms}
          onChange={(event) => {
            const filterType = event.target.value
            setFilterPlatforms(filterType)
          }}
          startAdornment={
            <InputAdornment position="start">
              <ImportantDevices />
            </InputAdornment>
          }
        >
          <MenuItem value="" key="">
            <em>Toutes</em>
          </MenuItem>
          {platformComponents.map(({ id, code }) => (
            <MenuItem value={id} key={code}>
              {code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Search bar */}
      <div style={{ marginLeft: 'auto' }}>
        <MTableToolbar {...props} />
      </div>
    </Grid>
  )
}
