import MaterialTable from '@material-table/core'
import { ThemeProvider } from '@material-ui/core/styles'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteForever,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn
} from '@material-ui/icons'
import i18n from 'data/i18n'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import globalTheme from 'theme'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => (
    <DeleteForever color="secondary" {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => (
    <Edit color="secondary" {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}

// HACK remove shadow from table.
// elevation2 = table container only.

// Overriding components.Container below causes
// constant rerender on tables(UsergroundDatasetAccess)

// TODO replace with something stable.
const MuiPaperStyle = {
  elevation2: {
    width: '100%',
    boxShadow: 'none !important'
  }
}

const defaultTheme = {
  overrides: {
    MuiPaper: MuiPaperStyle,
    MuiToolbar: {
      root: {
        paddingRight: '0 !important',
        paddingLeft: '0 !important',
        paddingBottom: 15
      }
    },
    MuiTableCell: {
      // More spacing in cells
      root: {
        padding: '16px 32px',
        '&:first-child': {
          paddingLeft: '16px'
        },
        '&:last-child': {
          paddingRight: '16px'
        }
      }
    }
  }
}

const compactTheme = {
  overrides: {
    MuiPaper: MuiPaperStyle,
    MuiTableCell: {
      // Small text and header
      root: {
        fontSize: globalTheme.typography.body2.fontSize + ' !important'
      },
      head: {
        paddingTop: '8px',
        paddingBottom: '8px'
      }
    },
    MuiTableRow: {
      hover: {
        '&:hover': {
          backgroundColor: 'inherit !important'
        }
      }
    }
  }
}

const UtilsTable = React.forwardRef((props, ref) => {
  const { compact } = props
  const theme = compact ? compactTheme : defaultTheme

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        tableRef={ref}
        icons={tableIcons}
        {...props}
        // components={{
        //   Container: (props) => (
        //     <Paper
        //       {...props}
        //       style={{ width: "100%", ...props.style }}
        //       elevation={0}
        //     />
        //   ),
        //   ...props.components,
        // }}
        localization={{ ...i18n.fr.materialTable, ...props.localization }}
        options={{
          draggable: false,
          headerStyle: {
            backgroundColor: '#dedede',
            position: 'sticky'
          },
          searchFieldVariant: 'outlined',
          emptyRowsWhenPaging: false,
          showTitle: false,
          search: !compact,
          paging: !compact,
          toolbar: !compact,
          sorting: !compact,
          ...props.options
        }}
      />
    </ThemeProvider>
  )
})

UtilsTable.propTypes = {
  compact: PropTypes.bool
}

export default UtilsTable
