import {
  Breadcrumbs,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  MenuItem,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core'
import NotFound from 'components/Shared/NotFound/NotFound'
import Title from 'components/utils/Title/Title'
import { Field, FieldArray, Form, Formik } from 'formik'
import * as fmi from 'formik-material-ui'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import {
  AddBox,
  AddComment,
  ChevronLeft,
  ChevronRight, Clear, Save
} from '@material-ui/icons'
import ApplyButton from 'components/utils/ApplyButton'
import DeleteButton from 'components/utils/DeleteButton'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import i18n from 'data/i18n'
import { useSelector } from 'react-redux'
import createApi from 'services/api'
import { SURVEY_ANSWER_TYPES, USERTYPES } from 'services/constants'
import history from 'services/history'
import { appDispatch, store } from 'services/store'
import { URL_LINK, URL_MANAGER } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'
import { fetchPlatformComponents } from 'store/platformComponents'

const api = createApi(store)

const useStyles = makeStyles(() => ({
  questionContent: {
    display: 'flex',
    flexGrow: 1
  },
  questionActions: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 40
  }
}))

const questionRequiresPossibleAnswers = (typeOfAnswers) => {
  return ['multi_choice', 'single_choice'].includes(typeOfAnswers)
}

// questions and possible answers returned from the backend has contentFR and contentEN wrapped inside a content field
const possibleAnswerInitialValues = {
  content: { contentFR: '', contentEN: '' }
}

const questionInitialValues = {
  content: { contentFR: '', contentEN: '' },
  typeOfAnswers: SURVEY_ANSWER_TYPES[0],
  possibleAnswers: [{ ...possibleAnswerInitialValues }],
  questionSeqNumber: 1
}

const UpdateQuestionSchema = yup.object({
  content: yup.object().shape({
    contentFR: yup.string().required('Requis').max(255, 'Question trop longue'),
    contentEN: yup.string().required('Requis').max(255, 'Question trop longue')
  }),
  questionSeqNumber: yup.number().required('Requis').min(0),
  typeOfAnswers: yup.string().required().oneOf(SURVEY_ANSWER_TYPES),
  possibleAnswers: yup
    .array()
    .when('typeOfAnswers', (typeOfAnswers, schema) => {
      const pa_required = questionRequiresPossibleAnswers(typeOfAnswers)
      return pa_required
        ? schema
          .of(
            yup.object().shape({
              content: yup.object().shape({
                contentFR: yup.string().required('Requis'),
                contentEN: yup.string().required('Requis')
              })
            })
          )
          .min(1, 'Doit avoir au moins une réponse possible')
        : schema.of(
          yup.object().shape({
            content: yup.object().shape({
              contentFR: yup.string(),
              contentEN: yup.string()
            })
          })
        )
    })
    .required()
})

const DeleteQuestionDialog = ({
  questionDialogOpen,
  setQuestionDialogOpen,
  deleteQuestion
}) => (
  <Dialog
    open={questionDialogOpen}
    className="dialog"
    onClose={() => setQuestionDialogOpen(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">Suppression de question</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        Voulez-vous vraiment supprimer cette question?
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setQuestionDialogOpen(false)} color="primary">
        Annuler
      </Button>
      <Button onClick={deleteQuestion} color="primary" variant="contained">
        Supprimer
      </Button>
    </DialogActions>
  </Dialog>
)

const QuestionForm = ({
  values,
  errors,
  dirty,
  touched,
  isSubmitting,
  isValid,
  setFieldValue,
  handleBlur,
  question,
  setQuestionDialogOpen
}) => {
  const classes = useStyles()
  return (
    <Grid container spacing={8}>
      <Grid item xs className={classes.questionContent}>
        <Paper style={{ width: '100%', padding: '2rem' }} variant="outlined">
          <Form autoComplete="off" style={{ width: '100%' }}>
            <Grid container spacing={8}>
              <Grid item xs={2}>
                <Field
                  component={fmi.TextField}
                  type="number"
                  variant="outlined"
                  fullWidth
                  label="Numéro"
                  name="questionSeqNumber"
                  value={
                    values.questionSeqNumber || values.questionSeqNumber === 0
                      ? values.questionSeqNumber
                      : null
                  }
                  inputProps={{
                    min: 0,
                    step: 1
                  }}
                />
              </Grid>
              <Grid item xs={10} />
              <Grid item xs={12}>
                <Field
                  component={fmi.TextField}
                  variant="outlined"
                  fullWidth
                  autoFocus
                  label="Question FR"
                  name={'content.contentFR'}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={fmi.TextField}
                  variant="outlined"
                  fullWidth
                  label="Question EN"
                  name={'content.contentEN'}
                />
              </Grid>
              <Grid item xs={12}>
                <Field
                  component={fmi.TextField}
                  variant="outlined"
                  fullWidth
                  select
                  label="Type de réponse"
                  name={'typeOfAnswers'}
                >
                  {SURVEY_ANSWER_TYPES.map((answerType) => (
                    <MenuItem value={answerType} key={answerType}>
                      {i18n.fr.answerTypes[answerType]}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              {questionRequiresPossibleAnswers(values.typeOfAnswers) && (
                <Grid item xs={12}>
                  <FieldArray
                    name={'possibleAnswers'}
                    render={(possibleAnswerArrayHelpers) => (
                      <>
                        <Grid
                          item
                          style={{ width: 'auto', padding: '2rem' }}
                          className="centerCenter"
                          xs={12}
                        >
                          <Button
                            type="button"
                            variant="contained"
                            endIcon={<AddBox />}
                            onClick={() => {
                              possibleAnswerArrayHelpers.push({
                                ...possibleAnswerInitialValues
                              })
                            }}
                          >
                            Ajouter une réponse
                          </Button>
                        </Grid>
                        {errors.possibleAnswers &&
                          typeof errors.possibleAnswers === 'string' && (
                            <Grid
                              item
                              xs={12}
                              className="error-msg centerCenter"
                            >
                              {errors.possibleAnswers}
                            </Grid>
                        )}
                        {values.possibleAnswers.map((answer, answerIndex) => (
                          <Grid container spacing={8} key={answerIndex}>
                            <Grid
                              item
                              style={{
                                width: 140,
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              <IconButton
                                color="secondary"
                                variant="outlined"
                                disabled={answerIndex === 0}
                                onClick={() => {
                                  possibleAnswerArrayHelpers.swap(
                                    answerIndex,
                                    answerIndex - 1
                                  )
                                }}
                              >
                                <ChevronLeft />
                              </IconButton>
                              <Chip
                                color="secondary"
                                variant="outlined"
                                label={answerIndex + 1}
                              />
                              <IconButton
                                color="secondary"
                                variant="outlined"
                                disabled={
                                  answerIndex ===
                                  values.possibleAnswers.length - 1
                                }
                                onClick={() => {
                                  possibleAnswerArrayHelpers.swap(
                                    answerIndex,
                                    answerIndex + 1
                                  )
                                }}
                              >
                                <ChevronRight />
                              </IconButton>
                            </Grid>
                            <Grid item style={{ flexGrow: 1 }}>
                              <Field
                                component={fmi.TextField}
                                variant="outlined"
                                fullWidth
                                autoFocus={question.possibleAnswers.length > 1}
                                label="Réponse FR"
                                name={`possibleAnswers.${answerIndex}.content.contentFR`}
                              />
                            </Grid>
                            <Grid item style={{ flexGrow: 1 }}>
                              <Field
                                component={fmi.TextField}
                                variant="outlined"
                                fullWidth
                                label="Réponse EN"
                                name={`possibleAnswers.${answerIndex}.content.contentEN`}
                              />
                            </Grid>
                            <Grid
                              item
                              style={{ width: 'auto', paddingLeft: 0 }}
                            >
                              <Tooltip
                                title="Supprimer cette réponse"
                                style={{
                                  visibility:
                                    values.possibleAnswers.length === 1
                                      ? 'hidden'
                                      : 'visible'
                                }}
                              >
                                <IconButton
                                  onClick={() =>
                                    possibleAnswerArrayHelpers.remove(
                                      answerIndex
                                    )
                                  }
                                >
                                  <Clear />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          </Grid>
                        ))}
                      </>
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} className="centerCenter">
                {question.id && (
                  <DeleteButton onClick={() => setQuestionDialogOpen(true)}>
                    Supprimer la question
                  </DeleteButton>
                )}
                <ApplyButton
                  isValid={isValid}
                  dirty={dirty}
                  isSubmitting={isSubmitting}
                  endIcon={question.id ? <Save /> : <AddComment />}
                >
                  {!question.id && 'Ajouter la question'}
                </ApplyButton>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      </Grid>
    </Grid>
  )
}

export const UpdateQuestionInner = ({ surveyId, questionId = null }) => {
  const [survey, setSurvey] = useState(null)
  const [question, setQuestion] = useState(null)
  const [loading, setLoading] = useState(true)
  const [questionDialogOpen, setQuestionDialogOpen] = useState(false)

  const userType = useSelector((state) => state.user.userType)
  const isAdmin = userType === USERTYPES.ADMIN

  const surveysListUrl = isAdmin ? URL_LINK.SURVEYS : URL_LINK.MANAGE_SURVEYS
  const surveyUrl = isAdmin
    ? URL_LINK.SURVEYS_UPDATE + surveyId
    : URL_LINK.MANAGE_SURVEYS_UPDATE + surveyId

  const getQuestion = async () => {
    try {
      const { data: surveyRes } = await api.get(
        URL_MANAGER.SURVEYS_ID(surveyId)
      )

      const { data: allSurveyQuestions } = await api.get(
        URL_MANAGER.SURVEY_QUESTIONS(surveyId)
      )

      const maxQuestionSeqNumber =
        allSurveyQuestions.length === 0
          ? 0
          : Math.max(...allSurveyQuestions.map((q) => q.questionSeqNumber))

      const { data: questionRes } = questionId
        ? {
            data: allSurveyQuestions.find(
              (question) => question.id === questionId
            )
          }
        : {
            data: {
              ...questionInitialValues,
              questionSeqNumber: maxQuestionSeqNumber + 1
            }
          }

      questionRes.possibleAnswers.sort((a, b) => a.seqNumber - b.seqNumber)
      setSurvey(surveyRes)
      setQuestion(questionRes)
      setLoading(false)
    } catch (err) {
      appDispatch(errorMessage("La question n'a pas pu être chargée"))
    }
  }

  const saveQuestion = async (values) => {
    try {
      const method = questionId ? 'put' : 'post'

      values.possibleAnswers = values.possibleAnswers.map((pa, index) => {
        pa.seqNumber = index + 1
        return pa
      })

      const questionBody = {
        surveyId,
        id: questionId,
        content: {
          ...values.content
        },
        typeOfAnswers: values.typeOfAnswers,
        possibleAnswers: values.possibleAnswers,
        questionSeqNumber: values.questionSeqNumber
      }

      if (!questionRequiresPossibleAnswers(questionBody.typeOfAnswers)) {
        questionBody.possibleAnswers = []
      }

      await api[method](
        URL_MANAGER.QUESTIONS_ADD,
        questionBody
      )

      appDispatch(
        successMessage(
          questionId
            ? 'Les changements ont été effectués'
            : 'Données créées avec succès'
        )
      )

      // Redirect to survey details
      history.push(surveyUrl)
    } catch (error) {
      appDispatch(
        errorMessage(
          questionId
            ? "Les changements n'ont pas pu être effectués"
            : "Les données n'ont pas pu être créées"
        )
      )
    }
  }

  const deleteQuestion = async () => {
    try {
      await api.delete(URL_MANAGER.QUESTIONS_DELETE_ID(questionId))
      setQuestionDialogOpen(false)
      appDispatch(successMessage('La question a été supprimée'))
      history.push(surveyUrl)
    } catch (err) {
      appDispatch(errorMessage("La question n'a pas pu être supprimée"))
    }
  }

  useEffect(() => {
    appDispatch(fetchPlatformComponents(api))
    getQuestion()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  if (!loading && !question) {
    return <NotFound />
  }

  if (!question) {
    return <LoadingBar />
  }

  return (
    <>
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={surveysListUrl}>Sondages</Link>
            <Link to={surveyUrl}>{survey.titleContent.contentFR}</Link>
            <Typography>
              {questionId ? question.content.contentFR : 'Ajouter'}
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Formik
          children={(formikProps) => (
            <QuestionForm
              question={question}
              setQuestionDialogOpen={setQuestionDialogOpen}
              {...formikProps}
            />
          )}
          validationSchema={UpdateQuestionSchema}
          // Disable validateOnChange to improve performance
          // https://github.com/jaredpalmer/formik/issues/2542
          // la validation ne met pas a jour le bouton lors du changement de typeOfAnswers si validateOnChange=false
          // validateOnChange={false}
          initialValues={question}
          onSubmit={saveQuestion}
        />
      </Grid>
      <DeleteQuestionDialog
        questionDialogOpen={questionDialogOpen}
        setQuestionDialogOpen={setQuestionDialogOpen}
        deleteQuestion={deleteQuestion}
      />
    </>
  )
}

const UpdateQuestion = () => {
  const { surveyId, questionId } = useParams()

  return (
    <>
      <Title
        title={questionId ? 'Modifier une question' : 'Ajouter une question'}
      />
      <div className="toggler-section -white">
        <div className="container -data">
          <UpdateQuestionInner surveyId={surveyId} questionId={questionId} />
        </div>
      </div>
    </>
  )
}

export default UpdateQuestion
