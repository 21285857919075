import { Checkbox, FormControl, Grid } from '@material-ui/core'
import ApplyButton from 'components/utils/ApplyButton'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import createApi from 'services/api'
import { matchingSets, toggleItemsInSet } from 'services/helpers'
import { appDispatch, store } from 'services/store'
import { URL_ADMIN } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'
import { fetchPlatformComponents } from 'store/platformComponents'

const api = createApi(store)

const UsergroupPlatformsTable = ({
  platformComponents,
  usergroupPlatforms,
  setUsergroupPlatforms
}) => (
  <Grid item xs={12} style={{ marginBottom: '2rem' }}>
    <UtilsTable
      options={{
        pageSize: 10,
        sorting: true,
        thirdSortClick: false,
        showTextRowsSelected: false
      }}
      columns={[
        // Platforms
        {
          cellStyle: {
            padding: '0 16px'
          },
          headerStyle: {
            padding: '0 16px'
          },
          width: 130,
          sorting: false,
          render: (rowData) => {
            const platform = platformComponents.find(
              (platform) => platform.id === rowData.id
            )
            return (
              <Checkbox
                color="primary"
                checked={usergroupPlatforms.has(platform)}
                onClick={(event) => {
                  const { checked } = event.target
                  toggleItemsInSet(setUsergroupPlatforms)(platform, checked)
                }}
              />
            )
          },
          title: (
            <div style={{ whiteSpace: 'pre' }}>
              <Checkbox
                {...(() => {
                  const platformComponentsLength = platformComponents.length
                  const selectedPlatformsCount = platformComponents.filter(
                    (platform) => usergroupPlatforms.has(platform)
                  ).length

                  const checked =
                    platformComponentsLength === selectedPlatformsCount
                  const indeterminate =
                    selectedPlatformsCount > 0 &&
                    selectedPlatformsCount < platformComponentsLength

                  return { checked, indeterminate }
                })()}
                onClick={(event) => {
                  event.stopPropagation()
                  const indeterminate =
                    event.target.getAttribute('data-indeterminate') === 'true'
                  const checked = event.target.checked && !indeterminate

                  toggleItemsInSet(setUsergroupPlatforms)(
                    platformComponents,
                    checked
                  )
                }}
              />
            </div>
          )
        },
        {
          title: 'Plateforme',
          field: 'code',
          align: 'left'
        }
      ]}
      data={platformComponents}
    />
  </Grid>
)

const UsergroupPlatform = ({ id }) => {
  const platformComponents = useSelector(
    (state) => state.platformComponents.platformComponentsList
  )
  const [usergroupPlatforms, setUsergroupPlatforms] = useState(new Set())
  const [initUsergroupPlatforms, setInitUsergroupPlatforms] = useState(
    new Set()
  )
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)

  const getPlatformComponents = () => {
    setLoading(true)
    api
      .get(URL_ADMIN.PLATFORM_COMPONENTS_BY_USERGROUP, {
        params: { userGroupId: id }
      })
      .then(({ data: initUsergroupPlatformsData }) => {
        initUsergroupPlatformsData = initUsergroupPlatformsData.map(
          ({ id }) => id
        )
        const usergroupPlatformsRes = platformComponents.filter((platform) =>
          initUsergroupPlatformsData.includes(platform.id)
        )

        setUsergroupPlatforms(new Set(usergroupPlatformsRes))
        setInitUsergroupPlatforms(new Set(usergroupPlatformsRes))
        setLoading(false)
      })
  }

  const savePlatforms = async () => {
    const addedPlatforms = [...usergroupPlatforms]
      .filter((el) => el !== undefined)
      .map((el) => el.id)
    const removedPlatforms = platformComponents
      .filter((el) => !usergroupPlatforms.has(el))
      .map((el) => el.id)

    setIsSubmitting(true)

    try {
      await Promise.all([
        api.post(
          URL_ADMIN.GROUPS_ADD_PLATFORMCOMPONENTS,
          { ids: addedPlatforms },
          { params: { groupId: id } }
        ),
        api.post(
          URL_ADMIN.GROUPS_REMOVE_PLATFORMCOMPONENTS,
          { ids: removedPlatforms },
          { params: { groupId: id } }
        )
      ])

      // update the cached initial platforms
      setInitUsergroupPlatforms(new Set([...usergroupPlatforms]))

      appDispatch(successMessage('Les changements ont été effectués'))
    } catch (err) {
      appDispatch(errorMessage("Les changements n'ont pas pu être effectués"))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    appDispatch(fetchPlatformComponents(api))
    getPlatformComponents()
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])


  // Compare the initial state to the current state
  // to determine if changes have been made since last save
  useEffect(() => {
    const unchanged = matchingSets(initUsergroupPlatforms, usergroupPlatforms)

    setDirty(!unchanged)
  }, [initUsergroupPlatforms, usergroupPlatforms])

  return platformComponents.length === 0 || loading
    ? (
    <LoadingBar />
      )
    : (
    <>
      <UsergroupPlatformsTable
        platformComponents={platformComponents}
        usergroupPlatforms={usergroupPlatforms}
        setUsergroupPlatforms={setUsergroupPlatforms}
      />
      <Grid item className="centerCenter" xs={12}>
        <FormControl>
          <ApplyButton
            dirty={dirty}
            isValid={true}
            isSubmitting={isSubmitting}
            onClick={savePlatforms}
          ></ApplyButton>
        </FormControl>
      </Grid>
    </>
      )
}

export default UsergroupPlatform
