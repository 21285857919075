import ApplyButton from 'components/utils/ApplyButton'
import Title from 'components/utils/Title/Title'
import { Field, Form, Formik } from 'formik'
import * as fmi from 'formik-material-ui'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import history from 'services/history'
import * as yup from 'yup'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { URL_LINK } from 'services/urls'

import { unwrapResult } from '@reduxjs/toolkit'
import { appDispatch } from 'services/store'
import { login } from 'store/user'

const LoginSchema = yup.object({
  username: yup.string().email('Courriel invalide').required('Courriel requis'),
  password: yup.string().required('Mot de passe requis')
})

const LoginForm = ({
  status = {},
  errors,
  dirty,
  isSubmitting,
  isValid,
  setStatus
}) => (
  <Grid container justifyContent="center">
    <Grid item xs={5}>
      <Paper style={{ padding: '2rem' }} variant="outlined">
        <Form autoComplete="off">
          <Grid container spacing={8}>
            {status.expired && (
              <Grid item xs={12}>
                Votre connexion a expiré. Veuillez vous reconnecter.
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                variant="outlined"
                fullWidth
                label="Courriel"
                name="username"
                inputProps={{
                  autoComplete: 'username'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={fmi.TextField}
                type="password"
                variant="outlined"
                fullWidth
                label="Mot de passe"
                name="password"
                inputProps={{
                  autoComplete: 'current-password'
                }}
              />
            </Grid>
            {errors.login && (
              <Grid item xs={12} className="error-msg">
                {errors.login}
              </Grid>
            )}
            {status.locked && (
              <Grid item xs={12} className="error-msg">
                Votre compte a été verrouillé. <br />
                Un courriel vous a été envoyé avec des instructions sur la
                réinitialisation de votre mot de passe.
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <ApplyButton
                  isValid={isValid}
                  isSubmitting={isSubmitting}
                  endIcon={null}
                >
                  Connexion
                </ApplyButton>
                <Button
                  component={Link}
                  to={URL_LINK.FORGOT_PASSWORD}
                  variant="text"
                  color="secondary"
                >
                  Mot de passe oublié ?
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Paper>
    </Grid>
  </Grid>
)

const LoginInner = () => {
  const expired = useSelector((state) => state.user.expired)
  const locked = useSelector((state) => state.user.locked)

  const handleSubmit = async (
    values,
    { setFieldError, setSubmitting, setStatus }
  ) => {
    const { username, password } = values
    const loginResponse = await appDispatch(login({ username, password }))

    try {
      unwrapResult(loginResponse)
      history.push('/')
    } catch (error) {
      if (error === 'locked') {
        setStatus('locked', true)
        setSubmitting(false)
      } else {
        setFieldError('login', 'Le courriel ou le mot de passe est erroné.')
      }
    }
  }

  return (
    <Formik
      component={LoginForm}
      enableReinitialize
      validationSchema={LoginSchema}
      initialValues={{
        username: '',
        password: ''
      }}
      initialStatus={{ expired, locked }}
      onSubmit={handleSubmit}
    />
  )
}

const Login = () => (
  <>
    <Title title={'Se connecter'} />
    <div className="toggler-section -white">
      <div className="container -data">
        <LoginInner />
      </div>
    </div>
  </>
)

export { LoginForm, LoginSchema }
export default Login
