import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { URL_LINK, URL_USER } from 'services/urls'
import AddUserForm from 'components/Shared/Users/AddUserForm'
import Title from 'components/utils/Title/Title'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import history from 'services/history'
import { setUserName } from 'store/user'

const api = createApi(store)

export const SignupDialog = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      className="dialog"
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Inscription complétée</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Un courriel de confirmation vous a été envoyé avec un code de
          vérification. Veuillez continuer pour initialiser votre mot de passe
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" variant="contained">
          Continuer
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const Signup = () => {
  const [open, setOpen] = useState(false)

  const saveSignup = (values) => {
    const { email, firstName, lastName } = values

    appDispatch(setUserName(email))

    return api
      .post(
        URL_USER.SIGNUP_CREATE_BY_GUEST,
        {
          email,
          firstName,
          lastName,
        }
      )
      .then(() => {
        setOpen(true)
      })
  }

  // Initial user data
  const initialSignupState = {
    email: '',
    firstName: '',
    lastName: '',
  }

  const handleClose = () => {
    setOpen(false)
    history.push(URL_LINK.RESET_PASSWORD)
  }

  return (
    <>
      <Title title={"S'inscrire"} />
      <div className="toggler-section -white">
        <div className="container -data">
          <AddUserForm
            initialUserState={initialSignupState}
            saveUser={saveSignup}
            submitLabel="S'incrire"
            isAdmin={false}
          />
        </div>
      </div>
      <SignupDialog open={open} handleClose={handleClose} />
    </>
  )
}

export default Signup
