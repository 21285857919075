import { Grid, FormControl, Checkbox } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { URL_ADMIN } from 'services/urls'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import UtilsTable from 'components/utils/UtilsTable/UtilsTable'
import ApplyButton from 'components/utils/ApplyButton'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import PersonIcon from '@material-ui/icons/Person'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { matchingSets, toggleItemsInSet } from 'services/helpers'
import { USERTYPES } from 'services/constants'
import { errorMessage, successMessage } from 'store/message'
import { UsergroupUsersToolbar } from 'components/Shared/Usergroups/UsergroupUsersToolbar'

const api = createApi(store)

const userTypes = {
  users: { name: 'Tout', icon: <PersonOutlineIcon /> },
  members: { name: 'Membres', icon: <PersonIcon /> },
  managers: { name: 'Gestionnaires', icon: <SupervisorAccountIcon /> }
}

const UsergroupUsersTable = ({
  users,
  usergroupMembers,
  setUsergroupMembers,
  usergroupManagers,
  setUsergroupManagers,
  filterUsers,
  setFilterUsers,
  eligibleManagers
}) => (
  <Grid item xs={12} style={{ marginBottom: '2rem' }}>
    <UtilsTable
      components={{
        Toolbar: (props) => (
          <UsergroupUsersToolbar
            userTypes={userTypes}
            filterUsers={filterUsers}
            setFilterUsers={setFilterUsers}
            {...props}
          />
        )
      }}
      options={{
        pageSize: 10,
        sorting: true,
        thirdSortClick: false,
        showTextRowsSelected: false
      }}
      columns={[
        // Membres
        {
          cellStyle: {
            padding: '0 16px'
          },
          headerStyle: {
            padding: '0 16px'
          },
          width: 130,
          sorting: false,
          render: (rowData) => {
            const user = users.find((user) => user.id === rowData.id)

            return (
              <Checkbox
                color="primary"
                checked={usergroupMembers.has(user)}
                disabled={usergroupManagers.has(user)}
                onClick={(event) => {
                  const { checked } = event.target

                  toggleItemsInSet(setUsergroupMembers)(user, checked)
                }}
              />
            )
          },
          title: (
            <div style={{ whiteSpace: 'pre' }}>
              <Checkbox
                {...(() => {
                  const usersCount = users.length
                  const selectedUsersCount = users.filter((user) =>
                    usergroupMembers.has(user)
                  ).length

                  const checked = usersCount === selectedUsersCount
                  const indeterminate =
                    selectedUsersCount > 0 && selectedUsersCount < usersCount

                  return { checked, indeterminate }
                })()}
                onClick={(event) => {
                  event.stopPropagation()
                  const { checked } = event.target

                  toggleItemsInSet(setUsergroupMembers)(users, checked)
                  if (!checked) {
                    toggleItemsInSet(setUsergroupManagers)(
                      [...eligibleManagers],
                      false
                    )
                  }
                }}
              />
              <span style={{ verticalAlign: 'middle' }}>Membre</span>
            </div>
          )
        },
        // Gestionnaires
        {
          cellStyle: {
            padding: '0 16px'
          },
          headerStyle: {
            padding: '0 16px'
          },
          width: 130,
          sorting: false,
          render: (rowData) => {
            const user = users.find((user) => user.id === rowData.id)

            return (
              <Checkbox
                color="primary"
                checked={usergroupManagers.has(user)}
                disabled={!eligibleManagers.has(user)}
                onClick={(event) => {
                  const { checked } = event.target

                  if (checked) {
                    toggleItemsInSet(setUsergroupMembers)(user, true)
                  }
                  toggleItemsInSet(setUsergroupManagers)(user, checked)
                }}
              />
            )
          },
          title: (
            <div style={{ whiteSpace: 'pre' }}>
              <Checkbox
                {...(() => {
                  const usersCount = users.length
                  const selectedUsersCount = users.filter((user) =>
                    usergroupManagers.has(user)
                  ).length

                  const checked = usersCount === selectedUsersCount
                  const indeterminate =
                    selectedUsersCount > 0 && selectedUsersCount < usersCount

                  return { checked, indeterminate }
                })()}
                onClick={(event) => {
                  event.stopPropagation()
                  const indeterminate =
                    event.target.getAttribute('data-indeterminate') === 'true'
                  const checked = event.target.checked && !indeterminate

                  if (checked) {
                    toggleItemsInSet(setUsergroupMembers)(
                      [...eligibleManagers],
                      true
                    )
                  }
                  toggleItemsInSet(setUsergroupManagers)(
                    [...eligibleManagers],
                    checked
                  )
                }}
              />
              <span style={{ verticalAlign: 'middle' }}>Gestionnaire</span>
            </div>
          )
        },
        {
          title: 'Courriel',
          field: 'userName',
          align: 'left'
        },
        {
          title: 'Prénom',
          field: 'firstName',
          align: 'left',
          defaultSort: 'asc',
          cellStyle: {
            textTransform: 'capitalize'
          }
        },
        {
          title: 'Nom',
          field: 'lastName',
          align: 'left',
          cellStyle: {
            textTransform: 'capitalize'
          }
        }
      ]}
      data={users.filter((user) =>
        filterUsers === 'members'
          ? usergroupMembers.has(user)
          : filterUsers === 'managers'
            ? usergroupManagers.has(user)
            : true
      )}
    />
  </Grid>
)

const UsergroupUsers = ({ id }) => {
  const [users, setUsers] = useState([])
  const [eligibleManagers, setEligibleManagers] = useState(new Set())

  const [initUsergroupMembers, setInitUsergroupMembers] = useState(new Set())
  const [initUsergroupManagers, setInitUsergroupManagers] = useState(new Set())

  const [usergroupMembers, setUsergroupMembers] = useState(new Set())
  const [usergroupManagers, setUsergroupManagers] = useState(new Set())

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [filterUsers, setFilterUsers] = useState('users')
  const [dirty, setDirty] = useState(false)

  const getUsers = () => {
    Promise.all([
      api.get(URL_ADMIN.USERS),
      api.get(URL_ADMIN.USERS_BASE, { params: { userGroupId: id } }),
      api.get(URL_ADMIN.MANAGERS, { params: { userGroupId: id } })
    ])
      .then(
        ([
          { data: usersRes },
          { data: usergroupMembersRes },
          { data: usergroupManagersRes }
        ]) => {
          const usergroupMembersSet = new Set(
            usersRes.filter((user) =>
              usergroupMembersRes.map(({ id }) => id).includes(user.id)
            )
          )
          const usergroupManagersSet = new Set(
            usersRes.filter((user) =>
              usergroupManagersRes.map(({ id }) => id).includes(user.id)
            )
          )

          const eligibleManagersSet = new Set(
            usersRes.filter(
              (user) => user.userType === USERTYPES.MANAGER && user.isActive
            )
          )

          setInitUsergroupMembers(usergroupMembersSet)
          setInitUsergroupManagers(usergroupManagersSet)

          setUsergroupMembers(usergroupMembersSet)
          setUsergroupManagers(usergroupManagersSet)

          setEligibleManagers(eligibleManagersSet)
          setUsers(usersRes)
        }
      )
      .catch((err) => err)
  }

  const saveUsers = async () => {
    const addUsers = [...usergroupMembers]
      .filter((el) => el !== undefined)
      .map((el) => el.id)
    const removeUsers = users
      .filter((el) => !usergroupMembers.has(el))
      .map((el) => el.id)

    const addManagers = [...usergroupManagers]
      .filter((el) => el !== undefined)
      .map((el) => el.id)
    const removeManagers = users
      .filter((el) => !usergroupManagers.has(el))
      .map((el) => el.id)

    setIsSubmitting(true)

    try {
      await Promise.all([
        api.post(
          URL_ADMIN.GROUPS_ADD_USERS,
          { ids: addUsers },
          { params: { groupId: id } }
        ),
        api.post(
          URL_ADMIN.GROUPS_REMOVE_USERS,
          { ids: removeUsers },
          { params: { groupId: id } }
        )
      ])
      await Promise.all([
        api.post(
          URL_ADMIN.GROUPS_ADD_MANAGERS,
          { ids: addManagers },
          { params: { groupId: id } }
        ),
        api.post(
          URL_ADMIN.GROUPS_REMOVE_MANAGERS,
          { ids: removeManagers },
          { params: { groupId: id } }
        )
      ])

      // Keep cache of saved state
      setInitUsergroupMembers(new Set([...usergroupMembers]))
      setInitUsergroupManagers(new Set([...usergroupManagers]))

      appDispatch(successMessage('Les changements ont été effectués'))
    } catch (err) {
      appDispatch(errorMessage("Les changements n'ont pas pu être effectués"))
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Compare the initial state to the current state
  // to determine if changes have been made since last save
  useEffect(() => {
    const unchanged =
      matchingSets(initUsergroupMembers, usergroupMembers) &&
      matchingSets(initUsergroupManagers, usergroupManagers)

    setDirty(!unchanged)
  }, [
    initUsergroupMembers,
    initUsergroupManagers,
    usergroupMembers,
    usergroupManagers
  ])

  return !users.length
    ? (
    <LoadingBar />
      )
    : (
    <>
      <UsergroupUsersTable
        users={users}
        usergroupMembers={usergroupMembers}
        setUsergroupMembers={setUsergroupMembers}
        usergroupManagers={usergroupManagers}
        setUsergroupManagers={setUsergroupManagers}
        filterUsers={filterUsers}
        setFilterUsers={setFilterUsers}
        eligibleManagers={eligibleManagers}
      />
      <Grid item className="centerCenter" xs={12}>
        <FormControl>
          <ApplyButton
            dirty={dirty}
            isValid={true}
            isSubmitting={isSubmitting}
            onClick={saveUsers}
          ></ApplyButton>
        </FormControl>
      </Grid>
    </>
      )
}

export default UsergroupUsers
