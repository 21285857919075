import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { base, URL_USER } from "services/urls";

const defaultUserState = {
  loggedIn: false,
  // User name
  userName: null,
  // One of USERTYPES
  userType: null,
  // Session expired
  expired: false,
  // User account is locked
  locked: false,
  // Error returned when logging in
  error: null,
  // User session token
  token: null,

  // User language preference
  lang: "FR",
};

const login = createAsyncThunk(
  "user/login",
  async ({ username, password }, { getState, rejectWithValue }) => {
    try {
      const response = await axios.post(`${base}${URL_USER.LOGIN}`, {
        username,
        password,
      });
      const { userType, token } = response.data;

      return {
        ...getState().user,
        loggedIn: true,
        userName: username,
        userType,
        expired: false,
        locked: false,
        error: null,
        token,
      };
    } catch (error) {
      // Check if user locked
      try {
        const response = await axios.get(`${base}${URL_USER.ACCOUNT_STATUS}`, {
          params: { userName: username },
        });

        const { isLocked } = response.data;

        if (isLocked) {
          return rejectWithValue("locked");
        }
      } catch (error) {
        return rejectWithValue(error.response.data);
      }

      return rejectWithValue(error.response.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: defaultUserState,
  reducers: {
    logout: () => {
      return defaultUserState;
    },
    expire: (state) => ({
      ...state,
      loggedIn: false,
      userName: null,
      userType: null,
      expired: true,
    }),
    unlock: (state) => ({
      ...state,
      locked: false,
      expired: false,
      error: null,
    }),
    setUserName(state, { payload }) {
      return {
        ...state,
        userName: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.locked = false;
      state.expired = false;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    });
    builder.addCase(login.rejected, (state, { payload, error }) => {
      if (payload === "locked") {
        state.locked = true;
      } else {
        state.locked = false;
        state.error = payload?.errorMessage || error.message;
      }
    });
  },
});

export const { logout, expire, unlock, setUserName } = userSlice.actions;
export { login, defaultUserState };

export default userSlice.reducer;
