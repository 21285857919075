import React from 'react'
import Title from 'components/utils/Title/Title'

function About () {
  return (
    <>
      <Title title={'À propos'} />
      <div className="text-content">
        <div className="container">
          <h3>
            La Plateforme numérique de données en mobilité de Jalon est réalisée
            grâce à la contribution financière du Ministère de l'Économie et de
            l'Innovation du Québec et de Montréal en commun.
          </h3>

          <p>
            Ce Portail est la porte d'entrée à la Plateforme en données mobilité
            de Jalon. Ensemble, ils visent à mettre en commun, à partager et à
            valoriser de façon innovante des données liées à la mobilité
            capturées par différents acteurs à travers la province de Québec.
          </p>

          <p>
            Ce service est offert gratuitement à tout intervenant
            {/* qui en formule
            la demande en communiquant avec
            <a href="mailto:plateforme@jalonmtl.services">l'équipe de Jalon</a> */}
            . Le catalogue de l’ensemble de données est en accès libre.
            Cependant, pour visualiser les données brutes ou y accéder via notre
            API, vous devez créer un compte.
          </p>

          <p>
            Voici un apercu des organisations qui fournissent des ensembles de
            données:
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Agence de mobilité de Montréal
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- ARTM
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Bixi Montréal
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- BTM
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Communauto
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ministère de la Famille
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ministère de l'education
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ministère des Affaires municipales et de
            l'Habitation
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ministère des transports
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Niosense
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Region Administratives
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- SAAQ
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Statistique Canada
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- STM
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ville de Laval
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ville de Longueuil
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ville de Montréal
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ville de Québec
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;- Ville de Sherbrooke
            <br />
          </p>

          <p>
            N'hésitez pas à communiquer avec{' '}
            <a href="mailto:plateforme@jalonmtl.services">l'équipe de Jalon</a>{' '}
            pour toutes questions ou commentaires.
          </p>

          <p>Merci.</p>
        </div>
      </div>
    </>
  )
}

export default About
