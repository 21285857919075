import React from 'react'
import Title from 'components/utils/Title/Title'

function Help () {
  return (
    <>
      <Title title="Aide" />
      <div className="toggler-section -white">
        <div className="container">
          <h3 className="title">
            Quel est le niveau d'avancement de la plateforme numérique de
            données en mobilité de Jalon?
          </h3>
          <p className="description">
            La version bêta de la plateforme est disponible. Vous pouvez vous
            créer un accès gratuit dès maintenant. Les principales fonctions de
            base sont disponibles, mais Jalon travaille activement à intégrer
            davantage de données et à bonifier l'offre fonctionnelle. Jalon
            désire connaître l'opinion des acteurs de l'écosystème mobilité du
            Québec qui s'intéressent au partage de données en lien avec la
            mobilité. Jalon considère que cette plateforme doit être développée
            en collaboration avec ses futurs utilisateurs pour en assurer la
            pertinence.
          </p>
          <br />

          <h3 className="title">
            Est-ce que Jalon prévoit des nouvelles versions de la plateforme?
          </h3>
          <p className="description">
            Jalon a complété une première version de la plateforme. Elle permet
            une présentation simple des ensembles de données disponibles. Elle
            permet aussi une visualisation simple de ces données. Toutefois, la
            maturation fonctionnelle de ce service va se poursuivre dans les
            prochains mois, notamment par l'ajout régulier de fonctionnalités
            ainsi que de nouveaux ensembles de données.
          </p>
          <br />

          <h3 className="title">
            Comment peut-on avoir accès au Portail et à la Plateforme?
          </h3>
          <p className="description">
            Vous pouvez vous créer un compte gratuit à partir du Portail. Il
            serait également possible d'organiser une rencontre pour que Jalon
            expose sa vision complète et comprendre les besoins de votre
            organisation.
          </p>
          <br />

          <h3 className="title">
            Comment rapporter une anomalie ou formuler une demande d'ajout
            fonctionnelle ou de données?
          </h3>
          <p className="description">
            Communiquer toutes anomalies ou requêtes à{' '}
            <a href="mailto:plateforme@jalonmtl.services">l'équipe de Jalon</a>.
          </p>
        </div>
      </div>
    </>
  )
}

export default Help
