import UpdateUserForm, {
  UpdateUserDialog
} from 'components/Shared/Users/UpdateUserForm'
import LoadingBar from 'components/utils/LoadingBar/LoadingBar'
import Title from 'components/utils/Title/Title'
import React, { useEffect, useState } from 'react'
import createApi from 'services/api'
import { store, appDispatch } from 'services/store'
import { URL_LINK, URL_USER } from 'services/urls'
import { errorMessage, successMessage } from 'store/message'
import { logout } from 'store/user'
import history from 'services/history'
import { clearPlatformComponents } from 'store/platformComponents'

const api = createApi(store)

const UpdateProfile = () => {
  const [userInfo, setUserInfo] = useState()
  const [loading, setLoading] = useState(false)
  const [alreadyInUseEmail, setAlreadyInUseEmail] = React.useState([])
  const [open, setOpen] = useState(false)

  const saveUserUpdate = (url) => (values) => {
    return api
      .put(url, {
        ...values
      })
      .then((data) => {
        setUserInfo(data.data)
        setLoading(false)
        appDispatch(successMessage('Les changements ont été effectués'))
      })
      .catch((err) =>
        appDispatch(errorMessage("Les changements n'ont pas pu être effectués"))
      )
  }

  const setPopupOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const deleteUser = () => {
    api
      .delete(URL_USER.PUBLIC_USERS)
      .then(() => {
        setOpen(false)
        appDispatch(successMessage('Votre compte a été supprimé'))
        appDispatch(logout())
        appDispatch(clearPlatformComponents())
        history.push(URL_LINK.INDEX)
      })
      .catch(() =>
        appDispatch(errorMessage("Votre compte n'a pas pu être supprimé"))
      )
  }

  const savePassword = (values) => {
    const dto = {
      newPassword: values.newPassword,
      oldPassword: values.oldPassword
    }
    return api
      .put(URL_USER.SELF_UPDATE_PASSWORD, dto)
      .then(() => {
        appDispatch(successMessage('Votre mot de passe a été changé'))
      })
      .catch(() =>
        appDispatch(errorMessage("Votre mot de passe n'a pas pu être changé"))
      )
  }

  useEffect(() => {
    setLoading(true)
    api
      .get(URL_USER.PUBLIC_USERS)
      .then((data) => {
        setUserInfo(data.data)
        setLoading(false)
      })
      .catch(() =>
        appDispatch(errorMessage("Votre profil n'a pas pu être affiché"))
      )
  }, [])


  return (
    <>
      <Title title={'Mon profil'} />
      <div className="toggler-section -white">
        <div className="container -data">
          {!loading && userInfo ? (
            <UpdateUserForm
              usernameState={{ userName: userInfo.userName }}
              namesState={{
                firstName: userInfo.firstName,
                lastName: userInfo.lastName
              }}
              saveNames={saveUserUpdate(URL_USER.SELF_UPDATE_NAMES)}
              savePassword={savePassword}
              admin={false}
              resetLoading={() => {
                setLoading(false)
              }}
              alreadyInUseEmail={alreadyInUseEmail}
              setAlreadyInUseEmail={setAlreadyInUseEmail}
              setPopupOpen={setPopupOpen}
            />
          ) : (
            <LoadingBar />
          )}
        </div>
      </div>
      <UpdateUserDialog
        open={open}
        handleClose={handleClose}
        deleteUser={deleteUser}
        admin={false}
      />
    </>
  )
}

export default UpdateProfile
